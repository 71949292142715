import {PageContents} from '../utils';

export const pageContents: PageContents = {
  title: 'UniFeed समाचार',
  headline: 'बिना फ़िल्टर के दुनिया देखें – 13 देशों से 16 भाषाओं में वास्तविक समय के समाचार सारांश',
  subheading:
    'दुनिया भर से व्यापक, निष्पक्ष वास्तविक समय के समाचार सारांशों का पता लगाएं, सभी सुविधाजनक रूप से आपकी मूल भाषा में अनुवादित होते हैं, ताकि स्पष्टता और समझ प्रदान की जा सके',
  or: 'या',
  launchWebApp: 'वेब ऐप लॉन्च करें',
};
