import {PageContents} from '../utils';

export const pageContents: PageContents = {
  title: 'UniFeed Nieuws',
  headline: 'Zie de Wereld Ongefilterd – Actueel Nieuwsoverzicht uit 13 Landen in 16 Talen',
  subheading:
    'Verken uitgebreide, onbevooroordeelde actuele nieuwsdigesten van over de hele wereld, allemaal gemakkelijk vertaald naar uw moedertaal voor duidelijkheid en begrip',
  or: 'OF',
  launchWebApp: 'Start webapp',
};
