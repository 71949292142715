import * as React from 'react';

export const Nl: React.FC = React.memo(() => (
  <div>
    <h1>Privacybeleid</h1>
    <p>Laatst bijgewerkt: 5 juli 2024</p>
    <p>
      Dit Privacybeleid beschrijft ons beleid en onze procedures met betrekking tot de verzameling, het gebruik en de
      openbaarmaking van uw informatie wanneer u de Service gebruikt en informeert u over uw privacyrechten en hoe de
      wet u beschermt.
    </p>
    <p>
      We gebruiken uw persoonlijke gegevens om de Service te leveren en te verbeteren, inclusief het tonen van
      gepersonaliseerde advertenties en het aanbieden van betaalde abonnementen. Door de Service te gebruiken, stemt u
      in met de verzameling en het gebruik van informatie in overeenstemming met dit Privacybeleid.
    </p>
    <h2>Interpretatie en Definities</h2>
    <h3>Interpretatie</h3>
    <p>
      De woorden waarvan de eerste letter is gecapituleerd hebben de betekenis die is gedefinieerd onder de volgende
      voorwaarden. De volgende definities hebben dezelfde betekenis, ongeacht of ze in enkelvoud of meervoud voorkomen.
    </p>
    <h3>Definities</h3>
    <p>Voor de doeleinden van dit Privacybeleid:</p>
    <ul>
      <li>
        <strong>Account</strong> betekent een uniek account dat voor u is aangemaakt om toegang te krijgen tot onze
        Service of delen van onze Service.
      </li>
      <li>
        <strong>Gelieerde onderneming</strong> betekent een entiteit die zeggenschap heeft over, wordt gecontroleerd
        door of onder gemeenschappelijke controle staat met een partij, waarbij "controle" betekent eigendom van 50% of
        meer van de aandelen, het aandelenbelang of andere effecten die gerechtigd zijn om te stemmen voor de verkiezing
        van bestuurders of andere bestuurlijke autoriteit.
      </li>
      <li>
        <strong>Applicatie</strong> verwijst naar UniFeed News, het softwareprogramma dat door het Bedrijf wordt
        verstrekt.
      </li>
      <li>
        <strong>Bedrijf</strong> (verwezen naar als "het Bedrijf", "Wij", "Ons" of "Onze" in deze Overeenkomst) verwijst
        naar Ropsid Inc.
      </li>
      <li>
        <strong>Land</strong> verwijst naar: Ontario, Canada
      </li>
      <li>
        <strong>Apparaat</strong> betekent elk apparaat dat toegang kan krijgen tot de Service, zoals een computer, een
        mobiele telefoon of een digitale tablet.
      </li>
      <li>
        <strong>Persoonsgegevens</strong> zijn alle informatie die betrekking heeft op een geïdentificeerde of
        identificeerbare persoon.
      </li>
      <li>
        <strong>Service</strong> verwijst naar de Applicatie.
      </li>
      <li>
        <strong>Dienstverlener</strong> betekent elke natuurlijke of rechtspersoon die de gegevens namens het Bedrijf
        verwerkt. Dit verwijst naar externe bedrijven of personen die door het Bedrijf zijn ingehuurd om de Service te
        faciliteren, de Service namens het Bedrijf te leveren, diensten met betrekking tot de Service uit te voeren of
        het Bedrijf te helpen analyseren hoe de Service wordt gebruikt.
      </li>
      <li>
        <strong>Gebruiksggegevens</strong> verwijst naar gegevens die automatisch worden verzameld, gegenereerd door het
        gebruik van de Service of van de Service-infrastructuur zelf (bijvoorbeeld de duur van een paginaweergave).
      </li>
      <li>
        <strong>U</strong> betekent de persoon die de Service bezoekt of gebruikt, of het bedrijf of andere
        rechtspersoon namens wie een dergelijke persoon de Service bezoekt of gebruikt, indien van toepassing.
      </li>
    </ul>
    <h2>Verzameling en Gebruik van Uw Persoonsgegevens</h2>
    <h3>Soorten Gegevens die Worden Verzameld</h3>
    <h4>Persoonsgegevens</h4>
    <p>
      Tijdens het gebruik van onze Service kunnen we u vragen om ons bepaalde persoonlijk identificeerbare informatie te
      verstrekken die kan worden gebruikt om contact met u op te nemen of u te identificeren. Persoonlijk
      identificeerbare informatie kan omvatten, maar is niet beperkt tot:
    </p>
    <ul>
      <li>Emailadres</li>
      <li>Voornaam en achternaam</li>
      <li>Telefoonnummer</li>
      <li>Adres, Staat, Provincie, Postcode, Stad</li>
      <li>Gebruiksggegevens</li>
    </ul>
    <h4>Gebruiksggegevens</h4>
    <p>Gebruiksggegevens worden automatisch verzameld tijdens het gebruik van de Service.</p>
    <p>
      Gebruiksggegevens kunnen informatie omvatten zoals het IP-adres van uw Apparaat (bijv. IP-adres), browsertype,
      browserversie, de pagina's van onze Service die u bezoekt, de tijd en datum van uw bezoek, de tijd die op die
      pagina's is doorgebracht, unieke apparaat-ID's en andere diagnostische gegevens.
    </p>
    <p>
      Wanneer u de Service bezoekt via of via een mobiel apparaat, kan deze informatie informatie bevatten zoals het
      type mobiel apparaat dat u gebruikt, uw unieke ID van het mobiele apparaat, het IP-adres van uw mobiele apparaat,
      uw mobiele besturingssysteem, het type mobiele internetbrowser dat u gebruikt, unieke apparaat-ID's en andere
      diagnostische gegevens.
    </p>
    <h4>Trackingtechnologieën en Cookies</h4>
    <p>
      We gebruiken Cookies en soortgelijke trackingtechnologieën om de activiteit op onze Service te volgen en bepaalde
      informatie op te slaan. Trackingtechnologieën die worden gebruikt, zijn bakens, tags en scripts om informatie te
      verzamelen en te volgen en onze Service te verbeteren en te analyseren.
    </p>
    <p>De technologieën die we gebruiken kunnen omvatten:</p>
    <ul>
      <li>
        <strong>Cookies of Browsercookies.</strong> Een cookie is een klein bestand dat op uw Apparaat wordt geplaatst.
        U kunt uw browser instrueren om alle Cookies te weigeren of aan te geven wanneer een Cookie wordt verzonden. Als
        u echter geen Cookies accepteert, kunt u mogelijk sommige delen van onze Service niet gebruiken.
      </li>
      <li>
        <strong>Webbakens.</strong> Bepaalde secties van onze Service en onze e-mails kunnen kleine elektronische
        bestanden bevatten die bekend staan als webbaken (ook wel heldere gifs, pixeltags en single-pixel gifs genoemd)
        waarmee het Bedrijf bijvoorbeeld gebruikers kan tellen die die pagina's hebben bezocht of een e-mail hebben
        geopend en voor andere gerelateerde website-statistieken (bijvoorbeeld het vastleggen van de populariteit van
        een bepaald gedeelte en het verifiëren van systeem- en serverintegriteit).
      </li>
    </ul>
    <p>We gebruiken zowel sessiecookies als permanente cookies voor de hieronder uiteengezette doeleinden:</p>
    <ul>
      <li>
        <strong>Noodzakelijke / Essentiële Cookies</strong>
        Type: Sessie Cookies Beheerd door: Ons Doel: Deze Cookies zijn essentieel om u diensten te bieden die
        beschikbaar zijn via de Website en om u in staat te stellen sommige van de functies ervan te gebruiken. Ze
        helpen gebruikers te authenticeren en voorkomen frauduleus gebruik van gebruikersaccounts. Zonder deze Cookies
        kunnen de diensten die u hebt gevraagd niet worden geleverd, en we gebruiken deze Cookies alleen om u die
        diensten te leveren.
      </li>
      <li>
        <strong>Cookiesbeleid / Kennisgeving Acceptatie Cookies</strong>
        Type: Permanente Cookies Beheerd door: Ons Doel: Deze Cookies identificeren of gebruikers het gebruik van
        cookies op de Website hebben geaccepteerd.
      </li>
      <li>
        <strong>Functionaliteitscookies</strong>
        Type: Permanente Cookies Beheerd door: Ons Doel: Deze Cookies stellen ons in staat keuzes te onthouden die u
        maakt wanneer u de Website gebruikt, zoals het onthouden van uw inloggegevens of taalvoorkeur. Het doel van deze
        Cookies is om u een meer persoonlijke ervaring te bieden en te voorkomen dat u uw voorkeuren opnieuw moet
        invoeren telkens wanneer u de Website gebruikt.
      </li>
    </ul>
    <h4>Informatie Verzameld tijdens het Gebruik van de Applicatie</h4>
    <p>
      Tijdens het gebruik van onze Applicatie, om functies van onze Applicatie te bieden, kunnen we met uw voorafgaande
      toestemming verzamelen:
    </p>
    <ul>
      <li>Informatie over uw locatie</li>
      <li>Informatie uit het telefoonboek van uw Apparaat (contactenlijst)</li>
      <li>Afbeeldingen en andere informatie uit de camera en fotobibliotheek van uw Apparaat</li>
    </ul>
    <p>
      We gebruiken deze informatie om functies van onze Service te bieden, om onze Service te verbeteren en aan te
      passen. De informatie kan worden geüpload naar de servers van het Bedrijf en/of een server van een Dienstverlener
      of het kan eenvoudig worden opgeslagen op Uw apparaat.
    </p>
    <p>U kunt op elk moment de toegang tot deze informatie in- of uitschakelen via uw Apparaatinstellingen.</p>
    <h3>Gebruik van Uw Persoonsgegevens</h3>
    <p>Het Bedrijf kan Persoonsgegevens gebruiken voor de volgende doeleinden:</p>
    <ul>
      <li>
        <strong>Om onze Service te leveren en te onderhouden</strong>, inclusief om het gebruik van onze Service te
        monitoren.
      </li>
      <li>
        <strong>Om uw Account te beheren:</strong> om uw registratie als gebruiker van de Service te beheren. De
        Persoonsgegevens die u verstrekt, kunnen u toegang geven tot verschillende functionaliteiten van de Service die
        beschikbaar zijn voor u als geregistreerde gebruiker.
      </li>
      <li>
        <strong>Voor de uitvoering van een contract:</strong> de ontwikkeling, naleving en uitvoering van het
        koopcontract voor de producten, artikelen of diensten die u hebt gekocht of van enig ander contract met ons via
        de Service.
      </li>
      <li>
        <strong>Om contact met u op te nemen:</strong> Om contact met u op te nemen via e-mail, telefoongesprekken, SMS
        of andere gelijkwaardige vormen van elektronische communicatie, zoals pushmeldingen van een mobiele applicatie
        met betrekking tot updates of informatieve communicatie met betrekking tot de functionaliteiten, producten of
        diensten die u hebt gekocht of over waarop u hebt geïnformeerd, tenzij u ervoor hebt gekozen dergelijke
        informatie niet te ontvangen.
      </li>
      <li>
        Om u nieuws, speciale aanbiedingen en algemene informatie te verstrekken over andere goederen, diensten en
        evenementen die wij aanbieden die vergelijkbaar zijn met die welke u al hebt gekocht of waar u naar heeft
        geïnformeerd, tenzij u ervoor hebt gekozen dergelijke informatie niet te ontvangen.
      </li>
      <li>
        <strong>Om uw verzoeken te beheren:</strong> Om uw verzoeken aan ons te behandelen en te beheren.
      </li>
    </ul>
    <h4>Voor zakelijke overdrachten:</h4>
    <p>
      We kunnen uw informatie gebruiken om een fusie, verkoop, herstructurering, reorganisatie, ontbinding of andere
      verkoop of overdracht van sommige of al onze activa te evalueren of uit te voeren, hetzij als een going concern of
      als onderdeel van een faillissement, liquidatie of soortgelijke procedure, waarbij persoonlijke gegevens die wij
      over onze Servicegebruikers bewaren onder de overgedragen activa vallen.
    </p>
    <h4>Voor andere doeleinden:</h4>
    <p>
      We kunnen uw informatie gebruiken voor andere doeleinden, zoals gegevensanalyse, het identificeren van
      gebruikstrends, het bepalen van de effectiviteit van onze promotiecampagnes en om onze Service, producten,
      marketing en uw ervaring te evalueren en te verbeteren. We kunnen uw persoonlijke informatie delen in de volgende
      situaties:
    </p>
    <ul>
      <li>
        <strong>Met Dienstverleners:</strong> We kunnen uw persoonlijke informatie delen met Dienstverleners om het
        gebruik van onze Service te monitoren en analyseren, om contact met u op te nemen.
      </li>
      <li>
        <strong>Voor zakelijke overdrachten:</strong> We kunnen uw persoonlijke informatie delen of overdragen in
        verband met, of tijdens onderhandelingen over, enige fusie, verkoop van bedrijfsactiva, financiering of
        acquisitie van ons hele bedrijf of een deel daarvan aan een ander bedrijf.
      </li>
      <li>
        <strong>Met Gelieerde ondernemingen:</strong> We kunnen uw informatie delen met onze gelieerde ondernemingen, in
        welk geval we die gelieerde ondernemingen zullen verplichten dit Privacybeleid te respecteren. Gelieerde
        ondernemingen omvatten onze moedermaatschappij en alle andere dochterondernemingen, joint venture partners of
        andere bedrijven die wij controleren of die onder gemeenschappelijke controle met ons staan.
      </li>
      <li>
        <strong>Met zakelijke partners:</strong> We kunnen uw informatie delen met onze zakelijke partners om u bepaalde
        producten, diensten of promoties aan te bieden.
      </li>
      <li>
        <strong>Met andere gebruikers:</strong> wanneer u persoonlijke informatie deelt of anderszins interactie hebt in
        de openbare gebieden met andere gebruikers, kan dergelijke informatie door alle gebruikers worden bekeken en
        openbaar worden verspreid.
      </li>
      <li>
        <strong>Met uw toestemming:</strong> We kunnen uw persoonlijke informatie openbaar maken voor elk ander doel met
        uw toestemming.
      </li>
    </ul>
    <h3>Bewaring van Uw Persoonsgegevens</h3>
    <p>
      Het Bedrijf bewaart uw Persoonsgegevens alleen zo lang als nodig is voor de doeleinden uiteengezet in dit
      Privacybeleid. We zullen uw Persoonsgegevens bewaren en gebruiken voor zover nodig om te voldoen aan onze
      wettelijke verplichtingen (bijvoorbeeld als we uw gegevens moeten bewaren om te voldoen aan toepasselijke
      wetgeving), geschillen op te lossen en onze juridische overeenkomsten en beleidsregels af te dwingen.
    </p>
    <p>
      Het Bedrijf bewaart ook Gebruiksggegevens voor interne analysedoeleinden. Gebruiksggegevens worden over het
      algemeen voor een kortere periode bewaard, behalve wanneer deze gegevens worden gebruikt om de beveiliging te
      versterken of de functionaliteit van onze Service te verbeteren, of als we wettelijk verplicht zijn deze gegevens
      langer te bewaren.
    </p>
    <h3>Overdracht van Uw Persoonsgegevens</h3>
    <p>
      Uw informatie, inclusief Persoonsgegevens, wordt verwerkt in de operationele kantoren van het Bedrijf en op andere
      plaatsen waar de bij de verwerking betrokken partijen zich bevinden. Dit betekent dat deze informatie kan worden
      overgedragen naar — en onderhouden op — computers buiten uw staat, provincie, land of andere overheidsjurisdictie
      waar de wetgeving inzake gegevensbescherming anders kan zijn dan die van uw rechtsgebied.
    </p>
    <p>
      Uw instemming met dit Privacybeleid, gevolgd door uw indiening van dergelijke informatie, geeft aan dat u instemt
      met die overdracht.
    </p>
    <p>
      Het Bedrijf zal alle redelijkerwijs noodzakelijke stappen ondernemen om ervoor te zorgen dat uw gegevens veilig
      worden behandeld en in overeenstemming met dit Privacybeleid en dat geen overdracht van uw Persoonsgegevens zal
      plaatsvinden naar een organisatie of een land, tenzij er adequate controles zijn, waaronder de beveiliging van uw
      gegevens en andere persoonlijke informatie.
    </p>
    <h3>Openbaarmaking van Uw Persoonsgegevens</h3>
    <h4>Zakelijke Transacties</h4>
    <p>
      Als het Bedrijf betrokken is bij een fusie, acquisitie of verkoop van activa, kunnen uw Persoonsgegevens worden
      overgedragen. We zullen een kennisgeving geven voordat uw Persoonsgegevens worden overgedragen en onder een ander
      Privacybeleid vallen.
    </p>
    <h4>Wetshandhaving</h4>
    <p>
      Onder bepaalde omstandigheden kan het Bedrijf verplicht zijn uw Persoonsgegevens openbaar te maken als dit
      wettelijk verplicht is of als reactie op geldige verzoeken van overheidsinstanties (bijv. een rechtbank of een
      overheidsinstantie).
    </p>
    <h4>Andere Juridische Vereisten</h4>
    <p>
      Het Bedrijf kan uw Persoonsgegevens openbaar maken in de goedbedoelde overtuiging dat een dergelijke actie nodig
      is om:
    </p>
    <ul>
      <li>Te voldoen aan een wettelijke verplichting</li>
      <li>De rechten of eigendom van het Bedrijf te beschermen en te verdedigen</li>
      <li>Mogelijke overtredingen in verband met de Service te voorkomen of te onderzoeken</li>
      <li>De persoonlijke veiligheid van gebruikers van de Service of het publiek te beschermen</li>
      <li>Zich te beschermen tegen wettelijke aansprakelijkheid</li>
    </ul>
    <h3>Beveiliging van Uw Persoonsgegevens</h3>
    <p>
      De beveiliging van uw Persoonsgegevens is belangrijk voor ons, maar onthoud dat geen enkele methode van
      transmissie via het internet of methode van elektronische opslag 100% veilig is. Hoewel we ernaar streven
      commercieel acceptabele middelen te gebruiken om uw Persoonsgegevens te beschermen, kunnen we de absolute
      veiligheid ervan niet garanderen.
    </p>
    <h2>Privacy van Kinderen</h2>
    <p>
      Onze Service richt zich niet tot iemand jonger dan 13 jaar. We verzamelen niet bewust persoonlijk identificeerbare
      informatie van iemand jonger dan 13 jaar. Als u een ouder of voogd bent en u bent zich ervan bewust dat uw kind
      ons Persoonsgegevens heeft verstrekt, neem dan contact met ons op. Als we ons ervan bewust worden dat we
      Persoonsgegevens van iemand jonger dan 13 jaar hebben verzameld zonder verificatie van ouderlijke toestemming,
      ondernemen we stappen om die informatie van onze servers te verwijderen.
    </p>
    <p>
      Als we afhankelijk moeten zijn van toestemming als wettelijke basis voor het verwerken van uw informatie en uw
      land toestemming van een ouder vereist, kunnen we de toestemming van uw ouder vereisen voordat we die informatie
      verzamelen en gebruiken.
    </p>
    <h2>Links naar Andere Websites</h2>
    <p>
      Onze Service kan links bevatten naar andere websites die niet door ons worden beheerd. Als u op een link van een
      derde klikt, wordt u naar die site van de derde geleid. We raden u ten zeerste aan het Privacybeleid van elke site
      die u bezoekt te lezen.
    </p>
    <p>
      We hebben geen controle over en aanvaarden geen verantwoordelijkheid voor de inhoud, het privacybeleid of de
      praktijken van sites of diensten van derden.
    </p>
    <h2>Wijzigingen in dit Privacybeleid</h2>
    <p>
      We kunnen ons Privacybeleid van tijd tot tijd bijwerken. We zullen u op de hoogte stellen van eventuele
      wijzigingen door het nieuwe Privacybeleid op deze pagina te plaatsen.
    </p>
    <p>
      We zullen u op de hoogte stellen via e-mail en of een opvallende kennisgeving op onze Service, voorafgaand aan de
      wijziging die van kracht wordt en de datum "Laatst bijgewerkt" bovenaan dit Privacybeleid bijwerken.
    </p>
    <p>
      U wordt geadviseerd dit Privacybeleid periodiek te bekijken op eventuele wijzigingen. Wijzigingen in dit
      Privacybeleid zijn van kracht wanneer ze op deze pagina worden geplaatst.
    </p>
    <h2>Contact met Ons</h2>
    <p>Als u vragen hebt over dit Privacybeleid, kunt u contact met ons opnemen:</p>
    <ul>
      <li>Per e-mail: contact@unifeed.news</li>
    </ul>
  </div>
));
