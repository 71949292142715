import * as React from 'react';
import * as Rx from 'rxjs';
import * as states from '@/states';

export const Layout: React.FC = React.memo(() => {
  const setLayout = states.global.useSetLayoutState();

  React.useEffect(() => {
    const subscription = Rx.fromEvent(window, 'resize').subscribe(() =>
      setLayout({
        width: window.innerWidth,
        height: window.innerHeight,
      }),
    );
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return null;
});
