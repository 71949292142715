import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import * as React from 'react';
import Slider, {Settings as SliderSettings} from 'react-slick';
import * as states from '@/states';
import {Locale} from '@/types';
import {PhoneFrame} from './PhoneFrame';

export const Jumbotron: React.FC = React.memo(() => {
  const locale = states.global.useLocaleValue();
  const pageContents = states.bloc.usePageContentsValue();
  const [part1, part2] = pageContents.headline.split(' – ');
  return (
    <section className="relative grid gap-16 bg-gray-700 py-24 bg-blend-multiply lg:py-36">
      <div className="absolute inset-0 z-0 h-full w-full bg-gradient-to-br from-blue-900 to-transparent" />
      <div className="relative z-10 mx-auto max-w-screen-xl px-4 text-center">
        <h1 className="mb-4 text-4xl font-extrabold leading-none tracking-tight text-white md:text-5xl lg:text-6xl">
          {part1}
        </h1>
        <h2 className="mb-4 text-3xl font-medium leading-none text-white md:text-4xl lg:text-5xl">{part2}</h2>
        <p className="mb-8 text-lg font-normal text-gray-300 sm:px-16 lg:px-48 lg:text-xl">{pageContents.subheading}</p>
        <div className="flex flex-col flex-wrap items-center justify-center gap-4 md:flex-row">
          <a
            target="_blank"
            href="https://apps.apple.com/app/unifeed-news/id6504729341"
            className="inline-flex items-center justify-center rounded-lg focus:ring-4 dark:focus:ring-blue-900"
          >
            <img src={`/images/pages/downloads/ios/${locale}/white.svg`} className="h-[72px]" />
          </a>
          <p className="font-light text-white">{pageContents.or}</p>
          <a
            target="_blank"
            href="https://app.unifeed.news"
            className="flex h-[72px] items-center rounded-lg bg-gradient-to-br from-green-400 to-blue-600 px-5 py-2.5 text-center text-2xl font-medium text-white hover:bg-gradient-to-bl focus:outline-none focus:ring-4 focus:ring-green-200 dark:focus:ring-green-800"
          >
            {pageContents.launchWebApp}
          </a>
        </div>
      </div>
      <div className="relative z-10 overflow-hidden">
        <ScreenshotsCarousel />
      </div>
    </section>
  );
});

const SliderInner = Slider as unknown as React.FC<SliderSettings>;

const ScreenshotsCarousel: React.FC = React.memo(() => {
  const layout = states.global.useLayoutValue();
  return (
    <SliderInner
      infinite
      slidesToShow={layout.width / 320}
      slidesToScroll={1}
      autoplay
      speed={2000}
      autoplaySpeed={2000}
      cssEase="linear"
      swipeToSlide
    >
      {Object.values(Locale)
        .filter((locale) => ![Locale.Hi, Locale.Nl, Locale.Pl, Locale.Tr, Locale.Ar, Locale.Id].includes(locale))
        .map((locale) => (
          <PhoneFrame key={locale} imageUrl={`/images/pages/home/screenshots/ios/${locale}.png`} />
        ))}
    </SliderInner>
  );
});
