import * as React from 'react';
import clsx from 'clsx';
import {Link} from 'react-router-dom';
import * as states from '@/states';

export const Footer: React.FC = React.memo(() => {
  const pageContents = states.bloc.usePageContentsValue();
  return (
    <footer className="bg-gray-900">
      <div className="mx-auto w-full max-w-screen-xl">
        <div className="p-4 sm:flex sm:items-center sm:justify-between md:py-6">
          <Link to="/" className="mb-4 flex items-center space-x-3 rtl:space-x-reverse sm:mb-0">
            <img src="/images/icon-512.png" className="h-8" alt="Logo" />
            <span className="self-center whitespace-nowrap text-2xl font-semibold text-white">
              {pageContents.title}
            </span>
          </Link>
          <ul className="flex flex-wrap items-center text-sm font-medium text-gray-400">
            {[{url: '/privacy', label: 'Pivacy Policy'}].map(({url, label}, idx) => (
              <li key={label}>
                <Link to={url} className={clsx('hover:underline', idx < 2 && 'me-4 md:me-6')}>
                  {label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <hr className="border-gray-700 sm:mx-auto" />
        <span className="block py-2 text-center text-sm text-gray-400 lg:py-4">
          © 2024 Ropsid Inc. . All Rights Reserved.
        </span>
      </div>
    </footer>
  );
});
