import {PageContents} from '../utils';

export const pageContents: PageContents = {
  title: 'UniFeed Haberleri',
  headline: 'Dünyayı Filtresiz Görün – 13 Ülkeden 16 Dilde Gerçek Zamanlı Haber Özetleri',
  subheading:
    'Dünya çapında kapsamlı, tarafsız gerçek zamanlı haber özetlerini keşfedin, hepsi anadilinizde netlik ve anlayış için uygun şekilde çevrilmiştir',
  or: 'VEYA',
  launchWebApp: 'Web Uygulamasını Başlat',
};
