import {PageContents} from '../utils';

export const pageContents: PageContents = {
  title: 'UniFeed ニュース',
  headline: 'フィルターなしで世界を見る – 13カ国からのリアルタイムニュースダイジェストを16の言語で',
  subheading:
    '世界中の詳細かつ公正なリアルタイムのニュースダイジェストを、理解しやすいように母国語に翻訳して探索してください',
  or: 'または',
  launchWebApp: 'ウェブアプリを起動',
};
