import * as React from 'react';

export const Id: React.FC = React.memo(() => (
  <div>
    <h1>Kebijakan Privasi</h1>
    <p>Terakhir diperbarui: 5 Juli 2024</p>
    <p>
      Kebijakan Privasi ini menjelaskan kebijakan dan prosedur kami tentang pengumpulan, penggunaan, dan pengungkapan
      informasi Anda saat Anda menggunakan Layanan dan memberi tahu Anda tentang hak privasi Anda dan bagaimana hukum
      melindungi Anda.
    </p>
    <p>
      Kami menggunakan data pribadi Anda untuk menyediakan dan meningkatkan Layanan, termasuk menampilkan iklan yang
      dipersonalisasi dan menawarkan langganan berbayar. Dengan menggunakan Layanan, Anda menyetujui pengumpulan dan
      penggunaan informasi sesuai dengan Kebijakan Privasi ini.
    </p>
    <h2>Penafsiran dan Definisi</h2>
    <h3>Penafsiran</h3>
    <p>
      Kata-kata yang huruf awalnya menggunakan huruf besar memiliki makna yang ditentukan dalam kondisi berikut.
      Definisi berikut akan memiliki makna yang sama terlepas dari apakah mereka muncul dalam bentuk tunggal atau jamak.
    </p>
    <h3>Definisi</h3>
    <p>Untuk tujuan Kebijakan Privasi ini:</p>
    <ul>
      <li>
        <strong>Akun</strong> berarti akun unik yang dibuat untuk Anda untuk mengakses Layanan kami atau bagian dari
        Layanan kami.
      </li>
      <li>
        <strong>Afiliasi</strong> berarti suatu entitas yang mengendalikan, dikendalikan oleh, atau berada di bawah
        kendali bersama dengan suatu pihak, di mana "kendali" berarti kepemilikan 50% atau lebih saham, kepentingan
        ekuitas, atau sekuritas lainnya yang berhak memilih direktur atau otoritas manajemen lainnya.
      </li>
      <li>
        <strong>Aplikasi</strong> merujuk pada UniFeed News, program perangkat lunak yang disediakan oleh Perusahaan.
      </li>
      <li>
        <strong>Perusahaan</strong> (disebut juga sebagai "Perusahaan", "Kami", atau "Milik Kami" dalam Perjanjian ini)
        mengacu pada Ropsid Inc.
      </li>
      <li>
        <strong>Negara</strong> merujuk pada: Ontario, Kanada
      </li>
      <li>
        <strong>Perangkat</strong> berarti perangkat apa pun yang dapat mengakses Layanan seperti komputer, ponsel, atau
        tablet digital.
      </li>
      <li>
        <strong>Data Pribadi</strong> adalah informasi apa pun yang berkaitan dengan individu yang diidentifikasi atau
        dapat diidentifikasi.
      </li>
      <li>
        <strong>Layanan</strong> mengacu pada Aplikasi.
      </li>
      <li>
        <strong>Penyedia Layanan</strong> berarti orang alami atau badan hukum yang memproses data atas nama Perusahaan.
        Ini merujuk pada perusahaan pihak ketiga atau individu yang dipekerjakan oleh Perusahaan untuk memfasilitasi
        Layanan, untuk menyediakan Layanan atas nama Perusahaan, untuk melakukan layanan terkait dengan Layanan, atau
        untuk membantu Perusahaan menganalisis bagaimana Layanan digunakan.
      </li>
      <li>
        <strong>Data Penggunaan</strong> merujuk pada data yang dikumpulkan secara otomatis, baik yang dihasilkan oleh
        penggunaan Layanan atau dari infrastruktur Layanan itu sendiri (misalnya, durasi kunjungan halaman).
      </li>
      <li>
        <strong>Anda</strong> berarti individu yang mengakses atau menggunakan Layanan, atau perusahaan, atau badan
        hukum lainnya atas nama individu tersebut mengakses atau menggunakan Layanan, jika berlaku.
      </li>
    </ul>
    <h2>Pengumpulan dan Penggunaan Data Pribadi Anda</h2>
    <h3>Jenis Data yang Dikumpulkan</h3>
    <h4>Data Pribadi</h4>
    <p>
      Saat menggunakan Layanan kami, kami mungkin meminta Anda untuk memberikan informasi pribadi tertentu yang dapat
      digunakan untuk menghubungi atau mengidentifikasi Anda. Informasi pribadi yang dapat diidentifikasi mungkin
      termasuk, tetapi tidak terbatas pada:
    </p>
    <ul>
      <li>Alamat email</li>
      <li>Nama depan dan nama belakang</li>
      <li>Nomor telepon</li>
      <li>Alamat, Provinsi, Kode Pos, Kota</li>
      <li>Data Penggunaan</li>
    </ul>
    <h4>Data Penggunaan</h4>
    <p>Data Penggunaan dikumpulkan secara otomatis saat menggunakan Layanan.</p>
    <p>
      Data Penggunaan mungkin termasuk informasi seperti alamat Protokol Internet (IP) Perangkat Anda, jenis browser,
      versi browser, halaman Layanan kami yang Anda kunjungi, waktu dan tanggal kunjungan Anda, waktu yang dihabiskan di
      halaman tersebut, pengidentifikasi perangkat unik, dan data diagnostik lainnya.
    </p>
    <p>
      Saat Anda mengakses Layanan melalui perangkat seluler, informasi ini mungkin termasuk informasi seperti jenis
      perangkat seluler yang Anda gunakan, ID unik perangkat seluler Anda, alamat IP perangkat seluler Anda, sistem
      operasi seluler Anda, jenis browser Internet seluler yang Anda gunakan, pengidentifikasi perangkat unik, dan data
      diagnostik lainnya.
    </p>
    <h4>Teknologi Pelacakan dan Cookie</h4>
    <p>
      Kami menggunakan Cookie dan teknologi pelacakan serupa untuk melacak aktivitas di Layanan kami dan menyimpan
      informasi tertentu. Teknologi pelacakan yang digunakan adalah beacon, tag, dan skrip untuk mengumpulkan dan
      melacak informasi serta untuk meningkatkan dan menganalisis Layanan kami.
    </p>
    <p>Teknologi yang kami gunakan mungkin termasuk:</p>
    <ul>
      <li>
        <strong>Cookie atau Browser Cookie.</strong> Cookie adalah file kecil yang ditempatkan di Perangkat Anda. Anda
        dapat menginstruksikan browser Anda untuk menolak semua Cookie atau menunjukkan kapan Cookie dikirim. Namun,
        jika Anda tidak menerima Cookie, Anda mungkin tidak dapat menggunakan beberapa bagian dari Layanan kami.
      </li>
      <li>
        <strong>Web Beacon.</strong> Bagian tertentu dari Layanan kami dan email kami mungkin mengandung file elektronik
        kecil yang dikenal sebagai web beacon (juga disebut clear gifs, tag piksel, dan gif piksel tunggal) yang
        memungkinkan Perusahaan, misalnya, menghitung pengguna yang telah mengunjungi halaman tersebut atau membuka
        email dan untuk statistik situs web terkait lainnya (misalnya, merekam popularitas bagian tertentu dan
        memverifikasi integritas sistem dan server).
      </li>
    </ul>
    <p>Kami menggunakan Cookie sesi dan Cookie tetap untuk tujuan yang diatur di bawah ini:</p>
    <ul>
      <li>
        <strong>Cookie yang Diperlukan / Esensial</strong>
        Jenis: Cookie Sesi Dikelola oleh: Kami Tujuan: Cookie ini sangat penting untuk menyediakan layanan yang tersedia
        melalui Situs Web dan untuk memungkinkan Anda menggunakan beberapa fiturnya. Mereka membantu untuk
        mengautentikasi pengguna dan mencegah penggunaan akun pengguna secara curang. Tanpa Cookie ini, layanan yang
        Anda minta tidak dapat disediakan, dan kami hanya menggunakan Cookie ini untuk menyediakan layanan tersebut
        kepada Anda.
      </li>
      <li>
        <strong>Kebijakan Cookie / Penerimaan Pemberitahuan Cookie</strong>
        Jenis: Cookie Tetap Dikelola oleh: Kami Tujuan: Cookie ini mengidentifikasi apakah pengguna telah menerima
        penggunaan cookie di Situs Web.
      </li>
      <li>
        <strong>Cookie Fungsionalitas</strong>
        Jenis: Cookie Tetap Dikelola oleh: Kami Tujuan: Cookie ini memungkinkan kami untuk mengingat pilihan yang Anda
        buat saat Anda menggunakan Situs Web, seperti mengingat detail login Anda atau preferensi bahasa. Tujuan dari
        Cookie ini adalah untuk memberikan Anda pengalaman yang lebih personal dan untuk menghindari Anda harus
        memasukkan kembali preferensi Anda setiap kali Anda menggunakan Situs Web.
      </li>
    </ul>
    <h4>Informasi yang Dikumpulkan saat Menggunakan Aplikasi</h4>
    <p>
      Saat menggunakan Aplikasi kami, untuk menyediakan fitur-fitur Aplikasi kami, kami dapat mengumpulkan, dengan izin
      Anda sebelumnya:
    </p>
    <ul>
      <li>Informasi mengenai lokasi Anda</li>
      <li>Informasi dari buku telepon Perangkat Anda (daftar kontak)</li>
      <li>Gambar dan informasi lain dari kamera dan perpustakaan foto Perangkat Anda</li>
    </ul>
    <p>
      Kami menggunakan informasi ini untuk menyediakan fitur dari Layanan kami, untuk meningkatkan dan menyesuaikan
      Layanan kami. Informasi tersebut dapat diunggah ke server Perusahaan dan/atau server Penyedia Layanan atau mungkin
      hanya disimpan di Perangkat Anda.
    </p>
    <p>
      Anda dapat mengaktifkan atau menonaktifkan akses ke informasi ini kapan saja, melalui pengaturan Perangkat Anda.
    </p>
    <h3>Penggunaan Data Pribadi Anda</h3>
    <p>Perusahaan dapat menggunakan Data Pribadi untuk tujuan berikut:</p>
    <ul>
      <li>
        <strong>Untuk menyediakan dan memelihara Layanan kami</strong>, termasuk untuk memantau penggunaan Layanan kami.
      </li>
      <li>
        <strong>Untuk mengelola Akun Anda:</strong> untuk mengelola pendaftaran Anda sebagai pengguna Layanan. Data
        Pribadi yang Anda berikan dapat memberi Anda akses ke berbagai fungsi Layanan yang tersedia untuk Anda sebagai
        pengguna terdaftar.
      </li>
      <li>
        <strong>Untuk pelaksanaan kontrak:</strong> pengembangan, kepatuhan, dan pelaksanaan kontrak pembelian produk,
        barang, atau layanan yang Anda beli atau kontrak lain dengan kami melalui Layanan.
      </li>
      <li>
        <strong>Untuk menghubungi Anda:</strong> Untuk menghubungi Anda melalui email, panggilan telepon, SMS, atau
        bentuk komunikasi elektronik lainnya, seperti pemberitahuan push aplikasi seluler mengenai pembaruan atau
        komunikasi informatif terkait dengan fungsi, produk atau layanan yang dikontrak, termasuk pembaruan keamanan,
        bila perlu atau wajar untuk pelaksanaannya.
      </li>
      <li>
        Untuk memberi Anda berita, penawaran khusus, dan informasi umum tentang barang, layanan, dan acara lainnya yang
        kami tawarkan yang serupa dengan yang telah Anda beli atau tanyakan kecuali Anda telah memilih untuk tidak
        menerima informasi tersebut.
      </li>
      <li>
        <strong>Untuk mengelola permintaan Anda:</strong> Untuk menghadiri dan mengelola permintaan Anda kepada kami.
      </li>
    </ul>
    <h4>Untuk transfer bisnis:</h4>
    <p>
      Kami dapat menggunakan informasi Anda untuk mengevaluasi atau melakukan merger, divestasi, restrukturisasi,
      reorganisasi, pembubaran, atau penjualan atau transfer lainnya dari beberapa atau semua aset kami, baik sebagai
      kelangsungan usaha atau sebagai bagian dari kebangkrutan, likuidasi, atau prosedur serupa, di mana data pribadi
      yang kami pegang tentang pengguna Layanan kami adalah di antara aset yang ditransfer.
    </p>
    <h4>Untuk tujuan lain:</h4>
    <p>
      Kami dapat menggunakan informasi Anda untuk tujuan lain, seperti analisis data, mengidentifikasi tren penggunaan,
      menentukan efektivitas kampanye promosi kami, dan untuk mengevaluasi dan meningkatkan Layanan kami, produk,
      pemasaran, dan pengalaman Anda. Kami dapat membagikan informasi pribadi Anda dalam situasi berikut:
    </p>
    <ul>
      <li>
        <strong>Dengan Penyedia Layanan:</strong> Kami dapat membagikan informasi pribadi Anda dengan Penyedia Layanan
        untuk memantau dan menganalisis penggunaan Layanan kami, untuk menghubungi Anda.
      </li>
      <li>
        <strong>Untuk transfer bisnis:</strong> Kami dapat membagikan atau mentransfer informasi pribadi Anda sehubungan
        dengan, atau selama negosiasi dari, merger, penjualan aset perusahaan, pembiayaan, atau akuisisi sebagian atau
        seluruh bisnis kami ke perusahaan lain.
      </li>
      <li>
        <strong>Dengan Afiliasi:</strong> Kami dapat membagikan informasi Anda dengan afiliasi kami, dalam hal ini kami
        akan mewajibkan afiliasi tersebut untuk mematuhi Kebijakan Privasi ini. Afiliasi termasuk perusahaan induk kami
        dan setiap anak perusahaan lainnya, mitra usaha patungan, atau perusahaan lain yang kami kendalikan atau yang
        berada di bawah kendali bersama dengan kami.
      </li>
      <li>
        <strong>Dengan mitra bisnis:</strong> Kami dapat membagikan informasi Anda dengan mitra bisnis kami untuk
        menawarkan Anda produk, layanan, atau promosi tertentu.
      </li>
      <li>
        <strong>Dengan pengguna lain:</strong> ketika Anda membagikan informasi pribadi atau berinteraksi di area publik
        dengan pengguna lain, informasi tersebut dapat dilihat oleh semua pengguna dan dapat didistribusikan secara
        publik di luar.
      </li>
      <li>
        <strong>Dengan persetujuan Anda:</strong> Kami dapat mengungkapkan informasi pribadi Anda untuk tujuan lain
        dengan persetujuan Anda.
      </li>
    </ul>
    <h3>Retensi Data Pribadi Anda</h3>
    <p>
      Perusahaan akan menyimpan Data Pribadi Anda hanya selama yang diperlukan untuk tujuan yang ditetapkan dalam
      Kebijakan Privasi ini. Kami akan menyimpan dan menggunakan Data Pribadi Anda sejauh yang diperlukan untuk mematuhi
      kewajiban hukum kami (misalnya, jika kami diharuskan untuk menyimpan data Anda untuk mematuhi hukum yang berlaku),
      menyelesaikan perselisihan, dan menegakkan perjanjian dan kebijakan hukum kami.
    </p>
    <p>
      Perusahaan juga akan menyimpan Data Penggunaan untuk tujuan analisis internal. Data Penggunaan umumnya disimpan
      untuk jangka waktu yang lebih singkat, kecuali data ini digunakan untuk memperkuat keamanan atau untuk
      meningkatkan fungsi Layanan kami, atau kami diwajibkan secara hukum untuk menyimpan data ini untuk jangka waktu
      yang lebih lama.
    </p>
    <h3>Transfer Data Pribadi Anda</h3>
    <p>
      Informasi Anda, termasuk Data Pribadi, diproses di kantor operasi Perusahaan dan di tempat lain di mana
      pihak-pihak yang terlibat dalam pemrosesan berada. Ini berarti informasi ini dapat ditransfer ke — dan dipelihara
      di — komputer yang berada di luar negara bagian, provinsi, negara, atau yurisdiksi pemerintah Anda di mana hukum
      perlindungan data mungkin berbeda dari yang ada di yurisdiksi Anda.
    </p>
    <p>
      Persetujuan Anda terhadap Kebijakan Privasi ini diikuti oleh pengajuan informasi tersebut mewakili persetujuan
      Anda terhadap transfer tersebut.
    </p>
    <p>
      Perusahaan akan mengambil semua langkah yang diperlukan secara wajar untuk memastikan bahwa data Anda diperlakukan
      dengan aman dan sesuai dengan Kebijakan Privasi ini dan tidak ada transfer Data Pribadi Anda yang akan terjadi ke
      organisasi atau negara kecuali ada kontrol yang memadai termasuk keamanan data Anda dan informasi pribadi lainnya.
    </p>
    <h3>Pengungkapan Data Pribadi Anda</h3>
    <h4>Transaksi Bisnis</h4>
    <p>
      Jika Perusahaan terlibat dalam merger, akuisisi, atau penjualan aset, Data Pribadi Anda dapat ditransfer. Kami
      akan memberikan pemberitahuan sebelum Data Pribadi Anda ditransfer dan tunduk pada Kebijakan Privasi yang berbeda.
    </p>
    <h4>Penegakan Hukum</h4>
    <p>
      Dalam keadaan tertentu, Perusahaan mungkin diminta untuk mengungkapkan Data Pribadi Anda jika diperlukan untuk
      melakukannya oleh hukum atau sebagai tanggapan atas permintaan yang sah oleh otoritas publik (misalnya, pengadilan
      atau badan pemerintah).
    </p>
    <h4>Persyaratan Hukum Lainnya</h4>
    <p>Perusahaan dapat mengungkapkan Data Pribadi Anda dengan itikad baik bahwa tindakan tersebut diperlukan untuk:</p>
    <ul>
      <li>Mematuhi kewajiban hukum</li>
      <li>Melindungi dan mempertahankan hak atau properti Perusahaan</li>
      <li>Mencegah atau menyelidiki kemungkinan kesalahan terkait Layanan</li>
      <li>Melindungi keamanan pribadi pengguna Layanan atau publik</li>
      <li>Melindungi dari tanggung jawab hukum</li>
    </ul>
    <h3>Keamanan Data Pribadi Anda</h3>
    <p>
      Keamanan Data Pribadi Anda penting bagi kami, tetapi ingat bahwa tidak ada metode transmisi melalui Internet, atau
      metode penyimpanan elektronik yang 100% aman. Meskipun kami berupaya menggunakan sarana yang dapat diterima secara
      komersial untuk melindungi Data Pribadi Anda, kami tidak dapat menjamin keamanan mutlaknya.
    </p>
    <h2>Privasi Anak-Anak</h2>
    <p>
      Layanan kami tidak ditujukan untuk siapa pun yang berusia di bawah 13 tahun. Kami tidak secara sadar mengumpulkan
      informasi pribadi yang dapat diidentifikasi dari siapa pun yang berusia di bawah 13 tahun. Jika Anda adalah orang
      tua atau wali dan Anda mengetahui bahwa anak Anda telah memberikan kami Data Pribadi, harap hubungi kami. Jika
      kami mengetahui bahwa kami telah mengumpulkan Data Pribadi dari siapa pun yang berusia di bawah 13 tahun tanpa
      verifikasi persetujuan orang tua, kami mengambil langkah-langkah untuk menghapus informasi tersebut dari server
      kami.
    </p>
    <p>
      Jika kami perlu mengandalkan persetujuan sebagai dasar hukum untuk memproses informasi Anda dan negara Anda
      memerlukan persetujuan dari orang tua, kami mungkin memerlukan persetujuan orang tua Anda sebelum kami
      mengumpulkan dan menggunakan informasi tersebut.
    </p>
    <h2>Tautan ke Situs Web Lain</h2>
    <p>
      Layanan kami mungkin berisi tautan ke situs web lain yang tidak dioperasikan oleh kami. Jika Anda mengklik tautan
      pihak ketiga, Anda akan diarahkan ke situs pihak ketiga tersebut. Kami sangat menyarankan Anda untuk meninjau
      Kebijakan Privasi setiap situs yang Anda kunjungi.
    </p>
    <p>
      Kami tidak memiliki kendali atas dan tidak bertanggung jawab atas konten, kebijakan privasi, atau praktik situs
      atau layanan pihak ketiga mana pun.
    </p>
    <h2>Perubahan pada Kebijakan Privasi ini</h2>
    <p>
      Kami dapat memperbarui Kebijakan Privasi kami dari waktu ke waktu. Kami akan memberi tahu Anda tentang perubahan
      apa pun dengan memposting Kebijakan Privasi baru di halaman ini.
    </p>
    <p>
      Kami akan memberi tahu Anda melalui email dan/atau pemberitahuan yang menonjol di Layanan kami, sebelum perubahan
      menjadi efektif dan memperbarui tanggal "Terakhir diperbarui" di bagian atas Kebijakan Privasi ini.
    </p>
    <p>
      Anda disarankan untuk meninjau Kebijakan Privasi ini secara berkala untuk setiap perubahan. Perubahan pada
      Kebijakan Privasi ini efektif ketika diposting di halaman ini.
    </p>
    <h2>Hubungi Kami</h2>
    <p>Jika Anda memiliki pertanyaan tentang Kebijakan Privasi ini, Anda dapat menghubungi kami:</p>
    <ul>
      <li>Melalui email: contact@unifeed.news</li>
    </ul>
  </div>
));
