import * as React from 'react';

export const Ko: React.FC = React.memo(() => (
  <div>
    <h1>개인정보 처리방침</h1>
    <p>마지막 업데이트: 2024년 7월 5일</p>
    <p>
      이 개인정보 처리방침은 서비스 사용 시 귀하의 정보 수집, 사용 및 공개에 관한 당사의 정책 및 절차를 설명하며, 귀하의
      개인정보 보호 권리 및 법이 귀하를 어떻게 보호하는지 알려드립니다.
    </p>
    <p>
      당사는 개인 데이터를 사용하여 맞춤형 광고를 표시하고 유료 구독을 제공하는 등 서비스를 제공하고 개선합니다.
      서비스를 사용함으로써 귀하는 이 개인정보 처리방침에 따라 정보 수집 및 사용에 동의하게 됩니다.
    </p>
    <h2>해석 및 정의</h2>
    <h3>해석</h3>
    <p>
      첫 글자가 대문자인 단어는 다음 조건에 따라 정의된 의미를 가집니다. 다음 정의는 단수형 또는 복수형으로 표시되는지
      여부에 관계없이 동일한 의미를 가집니다.
    </p>
    <h3>정의</h3>
    <p>본 개인정보 처리방침의 목적상:</p>
    <ul>
      <li>
        <strong>계정</strong>이란 귀하가 당사의 서비스 또는 서비스의 일부에 액세스하기 위해 생성된 고유한 계정을
        의미합니다.
      </li>
      <li>
        <strong>계열사</strong>란 한 당사자가 통제하거나 통제받거나 공동 통제 하에 있는 모든 법인을 의미하며, 여기서
        "통제"란 50% 이상의 주식, 지분 또는 기타 유가증권을 소유하여 이사 선출 또는 기타 관리 권한을 행사할 수 있는
        권리를 의미합니다.
      </li>
      <li>
        <strong>응용 프로그램</strong>이란 회사가 제공하는 소프트웨어 프로그램인 UniFeed News를 의미합니다.
      </li>
      <li>
        <strong>회사</strong>(본 약관에서 "회사", "우리", "당사" 또는 "우리의"로 지칭함)는 Ropsid Inc를 의미합니다.
      </li>
      <li>
        <strong>국가</strong>란 캐나다 온타리오 주를 의미합니다.
      </li>
      <li>
        <strong>기기</strong>란 컴퓨터, 휴대폰 또는 디지털 태블릿과 같이 서비스를 이용할 수 있는 모든 기기를 의미합니다.
      </li>
      <li>
        <strong>개인 데이터</strong>란 식별되었거나 식별 가능한 개인과 관련된 모든 정보를 의미합니다.
      </li>
      <li>
        <strong>서비스</strong>란 응용 프로그램을 의미합니다.
      </li>
      <li>
        <strong>서비스 제공자</strong>란 회사 대신 데이터를 처리하는 모든 자연인 또는 법인을 의미합니다. 이는 회사가
        서비스를 용이하게 하기 위해, 회사 대신 서비스를 제공하기 위해, 서비스와 관련된 서비스를 수행하기 위해, 또는
        서비스 사용 방법을 분석하는 데 도움을 주기 위해 고용한 제3자 회사 또는 개인을 지칭합니다.
      </li>
      <li>
        <strong>사용 데이터</strong>란 서비스 사용이나 서비스 인프라 자체에서 자동으로 수집된 데이터를 의미합니다(예:
        페이지 방문 기간).
      </li>
      <li>
        <strong>귀하</strong>란 서비스를 액세스하거나 사용하는 개인, 또는 해당 개인이 서비스를 액세스하거나 사용하는
        회사 또는 기타 법인(해당하는 경우)을 의미합니다.
      </li>
    </ul>
    <h2>개인 데이터의 수집 및 사용</h2>
    <h3>수집된 데이터 유형</h3>
    <h4>개인 데이터</h4>
    <p>
      당사의 서비스를 사용하면서 귀하에게 연락하거나 식별하는 데 사용할 수 있는 특정 개인 식별 정보를 제공하도록 요청할
      수 있습니다. 개인 식별 정보에는 다음이 포함될 수 있지만 이에 국한되지는 않습니다:
    </p>
    <ul>
      <li>이메일 주소</li>
      <li>이름과 성</li>
      <li>전화번호</li>
      <li>주소, 주, 도, 우편번호, 도시</li>
      <li>사용 데이터</li>
    </ul>
    <h4>사용 데이터</h4>
    <p>사용 데이터는 서비스 사용 시 자동으로 수집됩니다.</p>
    <p>
      사용 데이터에는 귀하의 기기의 인터넷 프로토콜 주소(IP 주소), 브라우저 유형, 브라우저 버전, 당사 서비스의 방문한
      페이지, 방문 시간과 날짜, 해당 페이지에서 보낸 시간, 고유 기기 식별자 및 기타 진단 데이터와 같은 정보가 포함될 수
      있습니다.
    </p>
    <p>
      귀하가 모바일 기기를 통해 서비스를 액세스하는 경우, 사용 데이터에는 귀하가 사용하는 모바일 기기 유형, 모바일
      기기의 고유 ID, 모바일 기기의 IP 주소, 모바일 운영 체제, 귀하가 사용하는 모바일 인터넷 브라우저 유형, 고유 기기
      식별자 및 기타 진단 데이터와 같은 정보가 포함될 수 있습니다.
    </p>
    <h4>추적 기술 및 쿠키</h4>
    <p>
      당사는 쿠키 및 유사한 추적 기술을 사용하여 당사 서비스의 활동을 추적하고 특정 정보를 저장합니다. 사용된 추적
      기술에는 비콘, 태그 및 스크립트가 포함되어 있으며 정보를 수집하고 추적하며 당사 서비스를 개선하고 분석하는 데
      사용됩니다.
    </p>
    <p>사용되는 기술은 다음을 포함할 수 있습니다:</p>
    <ul>
      <li>
        <strong>쿠키 또는 브라우저 쿠키.</strong> 쿠키는 귀하의 기기에 배치되는 작은 파일입니다. 귀하는 모든 쿠키를
        거부하거나 쿠키가 전송될 때 표시하도록 브라우저에 지시할 수 있습니다. 그러나 쿠키를 허용하지 않으면 당사
        서비스의 일부를 사용할 수 없을 수 있습니다.
      </li>
      <li>
        <strong>웹 비콘.</strong> 당사 서비스의 특정 섹션과 이메일에는 작은 전자 파일인 웹 비콘(클리어 GIF, 픽셀 태그 및
        싱글 픽셀 GIF라고도 함)이 포함될 수 있습니다. 예를 들어 회사는 해당 페이지를 방문한 사용자 수를 세거나 이메일을
        열어보는 등 웹 사이트 통계(예: 특정 섹션의 인기 기록 및 시스템 및 서버 무결성 확인)를 기록할 수 있습니다.
      </li>
    </ul>
    <p>당사는 아래에 설명된 목적을 위해 세션 쿠키와 지속적인 쿠키를 사용합니다:</p>
    <ul>
      <li>
        <strong>필수 / 필수 쿠키</strong>
        유형: 세션 쿠키 관리: 당사 목적: 이러한 쿠키는 웹 사이트를 통해 제공되는 서비스와 일부 기능을 사용할 수 있도록
        하기 위해 필수적입니다. 이들은 사용자 인증을 돕고 사용자 계정의 사기성 사용을 방지합니다. 이러한 쿠키가 없으면
        귀하가 요청한 서비스가 제공되지 않으며, 당사는 이러한 쿠키를 사용하여 해당 서비스를 제공합니다.
      </li>
      <li>
        <strong>쿠키 정책 / 공지 수락 쿠키</strong>
        유형: 지속 쿠키 관리: 당사 목적: 이러한 쿠키는 사용자가 웹 사이트에서 쿠키 사용을 수락했는지 여부를 식별합니다.
      </li>
      <li>
        <strong>기능 쿠키</strong>
        유형: 지속 쿠키 관리: 당사 목적: 이러한 쿠키는 귀하가 웹 사이트를 사용할 때 선택한 사항을 기억할 수 있게
        해줍니다. 예를 들어 로그인 정보 또는 언어 기본 설정을 기억합니다. 이러한 쿠키의 목적은 더 개인화된 경험을
        제공하고 매번 웹 사이트를 사용할 때마다 기본 설정을 다시 입력할 필요가 없도록 하는 것입니다.
      </li>
    </ul>
    <h4>응용 프로그램 사용 시 수집된 정보</h4>
    <p>응용 프로그램 사용 시, 응용 프로그램의 기능을 제공하기 위해 사전 허가를 받아 다음 정보를 수집할 수 있습니다:</p>
    <ul>
      <li>귀하의 위치에 관한 정보</li>
      <li>귀하의 기기 전화번호부(연락처 목록)의 정보</li>
      <li>귀하의 기기 카메라 및 사진 라이브러리의 사진 및 기타 정보</li>
    </ul>
    <p>
      당사는 이러한 정보를 사용하여 서비스 기능을 제공하고 서비스 품질을 개선하고 사용자 맞춤형 서비스를 제공합니다.
      정보는 회사 서버 및/또는 서비스 제공자의 서버에 업로드되거나 귀하의 기기에 단순히 저장될 수 있습니다.
    </p>
    <p>귀하는 언제든지 기기 설정을 통해 이러한 정보에 대한 접근을 활성화하거나 비활성화할 수 있습니다.</p>
    <h3>귀하의 개인 데이터 사용</h3>

    <p>회사는 개인 데이터를 다음 목적으로 사용할 수 있습니다:</p>
    <ul>
      <li>
        <strong>우리의 서비스를 제공하고 유지하기 위해</strong>, 포함하여 우리의 서비스 사용을 모니터링하기 위해.
      </li>
      <li>
        <strong>귀하의 계정을 관리하기 위해:</strong> 귀하의 서비스 사용자로서의 등록을 관리하기 위해. 귀하가 제공하는
        개인 데이터는 등록된 사용자로서 사용할 수 있는 서비스의 다양한 기능에 접근할 수 있게 합니다.
      </li>
      <li>
        <strong>계약의 이행을 위해:</strong> 귀하가 구매한 제품, 항목 또는 서비스의 구매 계약 또는 서비스와 관련하여
        귀하와의 기타 계약의 개발, 준수 및 수행을 위해.
      </li>
      <li>
        <strong>귀하에게 연락하기 위해:</strong> 이메일, 전화 통화, SMS 또는 모바일 응용 프로그램의 푸시 알림과 같은
        전자 통신의 기타 동등한 형태로 귀하에게 연락하여 기능, 제품 또는 계약된 서비스와 관련된 업데이트 또는 정보성
        통신을 제공하기 위해, 포함하여 보안 업데이트가 필요하거나 합리적일 때.
      </li>
      <li>
        귀하가 이미 구매했거나 문의한 것과 유사한 기타 상품, 서비스 및 이벤트에 관한 뉴스, 특별 제안 및 일반 정보를
        제공하기 위해, 귀하가 그러한 정보를 수신하지 않기로 선택한 경우를 제외하고.
      </li>
      <li>
        <strong>귀하의 요청을 관리하기 위해:</strong> 귀하의 요청에 응답하고 관리하기 위해.
      </li>
    </ul>
    <h4>비즈니스 이전을 위해:</h4>
    <p>
      당사는 합병, 분사, 구조 조정, 재조직, 해체 또는 기타 일부 또는 전부 자산의 매각 또는 이전을 평가하거나 수행하기
      위해 귀하의 정보를 사용할 수 있습니다. 이 경우 서비스 사용자에 대한 개인 데이터가 이전 자산에 포함됩니다.
    </p>
    <h4>기타 목적을 위해:</h4>
    <p>
      당사는 데이터를 분석하고, 사용 경향을 식별하고, 판촉 캠페인의 효과를 결정하며, 서비스, 제품, 마케팅 및 사용자
      경험을 평가하고 개선하기 위한 기타 목적으로 귀하의 정보를 사용할 수 있습니다. 당사는 다음과 같은 상황에서 귀하의
      개인 정보를 공유할 수 있습니다:
    </p>
    <ul>
      <li>
        <strong>서비스 제공자와 함께:</strong> 당사는 귀하의 개인 정보를 서비스 제공자와 공유하여 당사 서비스의 사용을
        모니터링 및 분석하고, 귀하와 연락합니다.
      </li>
      <li>
        <strong>비즈니스 이전을 위해:</strong> 당사는 합병, 회사 자산 판매, 자금 조달 또는 당사의 전부 또는 일부
        비즈니스의 인수와 관련하여 또는 협상 중에 귀하의 개인 정보를 공유하거나 이전할 수 있습니다.
      </li>
      <li>
        <strong>계열사와 함께:</strong> 당사는 계열사와 귀하의 정보를 공유할 수 있으며, 이 경우 계열사가 본 개인정보
        처리방침을 준수하도록 요구할 것입니다. 계열사에는 당사의 모회사와 기타 자회사, 합작 파트너 또는 당사가
        통제하거나 당사와 공동 통제하에 있는 기타 회사가 포함됩니다.
      </li>
      <li>
        <strong>비즈니스 파트너와 함께:</strong> 당사는 비즈니스 파트너와 귀하의 정보를 공유하여 특정 제품, 서비스 또는
        프로모션을 제공할 수 있습니다.
      </li>
      <li>
        <strong>다른 사용자와 함께:</strong> 귀하가 다른 사용자와 공공 영역에서 개인 정보를 공유하거나 상호 작용할 때,
        해당 정보는 모든 사용자에 의해 볼 수 있으며 공개적으로 배포될 수 있습니다.
      </li>
      <li>
        <strong>귀하의 동의와 함께:</strong> 당사는 귀하의 동의를 받아 귀하의 개인 정보를 기타 목적을 위해 공개할 수
        있습니다.
      </li>
    </ul>
    <h3>귀하의 개인 데이터 보유</h3>
    <p>
      당사는 본 개인정보 처리방침에 명시된 목적을 달성하는 데 필요한 기간 동안만 귀하의 개인 데이터를 보유할 것입니다.
      당사는 법적 의무를 준수하기 위해 필요한 범위 내에서 귀하의 개인 데이터를 보유 및 사용할 것입니다(예: 해당 법률을
      준수하기 위해 데이터를 보유해야 하는 경우), 분쟁을 해결하고, 법적 계약 및 정책을 집행합니다.
    </p>
    <p>
      당사는 또한 내부 분석 목적으로 사용 데이터를 보유할 것입니다. 사용 데이터는 일반적으로 더 짧은 기간 동안
      보유되지만, 보안 강화 또는 서비스 기능 개선을 위해 사용되거나 법적으로 더 긴 기간 동안 데이터를 보유해야 하는
      경우를 제외하고.
    </p>
    <h3>귀하의 개인 데이터 이전</h3>
    <p>
      귀하의 정보, 포함하여 개인 데이터는 회사의 운영 사무소와 처리에 관여하는 당사자가 위치한 다른 장소에서 처리됩니다.
      이는 귀하의 정보가 귀하의 주, 도, 국가 또는 기타 정부 관할 구역 외부에 있는 컴퓨터로 이전 및 유지될 수 있음을
      의미합니다.
    </p>
    <p>귀하는 본 개인정보 처리방침에 대한 동의를 통해 그러한 정보를 제출함으로써 해당 이전에 동의하게 됩니다.</p>
    <p>
      당사는 귀하의 데이터를 안전하게 처리하고 본 개인정보 처리방침에 따라 처리되도록 합리적으로 필요한 모든 조치를 취할
      것이며, 적절한 통제가 이루어지지 않는 한 귀하의 개인 데이터가 조직이나 국가로 이전되지 않습니다.
    </p>
    <h3>귀하의 개인 데이터 공개</h3>
    <h4>비즈니스 거래</h4>
    <p>
      회사가 합병, 인수 또는 자산 매각에 관여하는 경우 귀하의 개인 데이터가 이전될 수 있습니다. 귀하의 개인 데이터가
      이전되기 전에 이를 통지하고 다른 개인정보 처리방침의 적용을 받게 됩니다.
    </p>
    <h4>법 집행</h4>
    <p>
      특정 상황에서는 회사가 법적 요구 또는 공공 기관의 유효한 요청(예: 법원 또는 정부 기관)에 따라 귀하의 개인 데이터를
      공개해야 할 수 있습니다.
    </p>
    <h4>기타 법적 요구</h4>
    <p>회사는 선의로 다음과 같은 조치를 위해 귀하의 개인 데이터를 공개할 수 있습니다:</p>
    <ul>
      <li>법적 의무 준수</li>
      <li>회사의 권리 또는 재산 보호 및 방어</li>
      <li>서비스와 관련된 잠재적 불법 행위 방지 또는 조사</li>
      <li>서비스 사용자 또는 대중의 개인 안전 보호</li>
      <li>법적 책임으로부터 보호</li>
    </ul>
    <h3>귀하의 개인 데이터 보안</h3>
    <p>
      귀하의 개인 데이터 보안은 중요하지만, 인터넷을 통한 전송 방법이나 전자 저장 방법은 100% 안전하지 않습니다. 당사는
      상업적으로 허용 가능한 수단을 사용하여 귀하의 개인 데이터를 보호하려고 노력하지만 절대적인 보안을 보장할 수는
      없습니다.
    </p>
    <h2>어린이의 개인정보 보호</h2>
    <p>
      당사 서비스는 13세 미만의 누구에게도 제공되지 않습니다. 당사는 13세 미만의 누구로부터 고의로 개인 식별 정보를
      수집하지 않습니다. 부모 또는 보호자인 귀하가 자녀가 당사에 개인 데이터를 제공한 사실을 알고 있는 경우, 당사에
      연락해 주십시오. 13세 미만의 누구로부터 부모의 동의 없이 개인 데이터를 수집한 사실을 알게 된 경우, 당사는 해당
      정보를 당사 서버에서 삭제하기 위해 필요한 조치를 취합니다.
    </p>
    <p>
      귀하의 정보 처리를 위한 법적 근거로 동의가 필요한 경우, 귀하의 국가에서 부모의 동의를 요구하는 경우, 당사는 해당
      정보를 수집하고 사용하기 전에 부모의 동의를 요구할 수 있습니다.
    </p>
    <h2>다른 웹사이트로의 링크</h2>
    <p>
      당사 서비스에는 당사가 운영하지 않는 다른 웹사이트에 대한 링크가 포함될 수 있습니다. 타사 링크를 클릭하면 해당
      타사 사이트로 이동합니다. 방문하는 모든 사이트의 개인정보 처리방침을 검토할 것을 강력히 권장합니다.
    </p>
    <p>
      당사는 타사 사이트나 서비스의 콘텐츠, 개인정보 처리방침 또는 관행에 대해 어떠한 통제도 하지 않으며, 책임을 지지
      않습니다.
    </p>
    <h2>본 개인정보 처리방침의 변경 사항</h2>
    <p>
      당사는 수시로 개인정보 처리방침을 업데이트할 수 있습니다. 새로운 개인정보 처리방침을 이 페이지에 게시하여 변경
      사항을 알려드립니다.
    </p>
    <p>
      변경 사항이 발효되기 전에 이메일 및/또는 당사 서비스에 눈에 띄게 공지하고, 본 개인정보 처리방침 상단의 "마지막
      업데이트" 날짜를 업데이트합니다.
    </p>
    <p>
      변경 사항에 대한 주기적인 검토를 권장합니다. 본 개인정보 처리방침의 변경 사항은 이 페이지에 게시될 때 적용됩니다.
    </p>
    <h2>문의하기</h2>
    <p>본 개인정보 처리방침에 대해 궁금한 점이 있으시면 다음 방법으로 당사에 문의하실 수 있습니다:</p>
    <ul>
      <li>이메일: contact@unifeed.news</li>
    </ul>
  </div>
));
