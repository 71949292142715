import {PageContents} from '../utils';

export const pageContents: PageContents = {
  title: 'UniFeed News',
  headline: 'See the World Unfiltered – Real-Time News Digests from 13 Countries in 16 Languages',
  subheading:
    'Explore comprehensive, unbiased real-time news digests from around the world, all conveniently translated into your native language for clarity and understanding',
  or: 'OR',
  launchWebApp: 'Launch Web App',
};
