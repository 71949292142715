import * as React from 'react';

export const Pl: React.FC = React.memo(() => (
  <div>
    <h1>Polityka Prywatności</h1>
    <p>Ostatnia aktualizacja: 5 lipca 2024 r.</p>
    <p>
      Niniejsza Polityka Prywatności opisuje nasze zasady i procedury dotyczące zbierania, wykorzystywania i ujawniania
      informacji o Tobie podczas korzystania z Usługi oraz informuje Cię o Twoich prawach do prywatności i o tym, jak
      prawo chroni Twoją prywatność.
    </p>
    <p>
      Wykorzystujemy Twoje dane osobowe, aby świadczyć i ulepszać Usługę, w tym wyświetlać spersonalizowane reklamy i
      oferować płatne subskrypcje. Korzystając z Usługi, wyrażasz zgodę na zbieranie i wykorzystywanie informacji
      zgodnie z niniejszą Polityką Prywatności.
    </p>
    <h2>Interpretacja i Definicje</h2>
    <h3>Interpretacja</h3>
    <p>
      Słowa, których początkowa litera jest pisana wielką literą, mają znaczenie zdefiniowane w poniższych warunkach.
      Następujące definicje mają to samo znaczenie, niezależnie od tego, czy pojawiają się w liczbie pojedynczej, czy
      mnogiej.
    </p>
    <h3>Definicje</h3>
    <p>Na potrzeby niniejszej Polityki Prywatności:</p>
    <ul>
      <li>
        <strong>Konto</strong> oznacza unikalne konto utworzone dla Ciebie w celu uzyskania dostępu do naszej Usługi lub
        jej części.
      </li>
      <li>
        <strong>Podmiot Powiązany</strong> oznacza jednostkę, która kontroluje, jest kontrolowana przez lub znajduje się
        pod wspólną kontrolą z daną stroną, gdzie "kontrola" oznacza posiadanie 50% lub więcej akcji, udziałów
        kapitałowych lub innych papierów wartościowych uprawniających do głosowania na wybór dyrektorów lub innej władzy
        zarządzającej.
      </li>
      <li>
        <strong>Aplikacja</strong> odnosi się do UniFeed News, programu oprogramowania dostarczonego przez Firmę.
      </li>
      <li>
        <strong>Firma</strong> (określana jako "Firma", "My", "Nas" lub "Nasz" w niniejszej Umowie) odnosi się do Ropsid
        Inc.
      </li>
      <li>
        <strong>Kraj</strong> odnosi się do: Ontario, Kanada
      </li>
      <li>
        <strong>Urządzenie</strong> oznacza każde urządzenie, które może uzyskać dostęp do Usługi, takie jak komputer,
        telefon komórkowy lub tablet cyfrowy.
      </li>
      <li>
        <strong>Dane Osobowe</strong> oznaczają wszelkie informacje, które odnoszą się do zidentyfikowanej lub możliwej
        do zidentyfikowania osoby fizycznej.
      </li>
      <li>
        <strong>Usługa</strong> odnosi się do Aplikacji.
      </li>
      <li>
        <strong>Dostawca Usług</strong> oznacza każdą osobę fizyczną lub prawną, która przetwarza dane w imieniu Firmy.
        Odnosi się to do firm trzecich lub osób zatrudnionych przez Firmę w celu ułatwienia świadczenia Usługi,
        świadczenia Usługi w imieniu Firmy, wykonywania usług związanych z Usługą lub pomocy Firmie w analizie, jak
        Usługa jest wykorzystywana.
      </li>
      <li>
        <strong>Dane Użytkowania</strong> oznaczają dane zbierane automatycznie, generowane przez korzystanie z Usługi
        lub z samej infrastruktury Usługi (na przykład czas trwania odwiedzin strony).
      </li>
      <li>
        <strong>Ty</strong> oznacza osobę uzyskującą dostęp do Usługi lub korzystającą z niej, lub firmę, lub inną
        jednostkę prawną w imieniu której dana osoba uzyskuje dostęp do Usługi lub korzysta z niej, w zależności od
        sytuacji.
      </li>
    </ul>
    <h2>Zbieranie i Wykorzystywanie Twoich Danych Osobowych</h2>
    <h3>Rodzaje Zbieranych Danych</h3>
    <h4>Dane Osobowe</h4>
    <p>
      Podczas korzystania z naszej Usługi możemy poprosić Cię o podanie pewnych danych osobowych, które mogą być użyte
      do kontaktu z Tobą lub do Twojej identyfikacji. Dane osobowe mogą obejmować, ale nie ograniczają się do:
    </p>
    <ul>
      <li>Adres e-mail</li>
      <li>Imię i nazwisko</li>
      <li>Numer telefonu</li>
      <li>Adres, Stan, Prowincja, Kod pocztowy, Miasto</li>
      <li>Dane Użytkowania</li>
    </ul>
    <h4>Dane Użytkowania</h4>
    <p>Dane Użytkowania są zbierane automatycznie podczas korzystania z Usługi.</p>
    <p>
      Dane Użytkowania mogą obejmować informacje takie jak adres IP Twojego Urządzenia (np. adres IP), typ przeglądarki,
      wersja przeglądarki, strony naszej Usługi, które odwiedzasz, czas i data Twojej wizyty, czas spędzony na tych
      stronach, unikalne identyfikatory urządzeń i inne dane diagnostyczne.
    </p>
    <p>
      Kiedy uzyskujesz dostęp do Usługi przez urządzenie mobilne lub za jego pośrednictwem, informacje te mogą obejmować
      informacje takie jak typ używanego urządzenia mobilnego, unikalny identyfikator urządzenia mobilnego, adres IP
      urządzenia mobilnego, system operacyjny urządzenia mobilnego, typ przeglądarki internetowej urządzenia mobilnego,
      unikalne identyfikatory urządzeń i inne dane diagnostyczne.
    </p>
    <h4>Technologie Śledzenia i Pliki Cookie</h4>
    <p>
      Używamy plików cookie i podobnych technologii śledzenia, aby śledzić aktywność w naszej Usłudze i przechowywać
      pewne informacje. Technologie śledzenia używane są beacony, tagi i skrypty do zbierania i śledzenia informacji
      oraz do ulepszania i analizy naszej Usługi.
    </p>
    <p>Technologie, których używamy, mogą obejmować:</p>
    <ul>
      <li>
        <strong>Pliki cookie lub pliki cookie przeglądarki.</strong> Cookie to mały plik umieszczany na Twoim
        Urządzeniu. Możesz skonfigurować przeglądarkę tak, aby odrzucała wszystkie pliki cookie lub aby wskazywała,
        kiedy plik cookie jest wysyłany. Jednakże, jeśli nie zaakceptujesz plików cookie, możesz nie być w stanie
        korzystać z niektórych części naszej Usługi.
      </li>
      <li>
        <strong>Web Beacons.</strong> Niektóre sekcje naszej Usługi i nasze e-maile mogą zawierać małe elektroniczne
        pliki znane jako web beacons (również nazywane clear gifs, pixel tags, i single-pixel gifs), które pozwalają
        Firmie, na przykład, liczyć użytkowników, którzy odwiedzili te strony lub otworzyli e-mail oraz na inne
        powiązane statystyki dotyczące strony internetowej (na przykład rejestrowanie popularności określonej sekcji i
        sprawdzanie integralności systemu i serwera).
      </li>
    </ul>
    <p>Używamy zarówno sesyjnych, jak i trwałych plików cookie do celów określonych poniżej:</p>
    <ul>
      <li>
        <strong>Nieodzowne / Niezbędne pliki cookie</strong>
        Typ: Pliki cookie sesyjne Administrowane przez: Nas Cel: Te pliki cookie są niezbędne do świadczenia usług
        dostępnych na Stronie oraz do umożliwienia korzystania z niektórych jej funkcji. Pomagają one uwierzytelniać
        użytkowników i zapobiegać oszukańczemu wykorzystaniu kont użytkowników. Bez tych plików cookie usługi, o które
        prosiłeś, nie mogą być świadczone, a my używamy tych plików cookie wyłącznie do świadczenia tych usług.
      </li>
      <li>
        <strong>Polityka plików cookie / pliki cookie do zaakceptowania polityki</strong>
        Typ: Trwałe pliki cookie Administrowane przez: Nas Cel: Te pliki cookie identyfikują, czy użytkownicy
        zaakceptowali korzystanie z plików cookie na Stronie.
      </li>
      <li>
        <strong>Pliki cookie funkcjonalności</strong>
        Typ: Trwałe pliki cookie Administrowane przez: Nas Cel: Te pliki cookie pozwalają nam zapamiętywać wybory, które
        dokonujesz podczas korzystania z Strony, takie jak zapamiętywanie danych logowania lub preferencji językowych.
        Celem tych plików cookie jest dostarczenie Ci bardziej spersonalizowanego doświadczenia i uniknięcie
        konieczności ponownego wprowadzania preferencji za każdym razem, gdy korzystasz z Strony.
      </li>
    </ul>
    <h4>Informacje Zbierane podczas Korzystania z Aplikacji</h4>
    <p>
      Podczas korzystania z naszej Aplikacji, aby zapewnić funkcje naszej Aplikacji, możemy zbierać, za Twoją uprzednią
      zgodą:
    </p>
    <ul>
      <li>Informacje dotyczące Twojej lokalizacji</li>
      <li>Informacje z książki telefonicznej Twojego Urządzenia (lista kontaktów)</li>
      <li>Zdjęcia i inne informacje z aparatu i biblioteki zdjęć Twojego Urządzenia</li>
    </ul>
    <p>
      Wykorzystujemy te informacje, aby zapewnić funkcje naszej Usługi, ulepszać i dostosowywać naszą Usługę. Informacje
      mogą być przesyłane na serwery Firmy i/lub serwer Dostawcy Usług lub mogą być przechowywane na Twoim urządzeniu.
    </p>
    <p>Możesz w każdej chwili włączyć lub wyłączyć dostęp do tych informacji poprzez ustawienia Twojego Urządzenia.</p>
    <h3>Wykorzystanie Twoich Danych Osobowych</h3>
    <p>Firma może wykorzystywać Dane Osobowe do następujących celów:</p>
    <ul>
      <li>
        <strong>Świadczenie i utrzymanie naszej Usługi</strong>, w tym monitorowanie korzystania z naszej Usługi.
      </li>
      <li>
        <strong>Zarządzanie Twoim Kontem:</strong> zarządzanie Twoją rejestracją jako użytkownika Usługi. Dane Osobowe,
        które podajesz, mogą dać Ci dostęp do różnych funkcji Usługi, które są dostępne dla Ciebie jako zarejestrowanego
        użytkownika.
      </li>
      <li>
        <strong>Wykonanie umowy:</strong> rozwój, realizacja i realizacja umowy zakupu produktów, przedmiotów lub usług,
        które zakupiłeś lub jakiejkolwiek innej umowy z nami za pośrednictwem Usługi.
      </li>
      <li>
        <strong>Kontakt z Tobą:</strong> Kontakt z Tobą za pomocą e-maila, połączeń telefonicznych, SMS-ów lub innych
        równoważnych form komunikacji elektronicznej, takich jak powiadomienia push aplikacji mobilnej, dotyczące
        aktualizacji lub informacyjnych komunikatów związanych z funkcjonalnościami, produktami lub usługami
        kontraktowanymi, w tym aktualizacje zabezpieczeń, gdy jest to konieczne lub uzasadnione dla ich wdrożenia.
      </li>
      <li>
        Aby dostarczyć Ci wiadomości, specjalne oferty i ogólne informacje o innych towarach, usługach i wydarzeniach,
        które oferujemy, które są podobne do tych, które już zakupiłeś lub o które zapytałeś, chyba że zdecydowałeś się
        nie otrzymywać takich informacji.
      </li>
      <li>
        <strong>Zarządzanie Twoimi żądaniami:</strong> Obsługa i zarządzanie Twoimi żądaniami do nas.
      </li>
    </ul>
    <h4>W przypadku transferów biznesowych:</h4>
    <p>
      Możemy wykorzystywać Twoje informacje do oceny lub przeprowadzenia fuzji, podziału, restrukturyzacji,
      reorganizacji, rozwiązania lub innej sprzedaży lub transferu części lub całości naszych aktywów, niezależnie od
      tego, czy jako przedsiębiorstwo funkcjonujące, czy jako część postępowania upadłościowego, likwidacyjnego lub
      podobnego, w którym dane osobowe posiadane przez nas dotyczące naszych użytkowników Usługi są wśród przenoszonych
      aktywów.
    </p>
    <h4>W innych celach:</h4>
    <p>
      Możemy wykorzystywać Twoje informacje do innych celów, takich jak analiza danych, identyfikowanie trendów
      użytkowania, określanie skuteczności naszych kampanii promocyjnych oraz ocena i ulepszanie naszej Usługi,
      produktów, marketingu i Twojego doświadczenia. Możemy udostępniać Twoje dane osobowe w następujących sytuacjach:
    </p>
    <ul>
      <li>
        <strong>Z Dostawcami Usług:</strong> Możemy udostępniać Twoje dane osobowe Dostawcom Usług w celu monitorowania
        i analizowania korzystania z naszej Usługi, kontaktowania się z Tobą.
      </li>
      <li>
        <strong>W przypadku transferów biznesowych:</strong> Możemy udostępniać lub przekazywać Twoje dane osobowe w
        związku z, lub w trakcie negocjacji, jakiejkolwiek fuzji, sprzedaży aktywów Firmy, finansowania lub przejęcia
        całości lub części naszej działalności przez inną firmę.
      </li>
      <li>
        <strong>Z Podmiotami Powiązanymi:</strong> Możemy udostępniać Twoje informacje naszym podmiotom powiązanym, w
        takim przypadku będziemy wymagać od tych podmiotów powiązanych przestrzegania niniejszej Polityki Prywatności.
        Podmioty powiązane obejmują naszą firmę macierzystą i wszelkie inne spółki zależne, partnerów joint venture lub
        inne firmy, które kontrolujemy lub które są pod wspólną kontrolą z nami.
      </li>
      <li>
        <strong>Z partnerami biznesowymi:</strong> Możemy udostępniać Twoje informacje naszym partnerom biznesowym, aby
        oferować Ci określone produkty, usługi lub promocje.
      </li>
      <li>
        <strong>Z innymi użytkownikami:</strong> Kiedy udostępniasz dane osobowe lub w inny sposób wchodzisz w
        interakcję w publicznych obszarach z innymi użytkownikami, takie informacje mogą być widoczne dla wszystkich
        użytkowników i mogą być publicznie dystrybuowane poza nimi.
      </li>
      <li>
        <strong>Za Twoją zgodą:</strong> Możemy ujawniać Twoje dane osobowe w każdym innym celu za Twoją zgodą.
      </li>
    </ul>
    <h3>Przechowywanie Twoich Danych Osobowych</h3>
    <p>
      Firma będzie przechowywać Twoje Dane Osobowe tylko przez okres niezbędny do celów określonych w niniejszej
      Polityce Prywatności. Będziemy przechowywać i wykorzystywać Twoje Dane Osobowe w zakresie niezbędnym do
      wypełnienia naszych obowiązków prawnych (na przykład, jeśli jesteśmy zobowiązani do przechowywania Twoich danych w
      celu przestrzegania obowiązujących przepisów prawa), rozwiązywania sporów i egzekwowania naszych umów i zasad
      prawnych.
    </p>
    <p>
      Firma będzie również przechowywać Dane Użytkowania do celów analizy wewnętrznej. Dane Użytkowania są zazwyczaj
      przechowywane przez krótszy okres czasu, chyba że dane te są wykorzystywane do wzmocnienia bezpieczeństwa lub do
      poprawy funkcjonalności naszej Usługi, lub jeśli jesteśmy prawnie zobowiązani do przechowywania tych danych przez
      dłuższy okres czasu.
    </p>
    <h3>Przekazywanie Twoich Danych Osobowych</h3>
    <p>
      Twoje informacje, w tym Dane Osobowe, są przetwarzane w biurach operacyjnych Firmy i w innych miejscach, gdzie
      znajdują się strony zaangażowane w przetwarzanie. Oznacza to, że te informacje mogą być przekazywane do — i
      przechowywane na — komputerach znajdujących się poza Twoim stanem, prowincją, krajem lub inną jurysdykcją rządową,
      gdzie przepisy dotyczące ochrony danych mogą różnić się od tych z Twojej jurysdykcji.
    </p>
    <p>
      Twoja zgoda na niniejszą Politykę Prywatności, a następnie przesłanie takich informacji oznacza Twoją zgodę na ten
      transfer.
    </p>
    <p>
      Firma podejmie wszelkie uzasadnione kroki, aby zapewnić, że Twoje dane są traktowane bezpiecznie i zgodnie z
      niniejszą Polityką Prywatności i żaden transfer Twoich Danych Osobowych nie odbędzie się do organizacji lub kraju,
      chyba że istnieją odpowiednie kontrole w tym miejscu, w tym bezpieczeństwo Twoich danych i innych danych
      osobowych.
    </p>
    <h3>Ujawnianie Twoich Danych Osobowych</h3>
    <h4>Transakcje Biznesowe</h4>
    <p>
      Jeśli Firma jest zaangażowana w fuzję, przejęcie lub sprzedaż aktywów, Twoje Dane Osobowe mogą być przekazane.
      Powiadomimy o tym przed przekazaniem Twoich Danych Osobowych i stają się one przedmiotem innej Polityki
      Prywatności.
    </p>
    <h4>Egzekwowanie Prawa</h4>
    <p>
      W określonych okolicznościach Firma może być zobowiązana do ujawnienia Twoich Danych Osobowych, jeśli jest to
      wymagane przez prawo lub w odpowiedzi na ważne żądania władz publicznych (np. sąd lub agencja rządowa).
    </p>
    <h4>Inne Wymogi Prawne</h4>
    <p>Firma może ujawniać Twoje Dane Osobowe w dobrej wierze, że takie działanie jest konieczne do:</p>
    <ul>
      <li>Przestrzegania zobowiązań prawnych</li>
      <li>Ochrony i obrony praw lub własności Firmy</li>
      <li>Zapobiegania lub badania możliwych nadużyć w związku z Usługą</li>
      <li>Ochrony osobistego bezpieczeństwa użytkowników Usługi lub publiczności</li>
      <li>Ochrony przed odpowiedzialnością prawną</li>
    </ul>
    <h3>Bezpieczeństwo Twoich Danych Osobowych</h3>
    <p>
      Bezpieczeństwo Twoich Danych Osobowych jest dla nas ważne, ale pamiętaj, że żadna metoda przesyłania danych przez
      Internet ani metoda przechowywania elektronicznego nie jest w 100% bezpieczna. Choć staramy się stosować
      komercyjnie akceptowalne środki ochrony Twoich Danych Osobowych, nie możemy zagwarantować ich absolutnego
      bezpieczeństwa.
    </p>
    <h2>Prywatność Dzieci</h2>
    <p>
      Nasza Usługa nie jest skierowana do osób poniżej 13 roku życia. Nie zbieramy świadomie danych osobowych od osób
      poniżej 13 roku życia. Jeśli jesteś rodzicem lub opiekunem i wiesz, że Twoje dziecko dostarczyło nam Dane Osobowe,
      skontaktuj się z nami. Jeśli dowiemy się, że zebraliśmy Dane Osobowe od osoby poniżej 13 roku życia bez
      weryfikacji zgody rodzica, podejmiemy kroki w celu usunięcia tych informacji z naszych serwerów.
    </p>
    <p>
      Jeśli musimy polegać na zgodzie jako podstawie prawnej do przetwarzania Twoich informacji, a Twój kraj wymaga
      zgody rodzica, możemy wymagać zgody Twojego rodzica przed zebraniem i użyciem tych informacji.
    </p>
    <h2>Linki do Innych Stron Internetowych</h2>
    <p>
      Nasza Usługa może zawierać linki do innych stron internetowych, które nie są przez nas zarządzane. Jeśli klikniesz
      link strony trzeciej, zostaniesz przekierowany do strony tej strony trzeciej. Zalecamy zdecydowanie zapoznanie się
      z Polityką Prywatności każdej strony, którą odwiedzasz.
    </p>
    <p>
      Nie mamy żadnej kontroli nad treściami, politykami prywatności ani praktykami żadnych stron trzecich lub usług.
    </p>
    <h2>Zmiany w niniejszej Polityce Prywatności</h2>
    <p>
      Możemy od czasu do czasu aktualizować naszą Politykę Prywatności. Powiadomimy Cię o wszelkich zmianach, publikując
      nową Politykę Prywatności na tej stronie.
    </p>
    <p>
      Poinformujemy Cię o tym za pośrednictwem e-maila i/lub zauważalnego powiadomienia w naszej Usłudze, zanim zmiana
      stanie się skuteczna i zaktualizujemy datę "Ostatnia aktualizacja" na górze tej Polityki Prywatności.
    </p>
    <p>
      Zaleca się okresowe przeglądanie tej Polityki Prywatności pod kątem wszelkich zmian. Zmiany w niniejszej Polityce
      Prywatności są skuteczne, gdy są opublikowane na tej stronie.
    </p>
    <h2>Kontakt z Nami</h2>
    <p>Jeśli masz jakiekolwiek pytania dotyczące tej Polityki Prywatności, możesz skontaktować się z nami:</p>
    <ul>
      <li>Poprzez e-mail: contact@unifeed.news</li>
    </ul>
  </div>
));
