import * as React from 'react';

export const Zh: React.FC = React.memo(() => (
  <div>
    <h1>隐私政策</h1>
    <p>最后更新日期：2024年7月5日</p>
    <p>
      本隐私政策描述了您在使用本服务时，我们如何收集、使用和披露您的信息，并告知您关于您的隐私权利以及法律如何保护您。
    </p>
    <p>
      我们使用您的个人数据来提供和改进本服务，包括展示个性化广告和提供付费订阅。通过使用本服务，您同意按照本隐私政策收集和使用信息。
    </p>
    <h2>解释和定义</h2>
    <h3>解释</h3>
    <p>首字母大写的词语在下列条件下有其定义。以下定义在单数或复数情况下具有相同的意义。</p>
    <h3>定义</h3>
    <p>就本隐私政策而言：</p>
    <ul>
      <li>
        <strong>账户</strong> 是指为您创建的用于访问我们服务或我们服务部分的唯一账户。
      </li>
      <li>
        <strong>关联公司</strong>{' '}
        是指控制、被控制或与一方共同控制的实体，其中“控制”是指拥有50%或以上的股份、权益或其他有权选举董事或其他管理权的证券。
      </li>
      <li>
        <strong>应用程序</strong> 是指公司提供的UniFeed News软件程序。
      </li>
      <li>
        <strong>公司</strong>（在本协议中称为“公司”、“我们”或“我们的”）是指Ropsid Inc。
      </li>
      <li>
        <strong>国家</strong> 是指：加拿大安大略省
      </li>
      <li>
        <strong>设备</strong> 是指任何可以访问本服务的设备，如计算机、手机或数字平板电脑。
      </li>
      <li>
        <strong>个人数据</strong> 是指与已识别或可识别的个人有关的任何信息。
      </li>
      <li>
        <strong>服务</strong> 是指本应用程序。
      </li>
      <li>
        <strong>服务提供商</strong>{' '}
        是指代表公司处理数据的任何自然人或法人。它指的是由公司聘用的第三方公司或个人，以促进服务、代表公司提供服务、执行与服务相关的服务或协助公司分析如何使用服务。
      </li>
      <li>
        <strong>使用数据</strong> 是指通过使用本服务或服务基础设施自动收集的数据（例如页面访问的持续时间）。
      </li>
      <li>
        <strong>您</strong> 是指访问或使用本服务的个人，或代表该个人访问或使用本服务的公司或其他法人实体（视情况而定）。
      </li>
    </ul>
    <h2>收集和使用您的个人数据</h2>
    <h3>收集的数据类型</h3>
    <h4>个人数据</h4>
    <p>在使用我们的服务时，我们可能会要求您提供某些可用于联系或识别您的个人身份信息。个人身份信息可能包括但不限于：</p>
    <ul>
      <li>电子邮件地址</li>
      <li>名字和姓氏</li>
      <li>电话号码</li>
      <li>地址、州、省、邮政编码、城市</li>
      <li>使用数据</li>
    </ul>
    <h4>使用数据</h4>
    <p>使用数据在使用本服务时自动收集。</p>
    <p>
      使用数据可能包括诸如您的设备的互联网协议地址（例如IP地址）、浏览器类型、浏览器版本、您访问的本服务页面、您访问的时间和日期、在那些页面上花费的时间、唯一设备标识符和其他诊断数据等信息。
    </p>
    <p>
      当您通过移动设备访问本服务时，这些信息可能包括您使用的移动设备类型、您的移动设备的唯一ID、您的移动设备的IP地址、您的移动操作系统、您使用的移动互联网浏览器类型、唯一设备标识符和其他诊断数据等信息。
    </p>
    <h4>跟踪技术和Cookie</h4>
    <p>
      我们使用Cookie和类似的跟踪技术来跟踪我们服务上的活动并存储某些信息。使用的跟踪技术包括信标、标签和脚本，以收集和跟踪信息以及改进和分析我们的服务。
    </p>
    <p>我们使用的技术可能包括：</p>
    <ul>
      <li>
        <strong>Cookie或浏览器Cookie。</strong>{' '}
        Cookie是放置在您的设备上的一个小文件。您可以指示您的浏览器拒绝所有Cookie或指示何时发送Cookie。然而，如果您不接受Cookie，您可能无法使用我们服务的某些部分。
      </li>
      <li>
        <strong>网络信标。</strong>{' '}
        我们服务的某些部分和我们的电子邮件可能包含称为网络信标的小电子文件（也称为透明GIF、像素标签和单像素GIF），允许公司，例如，计算访问这些页面或打开电子邮件的用户以及其他相关网站统计数据（例如，记录某个部分的受欢迎程度和验证系统和服务器的完整性）。
      </li>
    </ul>
    <p>我们使用会话和持久性Cookie来实现以下目的：</p>
    <ul>
      <li>
        <strong>必要的/基本的Cookie</strong>
        类型：会话Cookie 管理：我们
        目的：这些Cookie是为了通过网站向您提供服务并使您能够使用其某些功能而必需的。它们有助于验证用户身份并防止用户账户的欺诈使用。如果没有这些Cookie，您所要求的服务将无法提供，我们仅使用这些Cookie来向您提供这些服务。
      </li>
      <li>
        <strong>Cookie政策/通知接受Cookie</strong>
        类型：持久性Cookie 管理：我们 目的：这些Cookie识别用户是否接受网站上的Cookie使用。
      </li>
      <li>
        <strong>功能性Cookie</strong>
        类型：持久性Cookie 管理：我们
        目的：这些Cookie允许我们记住您在使用网站时所做的选择，例如记住您的登录详情或语言偏好。这些Cookie的目的是为您提供更个性化的体验，并避免您每次使用网站时都需要重新输入您的偏好。
      </li>
    </ul>
    <h4>使用应用程序时收集的信息</h4>
    <p>在使用我们的应用程序时，为了提供应用程序的功能，我们可能会在事先征得您的同意后收集以下信息：</p>
    <ul>
      <li>有关您位置的信息</li>
      <li>您设备电话簿中的信息（联系人列表）</li>
      <li>来自您设备相机和照片库的图片和其他信息</li>
    </ul>
    <p>
      我们使用这些信息来提供我们的服务功能，以改进和自定义我们的服务。该信息可能上传到公司的服务器和/或服务提供商的服务器，或可能仅存储在您的设备上。
    </p>
    <p>您可以随时通过设备设置启用或禁用对这些信息的访问。</p>
    <h3>使用您的个人数据</h3>
    <p>公司可能将个人数据用于以下目的：</p>
    <ul>
      <li>
        <strong>提供和维护我们的服务</strong>，包括监控我们服务的使用情况。
      </li>
      <li>
        <strong>管理您的账户：</strong>
        管理您作为服务用户的注册。您提供的个人数据可以让您访问作为注册用户的服务的不同功能。
      </li>
      <li>
        <strong>履行合同：</strong>开发、遵守和承诺通过服务购买的产品、物品或服务的购买合同或与我们签订的任何其他合同。
      </li>
      <li>
        <strong>与您联系：</strong>
        通过电子邮件、电话、短信或其他等效的电子通信方式（如移动应用程序的推送通知）与您联系，关于功能、产品或合同服务的更新或信息性通信，包括安全更新，当有必要或合理时，进行这些更新。
      </li>
      <li>
        向您提供新闻、特别优惠和一般信息，关于我们提供的与您已购买或咨询过的类似商品、服务和活动，除非您已选择不接收此类信息。
      </li>
      <li>
        <strong>管理您的请求：</strong>处理和管理您对我们的请求。
      </li>
    </ul>
    <h4>进行业务转让：</h4>
    <p>
      我们可能使用您的信息来评估或进行合并、剥离、重组、重组、解散或其他出售或转让我们部分或全部资产的交易，无论是作为持续经营或作为破产、清算或类似程序的一部分，其中关于我们服务用户的个人数据是转让的资产之一。
    </p>
    <h4>出于其他目的：</h4>
    <p>
      我们可能会将您的信息用于其他目的，例如数据分析、识别使用趋势、确定我们促销活动的有效性，以及评估和改进我们的服务、产品、营销和您的体验。我们可能会在以下情况下分享您的个人信息：
    </p>
    <ul>
      <li>
        <strong>与服务提供商：</strong>
        我们可能会与服务提供商分享您的个人信息，以监控和分析我们服务的使用情况，与您联系。
      </li>
      <li>
        <strong>进行业务转让：</strong>
        我们可能会在合并、公司资产出售、融资或将我们全部或部分业务出售给另一家公司时分享或转让您的个人信息。
      </li>
      <li>
        <strong>与关联公司：</strong>
        我们可能会与我们的关联公司分享您的信息，在这种情况下，我们将要求这些关联公司遵守本隐私政策。关联公司包括我们的母公司和任何其他子公司、合资伙伴或其他我们控制或与我们共同控制的公司。
      </li>
      <li>
        <strong>与业务伙伴：</strong>我们可能会与我们的业务伙伴分享您的信息，以向您提供某些产品、服务或促销活动。
      </li>
      <li>
        <strong>与其他用户：</strong>
        当您在公共区域与其他用户共享个人信息或以其他方式互动时，此类信息可能会被所有用户查看，并可能在外部公开分发。
      </li>
      <li>
        <strong>经您同意：</strong>我们可能会为任何其他目的披露您的个人信息，但须经您同意。
      </li>
    </ul>
    <h3>保留您的个人数据</h3>
    <p>
      公司将仅在为实现本隐私政策所述目的所需的时间内保留您的个人数据。我们将在必要的范围内保留和使用您的个人数据，以遵守我们的法律义务（例如，如果我们需要保留您的数据以遵守适用法律），解决争议，并执行我们的法律协议和政策。
    </p>
    <p>
      公司还将出于内部分析目的保留使用数据。使用数据通常保留时间较短，除非这些数据用于增强安全性或改进我们服务的功能，或我们有法律义务将这些数据保留更长时间。
    </p>
    <h3>转移您的个人数据</h3>
    <p>
      您的信息，包括个人数据，在公司经营办公室和任何参与处理的各方所在的其他地方进行处理。这意味着这些信息可能会被转移到——并维护在——您所在州、省、国家或其他政府管辖区之外的计算机上，在这些地方的数据保护法律可能与您的管辖区的法律不同。
    </p>
    <p>您同意本隐私政策并提交这些信息即表示您同意该转移。</p>
    <p>
      公司将采取一切合理必要措施，以确保您的数据安全并按照本隐私政策进行处理，除非有充分的控制措施包括保护您的数据和其他个人信息，否则您的个人数据不会被转移到任何组织或国家。
    </p>
    <h3>披露您的个人数据</h3>
    <h4>业务交易</h4>
    <p>
      如果公司参与合并、收购或资产出售，您的个人数据可能会被转移。在您的个人数据被转移并受不同隐私政策约束之前，我们将提供通知。
    </p>
    <h4>法律执法</h4>
    <p>在某些情况下，如果法律要求或应公共机构（例如法院或政府机构）的有效请求，公司可能会被要求披露您的个人数据。</p>
    <h4>其他法律要求</h4>
    <p>公司可能会出于善意相信有必要采取以下行动而披露您的个人数据：</p>
    <ul>
      <li>遵守法律义务</li>
      <li>保护和捍卫公司的权利或财产</li>
      <li>防止或调查与服务有关的可能的不当行为</li>
      <li>保护服务用户或公众的人身安全</li>
      <li>防范法律责任</li>
    </ul>
    <h3>保护您的个人数据的安全</h3>
    <p>
      保护您的个人数据的安全对我们很重要，但请记住，没有任何一种通过互联网或电子存储方法的传输方式是100%安全的。虽然我们努力使用商业上可接受的方法来保护您的个人数据，但我们不能保证其绝对安全。
    </p>
    <h2>儿童隐私</h2>
    <p>
      我们的服务不面向13岁以下的任何人。我们不会故意收集13岁以下任何人的个人身份信息。如果您是家长或监护人，并且您知道您的孩子向我们提供了个人数据，请联系我们。如果我们发现我们在未经家长同意的情况下从13岁以下的任何人那里收集了个人数据，我们将采取措施从我们的服务器中删除这些信息。
    </p>
    <p>
      如果我们需要依赖同意作为处理您信息的法律依据，并且您的国家要求家长同意，我们可能会在收集和使用该信息之前需要您父母的同意。
    </p>
    <h2>链接到其他网站</h2>
    <p>
      我们的服务可能包含指向非由我们操作的其他网站的链接。如果您点击第三方链接，您将被引导到该第三方的网站。我们强烈建议您查看您访问的每个网站的隐私政策。
    </p>
    <p>我们对任何第三方网站或服务的内容、隐私政策或做法不承担任何责任。</p>
    <h2>对本隐私政策的更改</h2>
    <p>我们可能会不时更新我们的隐私政策。我们将通过在本页面发布新的隐私政策来通知您任何更改。</p>
    <p>在更改生效前，我们会通过电子邮件和/或在我们的服务上显著通知您，并更新本隐私政策顶部的“最后更新”日期。</p>
    <p>建议您定期查看本隐私政策的任何更改。对本隐私政策的更改在发布在本页面上时生效。</p>
    <h2>联系我们</h2>
    <p>如果您对本隐私政策有任何疑问，您可以通过以下方式联系我们：</p>
    <ul>
      <li>通过电子邮件：contact@unifeed.news</li>
    </ul>
  </div>
));
