import * as React from 'react';
import {Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/react';
import LanguageIcon from '@heroicons/react/16/solid/LanguageIcon';
import * as states from '@/states';
import {Locale} from '@/types';
import {getLocaleLabel} from '@/utils/common';

export const LocalesDropdown: React.FC = React.memo(() => {
  const locale = states.global.useLocaleValue();
  const setLocale = states.global.useSetLocaleState();
  return (
    <Menu>
      <MenuButton className="inline-flex cursor-pointer items-center justify-center gap-1 rounded-lg px-4 py-2 text-sm font-medium text-white hover:bg-gray-700 hover:text-white sm:min-w-[120px]">
        <LanguageIcon width={24} height={24} />
        <span className="hidden sm:block">{getLocaleLabel(locale)}</span>
      </MenuButton>
      <MenuItems
        modal={false}
        transition
        anchor="bottom end"
        className="z-[100] origin-top-right rounded-xl border border-white/5 bg-[#354877] p-1 text-sm/6 text-white shadow-xl transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
      >
        {Object.values(Locale).map((locale) => (
          <MenuItem key={locale}>
            <button
              className="group flex w-full items-center gap-2 rounded-lg px-3 py-1.5 data-[focus]:bg-white/10"
              onClick={() => setLocale(locale)}
            >
              <div className="inline-flex items-center">{getLocaleLabel(locale)}</div>
            </button>
          </MenuItem>
        ))}
      </MenuItems>
    </Menu>
  );
});
