import * as React from 'react';

export const Es: React.FC = React.memo(() => (
  <div>
    <h1>Política de Privacidad</h1>
    <p>Última actualización: 5 de julio de 2024</p>
    <p>
      Esta Política de Privacidad describe nuestras políticas y procedimientos sobre la recogida, uso y divulgación de
      su información cuando utiliza el Servicio e informa sobre sus derechos de privacidad y cómo la ley le protege.
    </p>
    <p>
      Utilizamos sus datos personales para proporcionar y mejorar el Servicio, incluyendo mostrar anuncios
      personalizados y ofrecer suscripciones pagadas. Al utilizar el Servicio, usted acepta la recogida y el uso de
      información de acuerdo con esta Política de Privacidad.
    </p>
    <h2>Interpretación y Definiciones</h2>
    <h3>Interpretación</h3>
    <p>
      Las palabras cuya letra inicial está en mayúscula tienen significados definidos bajo las siguientes condiciones.
      Las siguientes definiciones tendrán el mismo significado independientemente de si aparecen en singular o en
      plural.
    </p>
    <h3>Definiciones</h3>
    <p>Para los propósitos de esta Política de Privacidad:</p>
    <ul>
      <li>
        <strong>Cuenta</strong> significa una cuenta única creada para usted para acceder a nuestro Servicio o partes de
        nuestro Servicio.
      </li>
      <li>
        <strong>Filial</strong> significa una entidad que controla, es controlada por, o está bajo control común con una
        parte, donde "control" significa propiedad de 50% o más de las acciones, interés de capital o otros valores que
        tienen derecho a voto para la elección de directores u otra autoridad de gestión.
      </li>
      <li>
        <strong>Aplicación</strong> se refiere a UniFeed News, el programa de software proporcionado por la Compañía.
      </li>
      <li>
        <strong>Compañía</strong> (referida como "la Compañía", "Nosotros", "Nos" o "Nuestro" en este Acuerdo) se
        refiere a Ropsid Inc.
      </li>
      <li>
        <strong>País</strong> se refiere a: Ontario, Canadá
      </li>
      <li>
        <strong>Dispositivo</strong> significa cualquier dispositivo que pueda acceder al Servicio, como una
        computadora, un teléfono celular o una tableta digital.
      </li>
      <li>
        <strong>Datos Personales</strong> es cualquier información que se relaciona con una persona identificada o
        identificable.
      </li>
      <li>
        <strong>Servicio</strong> se refiere a la Aplicación.
      </li>
      <li>
        <strong>Proveedor de Servicios</strong> significa cualquier persona natural o jurídica que procesa los datos en
        nombre de la Compañía. Se refiere a empresas o individuos de terceros contratados por la Compañía para facilitar
        el Servicio, proporcionar el Servicio en nombre de la Compañía, realizar servicios relacionados con el Servicio
        o ayudar a la Compañía en el análisis de cómo se utiliza el Servicio.
      </li>
      <li>
        <strong>Datos de Uso</strong> se refiere a datos recogidos automáticamente, generados por el uso del Servicio o
        desde la infraestructura del Servicio en sí (por ejemplo, la duración de una visita a la página).
      </li>
      <li>
        <strong>Usted</strong> se refiere a la persona que accede o usa el Servicio, o la empresa, u otra entidad legal
        en nombre de la cual tal persona accede o usa el Servicio, según sea aplicable.
      </li>
    </ul>
    <h2>Recogida y Uso de Sus Datos Personales</h2>
    <h3>Tipos de Datos Recogidos</h3>
    <h4>Datos Personales</h4>
    <p>
      Mientras usa nuestro Servicio, podemos pedirle que nos proporcione cierta información personal identificable que
      puede ser utilizada para contactar o identificarle. La información personal identificable puede incluir, pero no
      se limita a:
    </p>
    <ul>
      <li>Dirección de correo electrónico</li>
      <li>Nombre y apellido</li>
      <li>Número de teléfono</li>
      <li>Dirección, Estado, Provincia, Código Postal, Ciudad</li>
      <li>Datos de Uso</li>
    </ul>
    <h4>Datos de Uso</h4>
    <p>Los Datos de Uso se recogen automáticamente cuando se utiliza el Servicio.</p>
    <p>
      Los Datos de Uso pueden incluir información como la dirección del Protocolo de Internet de su dispositivo (por
      ejemplo, dirección IP), tipo de navegador, versión del navegador, las páginas de nuestro Servicio que visita, la
      hora y fecha de su visita, el tiempo que pasa en esas páginas, identificadores únicos de dispositivos y otros
      datos diagnósticos.
    </p>
    <p>
      Cuando accede al Servicio a través de un dispositivo móvil, esta información puede incluir información como el
      tipo de dispositivo móvil que utiliza, la ID única de su dispositivo móvil, la dirección IP de su dispositivo
      móvil, su sistema operativo móvil, el tipo de navegador de Internet móvil que utiliza, identificadores únicos de
      dispositivos y otros datos diagnósticos.
    </p>
    <h4>Tecnologías de Rastreo y Cookies</h4>
    <p>
      Utilizamos Cookies y tecnologías de rastreo similares para seguir la actividad en nuestro Servicio y almacenar
      cierta información. Las tecnologías de rastreo utilizadas son balizas, etiquetas y scripts para recopilar y seguir
      información y para mejorar y analizar nuestro Servicio.
    </p>
    <p>Las tecnologías que utilizamos pueden incluir:</p>
    <ul>
      <li>
        <strong>Cookies o Cookies del Navegador.</strong> Una cookie es un pequeño archivo colocado en su Dispositivo.
        Puede instruir a su navegador para rechazar todas las Cookies o para indicar cuándo se está enviando una Cookie.
        Sin embargo, si no acepta Cookies, es posible que no pueda utilizar algunas partes de nuestro Servicio.
      </li>
      <li>
        <strong>Balizas Web.</strong> Ciertas secciones de nuestro Servicio y nuestros correos electrónicos pueden
        contener pequeños archivos electrónicos conocidos como balizas web (también denominadas gifs transparentes,
        etiquetas de píxel y gifs de píxel único) que permiten a la Compañía, por ejemplo, contar usuarios que han
        visitado esas páginas o abierto un correo electrónico y para otras estadísticas de sitios web relacionadas (por
        ejemplo, registrar la popularidad de una sección y verificar la integridad del sistema y del servidor).
      </li>
    </ul>
    <p>Utilizamos tanto Cookies de sesión como Cookies persistentes para los propósitos establecidos a continuación:</p>
    <ul>
      <li>
        <strong>Cookies Necesarias / Esenciales</strong>
        Tipo: Cookies de Sesión Administradas por: Nosotros Propósito: Estas Cookies son esenciales para proporcionarle
        los servicios disponibles a través del Sitio Web y para permitirle utilizar algunas de sus funciones. Ayudan a
        autenticar usuarios y prevenir el uso fraudulento de cuentas de usuarios. Sin estas Cookies, los servicios que
        ha solicitado no pueden proporcionarse, y solo usamos estas Cookies para proporcionarle esos servicios.
      </li>
      <li>
        <strong>Cookies de Política / Aviso de Aceptación de Cookies</strong>
        Tipo: Cookies Persistentes Administradas por: Nosotros Propósito: Estas Cookies identifican si los usuarios han
        aceptado el uso de cookies en el Sitio Web.
      </li>
      <li>
        <strong>Cookies de Funcionalidad</strong>
        Tipo: Cookies Persistentes Administradas por: Nosotros Propósito: Estas Cookies nos permiten recordar las
        elecciones que hace cuando utiliza el Sitio Web, como recordar sus detalles de inicio de sesión o preferencia de
        idioma. El propósito de estas Cookies es proporcionarle una experiencia más personal y evitar que tenga que
        volver a ingresar sus preferencias cada vez que utilice el Sitio Web.
      </li>
    </ul>
    <h4>Información Recogida mientras Utiliza la Aplicación</h4>
    <p>
      Mientras utiliza nuestra Aplicación, para proporcionar características de nuestra Aplicación, podemos recoger, con
      su permiso previo:
    </p>
    <ul>
      <li>Información sobre su ubicación</li>
      <li>Información de la agenda telefónica de su Dispositivo (lista de contactos)</li>
      <li>Fotografías y otra información de la cámara y la biblioteca de fotos de su Dispositivo</li>
    </ul>
    <p>
      Utilizamos esta información para proporcionar características de nuestro Servicio, para mejorar y personalizar
      nuestro Servicio. La información puede ser subida a los servidores de la Compañía y/o un servidor del Proveedor de
      Servicios o simplemente almacenada en su Dispositivo.
    </p>
    <p>
      Puede habilitar o deshabilitar el acceso a esta información en cualquier momento, a través de la configuración de
      su Dispositivo.
    </p>
    <h3>Uso de Sus Datos Personales</h3>
    <p>La Compañía puede usar Datos Personales para los siguientes propósitos:</p>
    <ul>
      <li>
        <strong>Para proporcionar y mantener nuestro Servicio</strong>, incluyendo para monitorear el uso de nuestro
        Servicio.
      </li>
      <li>
        <strong>Para gestionar Su Cuenta:</strong> para gestionar su registro como usuario del Servicio. Los Datos
        Personales que proporcione pueden darle acceso a diferentes funcionalidades del Servicio que están disponibles
        para usted como usuario registrado.
      </li>
      <li>
        <strong>Para el desempeño de un contrato:</strong> el desarrollo, cumplimiento y realización del contrato de
        compra de los productos, artículos o servicios que ha comprado o de cualquier otro contrato con nosotros a
        través del Servicio.
      </li>
      <li>
        <strong>Para contactarle:</strong> Para contactarle por correo electrónico, llamadas telefónicas, SMS, u otras
        formas equivalentes de comunicación electrónica, como las notificaciones push de una aplicación móvil con
        respecto a actualizaciones o comunicaciones informativas relacionadas con las funcionalidades, productos o
        servicios contratados, incluidas las actualizaciones de seguridad, cuando sea necesario o razonable para su
        implementación.
      </li>
      <li>
        Para proporcionarle noticias, ofertas especiales e información general sobre otros bienes, servicios y eventos
        que ofrecemos que son similares a los que ya ha comprado o preguntado, a menos que haya optado por no recibir
        dicha información.
      </li>
      <li>
        <strong>Para gestionar sus solicitudes:</strong> Para atender y gestionar sus solicitudes a nosotros.
      </li>
    </ul>
    <h4>Para transferencias de negocios:</h4>
    <p>
      Podemos usar su información para evaluar o llevar a cabo una fusión, desinversión, reestructuración,
      reorganización, disolución u otra venta o transferencia de algunos o todos nuestros activos, ya sea como un
      negocio en marcha o como parte de quiebra, liquidación, o un procedimiento similar, en el que los datos personales
      que tenemos sobre nuestros usuarios del Servicio se encuentran entre los activos transferidos.
    </p>
    <h4>Para otros propósitos:</h4>
    <p>
      Podemos usar su información para otros propósitos, como el análisis de datos, identificación de tendencias de uso,
      determinación de la efectividad de nuestras campañas promocionales y para evaluar y mejorar nuestro Servicio,
      productos, marketing y su experiencia. Podemos compartir su información personal en las siguientes situaciones:
    </p>
    <ul>
      <li>
        <strong>Con Proveedores de Servicios:</strong> Podemos compartir su información personal con Proveedores de
        Servicios para monitorear y analizar el uso de nuestro Servicio, para contactarle.
      </li>
      <li>
        <strong>Para transferencias de negocios:</strong> Podemos compartir o transferir su información personal en
        conexión con, o durante las negociaciones de, cualquier fusión, venta de activos de la empresa, financiación, o
        adquisición de todo o parte de nuestro negocio a otra empresa.
      </li>
      <li>
        <strong>Con Filiales:</strong> Podemos compartir su información con nuestras filiales, en cuyo caso requeriremos
        que esas filiales cumplan esta Política de Privacidad. Las filiales incluyen nuestra empresa matriz y cualquier
        otra subsidiaria, socios de empresa conjunta u otras empresas que controlamos o que están bajo control común con
        nosotros.
      </li>
      <li>
        <strong>Con socios comerciales:</strong> Podemos compartir su información con nuestros socios comerciales para
        ofrecerle ciertos productos, servicios o promociones.
      </li>
      <li>
        <strong>Con otros usuarios:</strong> cuando comparte información personal u interactúa de otra manera en las
        áreas públicas con otros usuarios, dicha información puede ser vista por todos los usuarios y puede ser
        distribuida públicamente fuera.
      </li>
      <li>
        <strong>Con su consentimiento:</strong> Podemos divulgar su información personal para cualquier otro propósito
        con su consentimiento.
      </li>
    </ul>
    <h3>Conservación de Sus Datos Personales</h3>
    <p>
      La Compañía conservará sus Datos Personales solo durante el tiempo que sea necesario para los fines establecidos
      en esta Política de Privacidad. Conservaremos y usaremos sus Datos Personales en la medida en que sea necesario
      para cumplir con nuestras obligaciones legales (por ejemplo, si estamos obligados a conservar sus datos para
      cumplir con las leyes aplicables), resolver disputas y hacer cumplir nuestros acuerdos y políticas legales.
    </p>
    <p>
      La Compañía también conservará los Datos de Uso para fines de análisis internos. Los Datos de Uso se conservan
      generalmente por un período de tiempo más corto, excepto cuando estos datos se utilizan para fortalecer la
      seguridad o mejorar la funcionalidad de nuestro Servicio, o estamos legalmente obligados a retener estos datos por
      períodos de tiempo más largos.
    </p>
    <h3>Transferencia de Sus Datos Personales</h3>
    <p>
      Su información, incluidos los Datos Personales, se procesa en las oficinas operativas de la Compañía y en
      cualquier otro lugar donde las partes involucradas en el procesamiento estén ubicadas. Esto significa que esta
      información puede ser transferida a — y mantenida en — computadoras ubicadas fuera de su estado, provincia, país u
      otra jurisdicción gubernamental donde las leyes de protección de datos pueden diferir de las de su jurisdicción.
    </p>
    <p>
      Su consentimiento a esta Política de Privacidad seguido de su presentación de dicha información representa su
      acuerdo con esa transferencia.
    </p>
    <p>
      La Compañía tomará todas las medidas razonablemente necesarias para garantizar que sus datos sean tratados de
      manera segura y de acuerdo con esta Política de Privacidad y no se realizará transferencia de sus Datos Personales
      a una organización o un país a menos que existan controles adecuados en su lugar, incluida la seguridad de sus
      datos y otra información personal.
    </p>
    <h3>Divulgación de Sus Datos Personales</h3>
    <h4>Transacciones Empresariales</h4>
    <p>
      Si la Compañía está involucrada en una fusión, adquisición o venta de activos, sus Datos Personales pueden ser
      transferidos. Proporcionaremos aviso antes de que sus Datos Personales sean transferidos y estén sujetos a una
      Política de Privacidad diferente.
    </p>
    <h4>Aplicación de la Ley</h4>
    <p>
      Bajo ciertas circunstancias, la Compañía puede estar obligada a divulgar sus Datos Personales si es necesario
      hacerlo por ley o en respuesta a solicitudes válidas por parte de autoridades públicas (por ejemplo, un tribunal o
      una agencia gubernamental).
    </p>
    <h4>Otros Requerimientos Legales</h4>
    <p>La Compañía puede divulgar sus Datos Personales creyendo de buena fe que tal acción es necesaria para:</p>
    <ul>
      <li>Cumplir con una obligación legal</li>
      <li>Proteger y defender los derechos o propiedad de la Compañía</li>
      <li>Prevenir o investigar posibles irregularidades en relación con el Servicio</li>
      <li>Proteger la seguridad personal de los usuarios del Servicio o del público</li>
      <li>Protegerse contra responsabilidad legal</li>
    </ul>
    <h3>Seguridad de Sus Datos Personales</h3>
    <p>
      La seguridad de sus Datos Personales es importante para nosotros, pero recuerde que ningún método de transmisión
      por Internet, o método de almacenamiento electrónico, es 100% seguro. Mientras nos esforzamos por usar medios
      comercialmente aceptables para proteger sus Datos Personales, no podemos garantizar su seguridad absoluta.
    </p>
    <h2>Privacidad de los Niños</h2>
    <p>
      Nuestro Servicio no se dirige a nadie menor de 13 años. No recogemos información personal identificable de nadie
      menor de 13 años de manera consciente. Si usted es un padre o tutor y está consciente de que su hijo nos ha
      proporcionado Datos Personales, por favor contáctenos. Si nos damos cuenta de que hemos recogido Datos Personales
      de alguien menor de 13 años sin verificación del consentimiento de los padres, tomamos pasos para eliminar esa
      información de nuestros servidores.
    </p>
    <p>
      Si necesitamos confiar en el consentimiento como base legal para procesar su información y su país requiere
      consentimiento de un padre, podemos requerir el consentimiento de su padre antes de recoger y usar esa
      información.
    </p>
    <h2>Enlaces a Otros Sitios Web</h2>
    <p>
      Nuestro Servicio puede contener enlaces a otros sitios web que no son operados por nosotros. Si hace clic en un
      enlace de terceros, será dirigido al sitio de ese tercero. Le aconsejamos fuertemente que revise la Política de
      Privacidad de cada sitio que visite.
    </p>
    <p>
      No tenemos control sobre y no asumimos ninguna responsabilidad por el contenido, las políticas de privacidad o las
      prácticas de ningún sitio o servicios de terceros.
    </p>
    <h2>Cambios a esta Política de Privacidad</h2>
    <p>
      Podemos actualizar nuestra Política de Privacidad de vez en cuando. Le notificaremos cualquier cambio publicando
      la nueva Política de Privacidad en esta página.
    </p>
    <p>
      Le informaremos a través del correo electrónico y/o un aviso destacado en nuestro Servicio, antes de que el cambio
      se haga efectivo y actualizar la fecha de "última actualización" en la parte superior de esta Política de
      Privacidad.
    </p>
    <p>
      Se le aconseja revisar esta Política de Privacidad periódicamente para cualquier cambio. Los cambios a esta
      Política de Privacidad son efectivos cuando se publican en esta página.
    </p>
    <h2>Contáctenos</h2>
    <p>Si tiene alguna pregunta sobre esta Política de Privacidad, puede contactarnos:</p>
    <ul>
      <li>Por correo electrónico: contact@unifeed.news</li>
    </ul>
  </div>
));
