import {PageContents} from '../utils';

export const pageContents: PageContents = {
  title: 'UniFeed Новости',
  headline: 'Видите мир без фильтров – Сводки новостей в реальном времени из 13 стран на 16 языках',
  subheading:
    'Изучайте полные и объективные сводки новостей в реальном времени со всего мира, переведенные на ваш язык для лучшего понимания',
  or: 'или',
  launchWebApp: 'Запустить веб-приложение',
};
