import * as React from 'react';
import {atom, useAtomValue, useSetAtom} from 'jotai';
import {Nullable} from '@/types';

type BlockUiValue = {
  actions: Array<Nullable<React.ReactNode>>;
};

const blockUiState = atom<BlockUiValue>({
  actions: [],
});

export const useBlockUiValue = () => useAtomValue(blockUiState);

type BlockUiSetters = {
  block: (action: React.ReactNode) => void;
  unblock: (action: React.ReactNode) => void;
};

export const useBlockUiSetters = (): BlockUiSetters => {
  const setBlockUi = useSetAtom(blockUiState);
  return React.useMemo(
    () => ({
      block: (action) =>
        setBlockUi(({actions}) => ({
          actions: action ? [...actions, action] : actions,
        })),
      unblock: (action) =>
        setBlockUi(({actions}) => ({
          actions: action ? actions.filter((cur) => cur !== action) : actions,
        })),
    }),
    [setBlockUi],
  );
};

export const useBlockUiEffect = (block: boolean, action: React.ReactNode): void => {
  const BlockUiSetters = useBlockUiSetters();
  React.useEffect(() => {
    if (block) {
      BlockUiSetters.block(action);
    } else {
      BlockUiSetters.unblock(action);
    }
    return () => {
      BlockUiSetters.unblock(action);
    };
  }, [block]);
};
