import {PageContents} from '../utils';

export const pageContents: PageContents = {
  title: 'UniFeed الأخبار',
  headline: 'شاهد العالم بدون فلترة – ملخصات أخبار فورية من 13 دولة بـ16 لغات',
  subheading:
    'استكشف ملخصات الأخبار الفورية الشاملة وغير المتحيزة من حول العالم، كلها مترجمة بشكل ملائم إلى لغتك الأم للوضوح والفهم',
  or: 'أو',
  launchWebApp: 'تشغيل التطبيق الويب',
};
