import {PageContents} from '../utils';

export const pageContents: PageContents = {
  title: 'UniFeed Noticias',
  headline: 'Observa el mundo sin filtros – Resúmenes de noticias en tiempo real de 13 países en 16 idiomas',
  subheading:
    'Descubre resúmenes de noticias en tiempo real, completos y objetivos de todo el mundo, traducidos a tu idioma para facilitar su comprensión',
  or: 'o',
  launchWebApp: 'Lanzar aplicación web',
};
