import {PageContents} from '../utils';

export const pageContents: PageContents = {
  title: 'UniFeed Nachrichten',
  headline: 'Sehen Sie die Welt ungefiltert – Echtzeit-Nachrichtendigests aus 13 Ländern in 16 Sprachen',
  subheading:
    'Entdecken Sie umfassende, unparteiische Echtzeit-Nachrichtenzusammenfassungen aus der ganzen Welt, alle klar in Ihrer Sprache übersetzt, um Verständlichkeit zu gewährleisten',
  or: 'ODER',
  launchWebApp: 'Web-App starten',
};
