import * as React from 'react';

export const Ja: React.FC = React.memo(() => (
  <div>
    <h1>プライバシーポリシー</h1>
    <p>最終更新日：2024年7月5日</p>
    <p>
      本プライバシーポリシーは、サービスをご利用いただく際の情報の収集、利用、開示に関する当社のポリシーと手続きを説明し、法律がいかにしてあなたのプライバシーを保護するかについての情報を提供します。
    </p>
    <p>
      当社は、パーソナライズされた広告の表示や有料サブスクリプションの提供を含む、サービスの提供と改善のためにお客様の個人データを使用します。サービスをご利用になることで、お客様は本プライバシーポリシーに従って情報を収集および使用することに同意したとみなされます。
    </p>
    <h2>解釈と定義</h2>
    <h3>解釈</h3>
    <p>
      初めて大文字で表記される単語には、以下の条件で定義された意味があります。これらの定義は、単数形または複数形に関わらず同じ意味を持ちます。
    </p>
    <h3>定義</h3>
    <p>本プライバシーポリシーの目的のために：</p>
    <ul>
      <li>
        <strong>アカウント</strong>{' '}
        は、お客様がサービスまたはサービスの一部にアクセスするために作成されたユニークなアカウントを指します。
      </li>
      <li>
        <strong>アフィリエイト</strong>{' '}
        は、50％以上の株式、持分、または他の選挙権付き証券を所有することにより、ある当事者を制御し、または制御され、または共同で制御される実体を指します。
      </li>
      <li>
        <strong>アプリケーション</strong> は、当社が提供するUniFeed Newsソフトウェアプログラムを指します。
      </li>
      <li>
        <strong>会社</strong>
        （この合意書では「会社」、「私たち」、「私たちの」として言及されることがあります）は、Ropsid Inc.を指します。
      </li>
      <li>
        <strong>国</strong> は、カナダのオンタリオ州を指します。
      </li>
      <li>
        <strong>デバイス</strong>{' '}
        は、コンピュータ、携帯電話、またはデジタルタブレットなど、サービスにアクセスできる任意のデバイスを指します。
      </li>
      <li>
        <strong>個人データ</strong> は、識別されたまたは識別可能な個人に関連するあらゆる情報を指します。
      </li>
      <li>
        <strong>サービス</strong> はアプリケーションを指します。
      </li>
      <li>
        <strong>サービスプロバイダー</strong>{' '}
        は、当社に代わってデータを処理する自然人または法人を指します。これには、サービスを促進するために当社に雇用された第三者の企業や個人、当社に代わってサービスを提供する、サービスに関連するサービスを実行する、またはサービスの使用方法を分析する手助けをする企業や個人が含まれます。
      </li>
      <li>
        <strong>使用データ</strong>{' '}
        は、サービスの使用によって自動的に収集される、またはサービスインフラストラクチャ自体から生成されるデータを指します（例：ページ訪問の持続時間など）。
      </li>
      <li>
        <strong>あなた</strong>{' '}
        は、サービスをアクセスまたは使用する個人、またはそのような個人を代表してサービスをアクセスまたは使用する会社やその他の法的実体を指します。
      </li>
    </ul>
    <h2>あなたの個人データの収集および使用</h2>
    <h3>収集されるデータの種類</h3>
    <h4>個人データ</h4>
    <p>
      当社のサービスを利用する際、お客様に連絡を取るためまたはお客様を特定するために使用できる個人情報の提供を求める場合があります。個人情報には以下が含まれますが、これに限定されません：
    </p>
    <ul>
      <li>メールアドレス</li>
      <li>名前と姓</li>
      <li>電話番号</li>
      <li>住所、州、県、郵便番号、市町村</li>
      <li>使用データ</li>
    </ul>
    <h4>使用データ</h4>
    <p>使用データは、サービスを使用する際に自動的に収集されます。</p>
    <p>
      使用データには、お客様のデバイスのインターネットプロトコルアドレス（例：IPアドレス）、ブラウザの種類、ブラウザのバージョン、お客様が訪問した当社のサービスのページ、訪問の日時、これらのページで費やした時間、ユニークなデバイス識別子、その他の診断データが含まれることがあります。
    </p>
    <p>
      モバイルデバイスを通じてサービスにアクセスする場合、この情報には、使用するモバイルデバイスの種類、モバイルデバイスのユニークID、モバイルデバイスのIPアドレス、モバイルオペレーティングシステム、使用するモバイルインターネットブラウザの種類、ユニークなデバイス識別子、その他の診断データが含まれることがあります。
    </p>
    <h4>トラッキング技術とクッキーの使用</h4>
    <p>
      当社は、サービスの活動を追跡し、特定の情報を保存するために、クッキーおよび類似の追跡技術を使用します。使用される追跡技術には、情報の収集と追跡およびサービスの改善と分析を行うためのビーコン、タグ、およびスクリプトが含まれます。
    </p>
    <p>使用される技術には以下が含まれることがあります：</p>
    <ul>
      <li>
        <strong>クッキーまたは ブラウザクッキー。</strong>{' '}
        クッキーは、お客様のデバイスに配置される小さなファイルです。お客様はブラウザに指示して、すべてのクッキーを拒否するか、クッキーが送信されるときに表示するようにすることができます。ただし、クッキーを受け入れない場合、当社のサービスの一部を利用できない場合があります。
      </li>
      <li>
        <strong>ウェブビーコン。</strong>{' '}
        当社のサービスの特定のセクションや電子メールには、例えば訪問者数をカウントしたり、電子メールを開封したユーザー数をカウントしたりするために、ウェブビーコン（クリアGIF、ピクセルタグ、シングルピクセルGIFとも呼ばれます）と呼ばれる小型の電子ファイルが含まれている場合があります。
      </li>
    </ul>
    <p>以下の目的でセッションクッキーおよび永続クッキーを使用します：</p>
    <ul>
      <li>
        <strong>必要/基本クッキー</strong>
        種類：セッションクッキー 管理者：当社
        目的：これらのクッキーは、ウェブサイトを通じて提供されるサービスを利用するために不可欠であり、その機能の一部を利用するために必要です。これらはユーザー認証を助け、ユーザーアカウントの不正使用を防ぐために使用されます。これらのクッキーがなければ、依頼されたサービスを提供することができません。
      </li>
      <li>
        <strong>クッキーポリシー/通知受け入れクッキー</strong>
        種類：永続クッキー 管理者：当社
        目的：これらのクッキーは、ユーザーがウェブサイト上でクッキーの使用を受け入れたかどうかを識別します。
      </li>
      <li>
        <strong>機能クッキー</strong>
        種類：永続クッキー 管理者：当社
        目的：これらのクッキーは、ウェブサイトの使用時にお客様が行った選択を覚えておくことができます。例えば、ログイン情報や言語の設定を覚えておくことができます。これらのクッキーの目的は、より個人的な体験を提供し、お客様がウェブサイトを使用するたびに設定を再入力する必要がないようにすることです。
      </li>
    </ul>
    <h4>アプリケーションの使用中に収集される情報</h4>
    <p>アプリケーションの機能を提供するために、事前の許可を得て、以下の情報を収集することがあります：</p>
    <ul>
      <li>お客様の位置に関する情報</li>
      <li>お客様のデバイスの電話帳（連絡先リスト）からの情報</li>
      <li>お客様のデバイスのカメラおよびフォトライブラリからの画像やその他の情報</li>
    </ul>
    <p>
      この情報は、サービスの機能を提供し、サービスを改善およびカスタマイズするために使用されます。この情報は、会社のサーバーやサービスプロバイダーのサーバーにアップロードされることもあれば、お客様のデバイスに単に保存されることもあります。
    </p>
    <p>お客様はいつでもデバイスの設定を通じて、この情報へのアクセスを有効または無効にすることができます。</p>
    <h3>お客様の個人データの使用</h3>
    <p>会社は、以下の目的で個人データを使用することがあります：</p>
    <ul>
      <li>
        <strong>サービスの提供および維持：</strong> 当社のサービスの使用を監視するために含まれます。
      </li>
      <li>
        <strong>あなたのアカウントの管理：</strong>{' '}
        サービスのユーザーとしてのあなたの登録を管理します。提供される個人データは、登録ユーザーとして利用可能なサービスの異なる機能へのアクセスを可能にします。
      </li>
      <li>
        <strong>契約の履行のため：</strong>{' '}
        あなたが購入した商品、アイテム、またはサービスの購入契約、またはサービスを通じて当社との間で締結される他の契約の開発、遵守、および実施のためです。
      </li>
      <li>
        <strong>お客様への連絡：</strong>{' '}
        電子メール、電話、SMS、またはモバイルアプリケーションのプッシュ通知などの電子通信形式で、機能、商品、契約サービスに関連する更新情報や情報通信、必要または実施のためのセキュリティ更新情報についてお客様に連絡するためです。
      </li>
      <li>
        あなたがすでに購入または問い合わせたものと類似の他の商品、サービス、イベントについてのニュース、特典、および一般情報を提供するためです（お客様がそのような情報の受信を希望しない場合を除きます）。
      </li>
      <li>
        <strong>お客様のリクエストの管理：</strong> お客様からのリクエストに応じて管理するためです。
      </li>
    </ul>
    <h4>事業移転のため：</h4>
    <p>
      当社は、事業の継続または破産、清算、または類似の手続きの一環として、当社の資産の一部または全部の売却、合併、再編、再構築、解散、またはその他の売却または移転に関連して、またはそれに関する交渉中に、お客様の情報を評価または使用することがあります。この場合、当社のサービスユーザーに関する個人データは、移転される資産の一部です。
    </p>
    <h4>その他の目的のため：</h4>
    <p>
      当社は、データ分析、使用傾向の特定、プロモーションキャンペーンの効果の決定、およびサービス、製品、マーケティング、お客様の体験の評価と改善のために、お客様の情報を使用することがあります。以下の状況でお客様の個人情報を共有することがあります：
    </p>
    <ul>
      <li>
        <strong>サービスプロバイダーとの共有：</strong>{' '}
        当社のサービスの使用を監視および分析するために、お客様の個人情報をサービスプロバイダーと共有することがあります。
      </li>
      <li>
        <strong>事業移転の場合：</strong>{' '}
        当社は、事業の売却、資産の売却、資金調達、または当社の事業の全体または一部の取得に関連して、またはそれに関する交渉中に、お客様の個人情報を共有または移転することがあります。
      </li>
      <li>
        <strong>アフィリエイトとの共有：</strong>{' '}
        当社は、お客様の情報を当社のアフィリエイトと共有することがありますが、その場合はそれらのアフィリエイトに本プライバシーポリシーを遵守することを要求します。アフィリエイトには、当社の親会社およびその他の子会社、合弁事業パートナー、または当社が制御する、または共同で制御される他の会社が含まれます。
      </li>
      <li>
        <strong>ビジネスパートナーとの共有：</strong>{' '}
        当社は、お客様に特定の製品、サービス、またはプロモーションを提供するために、ビジネスパートナーとお客様の情報を共有することがあります。
      </li>
      <li>
        <strong>他のユーザーとの共有：</strong>{' '}
        お客様が公共エリアで個人情報を共有するか、他のユーザーと相互作用する場合、その情報はすべてのユーザーに表示され、外部で公開的に配布されることがあります。
      </li>
      <li>
        <strong>お客様の同意を得て：</strong>{' '}
        お客様の同意を得て、その他の目的でお客様の個人情報を開示することがあります。
      </li>
    </ul>
    <h3>お客様の個人データの保持</h3>
    <p>
      会社は、本プライバシーポリシーに記載されている目的のためにのみ、お客様の個人データを必要な期間保持します。当社は、法的義務を遵守するため（例えば、適用される法律に従ってデータを保持する必要がある場合）、紛争を解決し、および当社の法的契約およびポリシーを執行する範囲で、お客様の個人データを保持および使用します。
    </p>
    <p>
      会社はまた、内部分析目的で使用データを保持します。使用データは通常、より短い期間保持されますが、このデータがセキュリティを強化するため、または当社のサービスの機能を改善するために使用される場合、または法的にこのデータをより長い期間保持する義務がある場合は、例外です。
    </p>
    <h3>お客様の個人データの転送</h3>
    <p>
      お客様の情報、個人データを含む、は当社の運営オフィスおよび処理に関与する当事者が位置する他の場所で処理されます。これは、この情報がお客様の州、県、国、または他の政府管轄区域外のコンピューターに転送されて保持されることを意味します。そこではデータ保護法がお客様の管轄区域のものと異なる場合があります。
    </p>
    <p>本プライバシーポリシーに同意した後にそのような情報を提出することは、その転送に同意したことを表します。</p>
    <p>
      会社は、お客様のデータと他の個人情報のセキュリティを含む適切な管理が整っている場合に限り、お客様の個人データを組織または国に転送するために必要な合理的な手段を講じます。
    </p>
    <h3>お客様の個人データの開示</h3>
    <h4>ビジネス取引</h4>
    <p>
      会社が合併、買収、または資産売却に関与している場合、お客様の個人データは転送される可能性があります。お客様の個人データが転送され、異なるプライバシーポリシーの対象となる前に、通知を提供します。
    </p>
    <h4>法執行</h4>
    <p>
      特定の状況下で、会社は法律によって要求された場合、または公的機関（例えば、裁判所や政府機関）による有効な要求に応じて、お客様の個人データを開示する必要があります。
    </p>
    <h4>その他の法的要件</h4>
    <p>会社は、以下の行動が必要であると誠実に信じる場合に、お客様の個人データを開示することがあります：</p>
    <ul>
      <li>法的義務を遵守するため</li>
      <li>会社の権利または財産を保護および擁護するため</li>
      <li>サービスに関連する可能性のある不正行為を防止または調査するため</li>
      <li>サービスのユーザーまたは公衆の個人の安全を保護するため</li>
      <li>法的責任から保護するため</li>
    </ul>
    <h3>お客様の個人データのセキュリティ</h3>
    <p>
      お客様の個人データのセキュリティは、当社にとって重要ですが、インターネット上の送信方法や電子的な
      保存方法は100％安全ではありません。当社は、お客様の個人データを保護するために商業上許容される手段を使用するよう努めていますが、その絶対的なセキュリティを保証することはできません。
    </p>
    <h2>子供のプライバシー</h2>
    <p>
      当社のサービスは、13歳未満の方を対象としていません。当社は、13歳未満の方から故意に個人情報を収集することはありません。保護者または後見人であり、お子様が当社に個人データを提供したことが判明した場合は、当社にご連絡ください。当社が親の同意を得ずに13歳未満の方から個人データを収集したことを認識した場合、当社はその情報を当社のサーバーから削除する措置を講じます。
    </p>
    <p>
      お客様の情報を処理するために同意が法的根拠であり、お客様の国が親の同意を要求する場合、当社は情報を収集および使用する前に親の同意を求めることがあります。
    </p>
    <h2>他のウェブサイトへのリンク</h2>
    <p>
      当社のサービスには、当社が運営していない他のウェブサイトへのリンクが含まれている場合があります。第三者のリンクをクリックすると、その第三者のサイトに移動します。訪問するすべてのサイトのプライバシーポリシーを確認することを強くお勧めします。
    </p>
    <p>当社は、第三者のサイトやサービスの内容、プライバシーポリシー、または慣行について一切の責任を負いません。</p>
    <h2>本プライバシーポリシーの変更</h2>
    <p>
      当社は、時々本プライバシーポリシーを更新することがあります。変更を行った場合は、このページに新しいプライバシーポリシーを掲載します。
    </p>
    <p>
      変更が有効になる前に、メールや当社のサービス上の顕著なお知らせを通じてお知らせし、本プライバシーポリシーの「最終更新日」を更新します。
    </p>
    <p>
      定期的にこのプライバシーポリシーを確認して、変更があったかどうかを確認することをお勧めします。このプライバシーポリシーの変更は、このページに掲載されたときに効力を発します。
    </p>
    <h2>お問い合わせ</h2>
    <p>本プライバシーポリシーに関するご質問がある場合は、以下の方法でお問い合わせください：</p>
    <ul>
      <li>メールによるお問い合わせ：contact@unifeed.news</li>
    </ul>
  </div>
));
