import * as React from 'react';
import {Helmet} from 'react-helmet-async';
import {Nullable} from '@/types';

export const Seo: React.FC<{
  title: string;
  description?: Nullable<string>;
  keywords?: Nullable<string>;
}> = React.memo(({title, description, keywords}) => (
  <Helmet>
    <title>{title}</title>
    {description && <meta name="description" content={description} />}
    {keywords && <meta name="keywords" content={keywords} />}
    <meta property="og:title" content={title} />
    {description && <meta property="og:description" content={description} />}
  </Helmet>
));
