import {atom, useAtomValue, useSetAtom} from 'jotai';

const pageScrollTopState = atom<number>(0);

export const usePageScrollTopValue = () => useAtomValue(pageScrollTopState);

export const usePageScrollTopEffect = () => {
  const setPageScrollTop = useSetAtom(pageScrollTopState);
  window.addEventListener('scroll', () => setPageScrollTop(window.scrollY));
};
