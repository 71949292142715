import * as React from 'react';

export const It: React.FC = React.memo(() => (
  <div>
    <h1>Politica sulla Privacy</h1>
    <p>Ultimo aggiornamento: 5 luglio 2024</p>
    <p>
      Questa Politica sulla Privacy descrive le nostre politiche e procedure sulla raccolta, l'uso e la divulgazione
      delle tue informazioni quando utilizzi il Servizio e ti informa sui tuoi diritti alla privacy e su come la legge
      ti protegge.
    </p>
    <p>
      Utilizziamo i tuoi dati personali per fornire e migliorare il Servizio, inclusa la visualizzazione di annunci
      personalizzati e l'offerta di abbonamenti a pagamento. Utilizzando il Servizio, accetti la raccolta e l'uso delle
      informazioni in conformità con questa Politica sulla Privacy.
    </p>
    <h2>Interpretazione e Definizioni</h2>
    <h3>Interpretazione</h3>
    <p>
      Le parole di cui la lettera iniziale è maiuscola hanno significati definiti nelle seguenti condizioni. Le seguenti
      definizioni avranno lo stesso significato indipendentemente dal fatto che compaiano al singolare o al plurale.
    </p>
    <h3>Definizioni</h3>
    <p>Per i fini di questa Politica sulla Privacy:</p>
    <ul>
      <li>
        <strong>Account</strong> indica un account unico creato per te per accedere al nostro Servizio o parti del
        nostro Servizio.
      </li>
      <li>
        <strong>Affiliata</strong> indica un'entità che controlla, è controllata o è sotto il controllo comune di una
        parte, dove "controllo" significa possesso del 50% o più delle azioni, interesse azionario o altri titoli idonei
        a votare per l'elezione di amministratori o altre autorità di gestione.
      </li>
      <li>
        <strong>Applicazione</strong> si riferisce a UniFeed News, il programma software fornito dalla Società.
      </li>
      <li>
        <strong>Società</strong> (indicata come "la Società", "Noi", "Il nostro" o "Noi" in questo Accordo) si riferisce
        a Ropsid Inc.
      </li>
      <li>
        <strong>Paese</strong> si riferisce a: Ontario, Canada
      </li>
      <li>
        <strong>Dispositivo</strong> indica qualsiasi dispositivo che può accedere al Servizio come un computer, un
        cellulare o un tablet digitale.
      </li>
      <li>
        <strong>Dati Personali</strong> è qualsiasi informazione che riguarda un individuo identificato o
        identificabile.
      </li>
      <li>
        <strong>Servizio</strong> si riferisce all'Applicazione.
      </li>
      <li>
        <strong>Fornitore di servizi</strong> indica qualsiasi persona fisica o giuridica che tratta i dati per conto
        della Società. Si riferisce a società terze o individui impiegati dalla Società per facilitare il Servizio, per
        fornire il Servizio per conto della Società, per eseguire servizi relativi al Servizio o per assistere la
        Società nell'analisi del modo in cui il Servizio viene utilizzato.
      </li>
      <li>
        <strong>Dati di Utilizzo</strong> si riferisce ai dati raccolti automaticamente, generati dall'uso del Servizio
        o dall'infrastruttura del Servizio stesso (ad esempio, la durata di una visita alla pagina).
      </li>
      <li>
        <strong>Tu</strong> indica la persona fisica che accede o utilizza il Servizio, o l'azienda o altro ente
        giuridico per conto del quale tale individuo accede o utilizza il Servizio, se applicabile.
      </li>
    </ul>
    <h2>Raccolta e Uso dei Tuoi Dati Personali</h2>
    <h3>Tipi di Dati Raccolti</h3>
    <h4>Dati Personali</h4>
    <p>
      Durante l'utilizzo del nostro Servizio, potremmo chiederti di fornirci alcune informazioni personalmente
      identificabili che possono essere utilizzate per contattarti o identificarti. Le informazioni personalmente
      identificabili possono includere, ma non sono limitate a:
    </p>
    <ul>
      <li>Indirizzo email</li>
      <li>Nome e cognome</li>
      <li>Numero di telefono</li>
      <li>Indirizzo, Stato, Provincia, CAP, Città</li>
      <li>Dati di Utilizzo</li>
    </ul>
    <h4>Dati di Utilizzo</h4>
    <p>I Dati di Utilizzo sono raccolti automaticamente quando si utilizza il Servizio.</p>
    <p>
      I Dati di Utilizzo possono includere informazioni come l'indirizzo del Protocollo Internet del tuo dispositivo (ad
      esempio l'indirizzo IP), il tipo di browser, la versione del browser, le pagine del nostro Servizio che visiti,
      l'ora e la data della tua visita, il tempo trascorso su tali pagine, identificatori unici del dispositivo e altri
      dati diagnostici.
    </p>
    <p>
      Quando accedi al Servizio tramite un dispositivo mobile, queste informazioni possono includere informazioni come
      il tipo di dispositivo mobile che utilizzi, l'ID unico del tuo dispositivo mobile, l'indirizzo IP del tuo
      dispositivo mobile, il sistema operativo mobile, il tipo di browser Internet mobile che utilizzi, identificatori
      unici del dispositivo e altri dati diagnostici.
    </p>
    <h4>Tecnologie di Tracciamento e Cookie</h4>
    <p>
      Utilizziamo Cookie e tecnologie di tracciamento simili per monitorare l'attività sul nostro Servizio e conservare
      determinate informazioni. Le tecnologie di tracciamento utilizzate sono beacon, tag e script per raccogliere e
      tracciare informazioni e per migliorare e analizzare il nostro Servizio.
    </p>
    <p>Le tecnologie che utilizziamo possono includere:</p>
    <ul>
      <li>
        <strong>Cookie o Cookie del Browser.</strong> Un cookie è un piccolo file posizionato sul tuo dispositivo. Puoi
        istruire il tuo browser a rifiutare tutti i cookie o a indicare quando viene inviato un cookie. Tuttavia, se non
        accetti i cookie, potresti non essere in grado di utilizzare alcune parti del nostro Servizio.
      </li>
      <li>
        <strong>Web Beacon.</strong> Sezioni del nostro Servizio e le nostre email possono contenere piccoli file
        elettronici noti come web beacon (chiamati anche gif trasparenti, tag pixel e gif a pixel singolo) che
        permettono alla Società, per esempio, di contare gli utenti che hanno visitato quelle pagine o aperto un'email e
        per altre statistiche di siti web correlate (ad esempio, registrare la popolarità di una certa sezione e
        verificare l'integrità del sistema e del server).
      </li>
    </ul>
    <p>Utilizziamo sia Cookie di sessione che Cookie persistenti per i motivi qui sotto:</p>
    <ul>
      <li>
        <strong>Cookie Necessari / Essenziali</strong>
        Tipo: Cookie di Sessione Amministrati da: Noi Scopo: Questi Cookie sono essenziali per fornirti i servizi
        disponibili attraverso il Sito Web e per consentirti di utilizzare alcune delle sue funzioni. Aiutano a
        autenticare gli utenti e prevenire l'uso fraudolento degli account utente. Senza questi Cookie, i servizi che
        hai richiesto non possono essere forniti e utilizziamo solo questi Cookie per fornirti tali servizi.
      </li>
      <li>
        <strong>Cookie di Accettazione della Politica sui Cookie / Avviso</strong>
        Tipo: Cookie Persistenti Amministrati da: Noi Scopo: Questi Cookie identificano se gli utenti hanno accettato
        l'uso dei cookie sul Sito Web.
      </li>
      <li>
        <strong>Cookie di Funzionalità</strong>
        Tipo: Cookie Persistenti Amministrati da: Noi Scopo: Questi Cookie ci permettono di ricordare le scelte che fai
        quando utilizzi il Sito Web, come ad esempio ricordare i tuoi dettagli di login o la tua preferenza linguistica.
        Lo scopo di questi Cookie è fornirti un'esperienza più personale e evitare che tu debba reinserire le tue
        preferenze ogni volta che utilizzi il Sito Web.
      </li>
    </ul>
    <h4>Informazioni Raccolte durante l'Utilizzo dell'Applicazione</h4>
    <p>
      Durante l'utilizzo della nostra Applicazione, al fine di fornire funzionalità della nostra Applicazione, potremmo
      raccogliere, con il tuo previo permesso:
    </p>
    <ul>
      <li>Informazioni riguardanti la tua posizione</li>
      <li>Informazioni dalla rubrica del tuo dispositivo (lista dei contatti)</li>
      <li>Immagini e altre informazioni dalla fotocamera e dalla galleria fotografica del tuo dispositivo</li>
    </ul>
    <p>
      Utilizziamo queste informazioni per fornire le funzionalità del nostro Servizio, per migliorare e personalizzare
      il nostro Servizio. Le informazioni possono essere caricate sui server della Società e/o su un server del
      Fornitore di servizi o possono essere semplicemente conservate sul tuo dispositivo.
    </p>
    <p>
      Puoi abilitare o disabilitare l'accesso a queste informazioni in qualsiasi momento, attraverso le impostazioni del
      tuo dispositivo.
    </p>
    <h3>Uso dei Tuoi Dati Personali</h3>
    <p>La Società può utilizzare i Dati Personali per i seguenti scopi:</p>
    <ul>
      <li>
        <strong>Per fornire e mantenere il nostro Servizio</strong>, incluso per monitorare l'uso del nostro Servizio.
      </li>
      <li>
        <strong>Per gestire il Tuo Account:</strong> per gestire la Tua registrazione come utente del Servizio. I Dati
        Personali che fornisci possono darti accesso a diverse funzionalità del Servizio che sono disponibili per Te
        come utente registrato.
      </li>
      <li>
        <strong>Per l'esecuzione di un contratto:</strong> lo sviluppo, la conformità e l'attuazione del contratto di
        acquisto dei prodotti, degli articoli o dei servizi che hai acquistato o di qualsiasi altro contratto con Noi
        attraverso il Servizio.
      </li>
      <li>
        <strong>Per contattarti:</strong> Per contattarti tramite email, chiamate telefoniche, SMS o altre forme
        equivalenti di comunicazione elettronica, come le notifiche push di un'applicazione mobile riguardo agli
        aggiornamenti o comunicazioni informative relative alle funzionalità, ai prodotti o ai servizi contrattuali,
        inclusi gli aggiornamenti di sicurezza, quando necessario o ragionevole per la loro attuazione.
      </li>
      <li>
        Per fornirti notizie, offerte speciali e informazioni generali su altri beni, servizi ed eventi che offriamo
        simili a quelli che hai già acquistato o di cui hai fatto richiesta a meno che non hai scelto di non ricevere
        tali informazioni.
      </li>
      <li>
        <strong>Per gestire le tue richieste:</strong> Per partecipare e gestire le tue richieste a noi.
      </li>
    </ul>
    <h4>Per trasferimenti aziendali:</h4>
    <p>
      Potremmo utilizzare le tue informazioni per valutare o effettuare una fusione, cessione, ristrutturazione,
      riorganizzazione, scioglimento o altro trasferimento di alcuni o tutti i nostri beni, sia come un'entità in corso
      che come parte di un fallimento, liquidazione o procedura simile, in cui i Dati Personali detenuti da noi sui
      nostri utenti del servizio sono tra gli asset trasferiti.
    </p>
    <h4>Per altri scopi:</h4>
    <p>
      Potremmo utilizzare le tue informazioni per altri scopi, come l'analisi dei dati, l'identificazione delle tendenze
      di utilizzo, la determinazione dell'efficacia delle nostre campagne promozionali e per valutare e migliorare il
      nostro Servizio, prodotti, marketing e la tua esperienza. Potremmo condividere le tue informazioni personali nelle
      seguenti situazioni:
    </p>
    <ul>
      <li>
        <strong>Con i Fornitori di servizi:</strong> Potremmo condividere le tue informazioni personali con i Fornitori
        di servizi per monitorare e analizzare l'uso del nostro Servizio, per contattarti.
      </li>
      <li>
        <strong>Per trasferimenti aziendali:</strong> Potremmo condividere o trasferire le tue informazioni personali in
        connessione con, o durante le negoziazioni di, qualsiasi fusione, vendita di beni della Società, finanziamento o
        acquisizione di tutto o parte del nostro business ad un'altra società.
      </li>
      <li>
        <strong>Con le Affiliate:</strong> Potremmo condividere le tue informazioni con le nostre affiliate, in tal caso
        richiederemo alle nostre affiliate di rispettare questa Politica sulla Privacy. Le affiliate includono la nostra
        società madre e qualsiasi altra sussidiaria, partner in joint venture o altre società che controlliamo o che
        sono sotto controllo comune con noi.
      </li>
      <li>
        <strong>Con i partner commerciali:</strong> Potremmo condividere le tue informazioni con i nostri partner
        commerciali per offrirti determinati prodotti, servizi o promozioni.
      </li>
      <li>
        <strong>Con altri utenti:</strong> quando condividi informazioni personali o altrimenti interagisci nelle aree
        pubbliche con altri utenti, tali informazioni possono essere visualizzate da tutti gli utenti e possono essere
        distribuite pubblicamente all'esterno.
      </li>
      <li>
        <strong>Con il tuo consenso:</strong> Potremmo divulgare le tue informazioni personali per qualsiasi altro scopo
        con il tuo consenso.
      </li>
    </ul>
    <h3>Conservazione dei Tuoi Dati Personali</h3>
    <p>
      La Società conserverà i Tuoi Dati Personali solo per il tempo necessario agli scopi stabiliti in questa Politica
      sulla Privacy. Conserveremo e utilizzeremo i Tuoi Dati Personali nella misura necessaria per adempiere ai nostri
      obblighi legali (ad esempio, se siamo tenuti a conservare i tuoi dati per rispettare le leggi applicabili),
      risolvere le controversie e far rispettare i nostri accordi e politiche legali.
    </p>
    <p>
      La Società conserverà anche i Dati di Utilizzo per scopi di analisi interna. I Dati di Utilizzo sono generalmente
      conservati per un periodo di tempo più breve, eccetto quando questi dati sono utilizzati per rafforzare la
      sicurezza o per migliorare la funzionalità del nostro Servizio, o siamo legalmente obbligati a conservare questi
      dati per periodi di tempo più lunghi.
    </p>
    <h3>Trasferimento dei Tuoi Dati Personali</h3>
    <p>
      Le tue informazioni, inclusi i Dati Personali, vengono trattate negli uffici operativi della Società e in
      qualsiasi altro luogo in cui si trovano le parti coinvolte nel trattamento. Ciò significa che queste informazioni
      possono essere trasferite a — e mantenute su — computer situati fuori dal tuo stato, provincia, paese o altra
      giurisdizione governativa dove le leggi sulla protezione dei dati possono differire da quelle della tua
      giurisdizione.
    </p>
    <p>
      Il tuo consenso a questa Politica sulla Privacy seguito dalla tua presentazione di tali informazioni rappresenta
      il tuo accordo a tale trasferimento.
    </p>
    <p>
      La Società adotterà tutte le misure ragionevolmente necessarie per garantire che i tuoi dati siano trattati in
      modo sicuro e in conformità con questa Politica sulla Privacy e nessun trasferimento dei Tuoi Dati Personali avrà
      luogo a un'organizzazione o a un paese a meno che non ci siano controlli adeguati in atto, inclusa la sicurezza
      dei Tuoi dati e altre informazioni personali.
    </p>
    <h3>Divulgazione dei Tuoi Dati Personali</h3>
    <h4>Transazioni commerciali</h4>
    <p>
      Se la Società è coin volta in una fusione, acquisizione o vendita di attività, i Tuoi Dati Personali possono
      essere trasferiti. Ti forniremo un avviso prima che i Tuoi Dati Personali vengano trasferiti e diventino oggetto
      di una diversa Politica sulla Privacy.
    </p>
    <h4>Applicazione della legge</h4>
    <p>
      In determinate circostanze, la Società può essere tenuta a divulgare i Tuoi Dati Personali se richiesto per farlo
      dalla legge o in risposta a richieste valide da parte delle autorità pubbliche (ad esempio, un tribunale o
      un'agenzia governativa).
    </p>
    <h4>Altri requisiti legali</h4>
    <p>
      La Società può divulgare i Tuoi Dati Personali nella convinzione in buona fede che tale azione sia necessaria per:
    </p>
    <ul>
      <li>Compiere un obbligo legale</li>
      <li>Proteggere e difendere i diritti o la proprietà della Società</li>
      <li>Prevenire o investigare possibili illeciti in relazione al Servizio</li>
      <li>Proteggere la sicurezza personale degli utenti del Servizio o del pubblico</li>
      <li>Proteggere contro la responsabilità legale</li>
    </ul>
    <h3>Sicurezza dei Tuoi Dati Personali</h3>
    <p>
      La sicurezza dei Tuoi Dati Personali è importante per noi, ma ricorda che nessun metodo di trasmissione su
      Internet o metodo di conservazione elettronica è sicuro al 100%. Anche se ci impegniamo ad utilizzare mezzi
      commercialmente accettabili per proteggere i Tuoi Dati Personali, non possiamo garantire la loro sicurezza
      assoluta.
    </p>
    <h2>Privacy dei minori</h2>
    <p>
      Il nostro Servizio non si rivolge a nessuno di età inferiore a 13 anni. Non raccogliamo intenzionalmente
      informazioni personalmente identificabili da nessuno di età inferiore a 13 anni. Se sei un genitore o tutore e sei
      a conoscenza del fatto che tuo figlio ci ha fornito Dati Personali, ti preghiamo di contattarci. Se veniamo a
      conoscenza del fatto che abbiamo raccolto Dati Personali da minori di 13 anni senza verifica del consenso dei
      genitori, adottiamo misure per rimuovere tali informazioni dai nostri server.
    </p>
    <p>
      Se dobbiamo fare affidamento sul consenso come base legale per il trattamento delle Tue informazioni e il tuo
      paese richiede il consenso dei genitori, potremmo richiedere il consenso dei tuoi genitori prima di raccogliere e
      utilizzare tali informazioni.
    </p>
    <h2>Link ad altri siti web</h2>
    <p>
      Il nostro Servizio può contenere collegamenti ad altri siti web che non sono gestiti da noi. Se fai clic su un
      collegamento di terzi, verrai indirizzato al sito di quel terzo. Ti consigliamo vivamente di rivedere la Politica
      sulla Privacy di ogni sito che visiti.
    </p>
    <p>
      Non abbiamo alcun controllo e non assumiamo alcuna responsabilità per il contenuto, le politiche sulla privacy o
      le pratiche di qualsiasi sito o servizio di terzi.
    </p>
    <h2>Modifiche a questa Politica sulla Privacy</h2>
    <p>
      Potremmo aggiornare la nostra Politica sulla Privacy di tanto in tanto. Ti avviseremo di eventuali modifiche
      pubblicando la nuova Politica sulla Privacy su questa pagina.
    </p>
    <p>
      Ti informeremo tramite e-mail e/o un avviso ben visibile sul nostro Servizio, prima che la modifica diventi
      efficace e aggiorneremo la data di "ultimo aggiornamento" in cima a questa Politica sulla Privacy.
    </p>
    <p>
      Ti consigliamo di rivedere periodicamente questa Politica sulla Privacy per eventuali modifiche. Le modifiche a
      questa Politica sulla Privacy diventano effettive quando sono pubblicate su questa pagina.
    </p>
    <h2>Contattaci</h2>
    <p>Se hai domande su questa Politica sulla Privacy, puoi contattarci:</p>
    <ul>
      <li>Tramite email: contact@unifeed.news</li>
    </ul>
  </div>
));
