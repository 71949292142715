import {PageContents} from '../utils';

export const pageContents: PageContents = {
  title: 'UniFeed Actualités',
  headline: "Découvrez le monde sans filtre – Digests d'actualités en temps réel de 13 pays en 16 langues",
  subheading:
    "Explorez des digests d'actualités en temps réel, détaillés et neutres du monde entier, tous traduits dans votre langue pour une meilleure compréhension",
  or: 'OU',
  launchWebApp: "Lancer l'application Web",
};
