import {atom, useAtomValue} from 'jotai';
import {match} from 'ts-pattern';
import * as states from '@/states';
import {Locale} from '@/types';
import * as locales from './locales';
import {PageContents} from './utils';

const pageContentsState = atom<PageContents>((get) => {
  const locale = get(states.global.localeState);
  return match(locale)
    .with(Locale.En, () => locales.en.pageContents)
    .with(Locale.Zh, () => locales.zh.pageContents)
    .with(Locale.De, () => locales.de.pageContents)
    .with(Locale.Fr, () => locales.fr.pageContents)
    .with(Locale.Ja, () => locales.ja.pageContents)
    .with(Locale.Ko, () => locales.ko.pageContents)
    .with(Locale.Ru, () => locales.ru.pageContents)
    .with(Locale.Es, () => locales.es.pageContents)
    .with(Locale.Pt, () => locales.pt.pageContents)
    .with(Locale.It, () => locales.it.pageContents)
    .with(Locale.Hi, () => locales.hi.pageContents)
    .with(Locale.Nl, () => locales.nl.pageContents)
    .with(Locale.Pl, () => locales.pl.pageContents)
    .with(Locale.Tr, () => locales.tr.pageContents)
    .with(Locale.Ar, () => locales.ar.pageContents)
    .with(Locale.Id, () => locales.id.pageContents)
    .exhaustive();
});

export const usePageContentsValue = () => useAtomValue(pageContentsState);
