import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {uniq} from 'lodash-es';
import * as states from '@/states';
import {Spinner} from './Spinner';

const root = document.createElement('div');
root.id = 'rtb';
const mount = () => {
  if (!document.querySelector(`#${root.id}`)) {
    document.body.appendChild(root);
  }
};
const unmount = () => document.querySelector(`#${root.id}`)?.remove();

export const BlockUi: React.FC = React.memo(() => {
  const {actions} = states.global.useBlockUiValue();

  React.useEffect(() => {
    return () => {
      unmount();
    };
  }, []);

  React.useEffect(() => {
    if (actions.length > 0) {
      mount();
    } else {
      unmount();
    }
  }, [actions]);

  return ReactDOM.createPortal(
    <>
      <div className="fixed inset-0" style={{backgroundColor: 'rgba(255, 255, 255, 0.7)'}} />
      <Spinner />
      <div className="rtb-actions">
        {React.useMemo(() => uniq(actions).map((action, idx) => <div key={idx}>{action}</div>), [actions])}
      </div>
    </>,
    root,
  );
});
