import {PageContents} from '../utils';

export const pageContents: PageContents = {
  title: 'UniFeed Notícias',
  headline: 'Veja o mundo sem filtros – Digests de notícias em tempo real de 13 países em 16 línguas',
  subheading:
    'Explore resumos de notícias em tempo real, abrangentes e imparciais de todo o mundo, todos traduzidos para o seu idioma nativo para facilitar a compreensão',
  or: 'ou',
  launchWebApp: 'Lançar aplicativo web',
};
