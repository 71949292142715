import * as React from 'react';
import clsx from 'clsx';
import {Link} from 'react-router-dom';
import * as states from '@/states';
import {LocalesDropdown} from './LocalesDropdown';

export const Header: React.FC = React.memo(() => {
  const hasScrolled = states.global.usePageScrollTopValue() > 0;
  const pageContents = states.bloc.usePageContentsValue();
  return (
    <nav
      className={clsx('fixed inset-x-0 top-0 z-[100] border-gray-200', {
        'bg-transparent': !hasScrolled,
        'bg-gray-900 shadow-xl': hasScrolled,
      })}
    >
      <div className="mx-auto flex max-w-screen-xl flex-wrap items-center justify-between p-4">
        <Link to="/" className="flex items-center space-x-3 rtl:space-x-reverse">
          <img src="/images/icon-512.png" className="h-8" alt="Logo" />
          <span className="self-center whitespace-nowrap text-2xl font-semibold text-white">{pageContents.title}</span>
        </Link>
        <LocalesDropdown />
      </div>
    </nav>
  );
});
