import {PageContents} from '../utils';

export const pageContents: PageContents = {
  title: 'UniFeed Berita',
  headline: 'Lihat Dunia Tanpa Filter – Ringkasan Berita Waktu Nyata dari 13 Negara dalam 16 Bahasa',
  subheading:
    'Jelajahi ringkasan berita waktu nyata yang komprehensif dan tidak bias dari seluruh dunia, semua diterjemahkan dengan nyaman ke dalam bahasa asli Anda untuk kejelasan dan pemahaman',
  or: 'ATAU',
  launchWebApp: 'Luncurkan Aplikasi Web',
};
