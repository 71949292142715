import * as React from 'react';

export const Fr: React.FC = React.memo(() => (
  <div>
    <h1>Politique de Confidentialité</h1>
    <p>Dernière mise à jour : 5 juillet 2024</p>
    <p>
      Cette Politique de Confidentialité décrit nos politiques et procédures concernant la collecte, l'utilisation et la
      divulgation de vos informations lorsque vous utilisez le Service et vous informe sur vos droits en matière de vie
      privée et comment la loi vous protège.
    </p>
    <p>
      Nous utilisons vos données personnelles pour fournir et améliorer le Service, y compris afficher des publicités
      personnalisées et proposer des abonnements payants. En utilisant le Service, vous acceptez la collecte et
      l'utilisation des informations conformément à cette Politique de Confidentialité.
    </p>
    <h2>Interprétation et Définitions</h2>
    <h3>Interprétation</h3>
    <p>
      Les mots dont la lettre initiale est en majuscule ont des significations définies dans les conditions suivantes.
      Les définitions suivantes ont la même signification qu'elles apparaissent au singulier ou au pluriel.
    </p>
    <h3>Définitions</h3>
    <p>Pour les besoins de cette Politique de Confidentialité :</p>
    <ul>
      <li>
        <strong>Compte</strong> désigne un compte unique créé pour vous permettre d'accéder à notre Service ou à des
        parties de notre Service.
      </li>
      <li>
        <strong>Filiale</strong> désigne une entité qui contrôle, est contrôlée par, ou est sous contrôle commun avec
        une partie, où "contrôle" signifie la possession de 50 % ou plus des actions, intérêts en capital ou autres
        valeurs mobilières ayant droit de vote pour l'élection de directeurs ou d'une autre autorité de gestion.
      </li>
      <li>
        <strong>Application</strong> se réfère à UniFeed News, le programme logiciel fourni par la Société.
      </li>
      <li>
        <strong>Société</strong> (désignée soit par "la Société", "Nous", "Notre" ou "Nos" dans cet Accord) se réfère à
        Ropsid Inc.
      </li>
      <li>
        <strong>Pays</strong> se réfère à : Ontario, Canada
      </li>
      <li>
        <strong>Dispositif</strong> désigne tout dispositif pouvant accéder au Service tel qu'un ordinateur, un
        téléphone portable ou une tablette numérique.
      </li>
      <li>
        <strong>Données Personnelles</strong> est toute information qui se rapporte à une personne identifiée ou
        identifiable.
      </li>
      <li>
        <strong>Service</strong> se réfère à l'Application.
      </li>
      <li>
        <strong>Fournisseur de services</strong> désigne toute personne physique ou morale qui traite les données pour
        le compte de la Société. Cela se réfère aux entreprises tierces ou aux individus engagés par la Société pour
        faciliter le Service, fournir le Service au nom de la Société, effectuer des services liés au Service, ou aider
        la Société à analyser comment le Service est utilisé.
      </li>
      <li>
        <strong>Données d'Utilisation</strong> désigne les données collectées automatiquement, générées par
        l'utilisation du Service ou provenant de l'infrastructure du Service elle-même (par exemple, la durée d'une
        visite de page).
      </li>
      <li>
        <strong>Vous</strong> désigne la personne accédant ou utilisant le Service, ou la société, ou autre entité
        légale pour le compte de laquelle une telle personne accède ou utilise le Service, selon le cas.
      </li>
    </ul>
    <h2>Collecte et Utilisation de Vos Données Personnelles</h2>
    <h3>Types de Données Collectées</h3>
    <h4>Données Personnelles</h4>
    <p>
      Lors de l'utilisation de notre Service, nous pouvons vous demander de nous fournir certaines informations
      personnelles identifiables qui peuvent être utilisées pour vous contacter ou vous identifier. Les informations
      personnelles identifiables peuvent inclure, mais sans s'y limiter :
    </p>
    <ul>
      <li>Adresse e-mail</li>
      <li>Prénom et nom</li>
      <li>Numéro de téléphone</li>
      <li>Adresse, État, Province, code postal, Ville</li>
      <li>Données d'Utilisation</li>
    </ul>
    <h4>Données d'Utilisation</h4>
    <p>Les Données d'Utilisation sont collectées automatiquement lors de l'utilisation du Service.</p>
    <p>
      Les Données d'Utilisation peuvent inclure des informations telles que l'adresse de protocole Internet de votre
      dispositif (par exemple, adresse IP), le type de navigateur, la version du navigateur, les pages de notre Service
      que vous visitez, l'heure et la date de votre visite, le temps passé sur ces pages, des identifiants de dispositif
      uniques et d'autres données diagnostiques.
    </p>
    <p>
      Lorsque vous accédez au Service par ou via un dispositif mobile, ces informations peuvent inclure des informations
      telles que le type de dispositif mobile que vous utilisez, l'ID unique de votre dispositif mobile, l'adresse IP de
      votre dispositif mobile, votre système d'exploitation mobile, le type de navigateur Internet mobile que vous
      utilisez, des identifiants de dispositif uniques et d'autres données diagnostiques.
    </p>
    <h4>Technologies de Suivi et Cookies</h4>
    <p>
      Nous utilisons des Cookies et des technologies de suivi similaires pour suivre l'activité sur notre Service et
      stocker certaines informations. Les technologies de suivi utilisées sont des balises, des tags et des scripts pour
      collecter et suivre les informations et pour améliorer et analyser notre Service.
    </p>
    <p>Les technologies que nous utilisons peuvent inclure :</p>
    <ul>
      <li>
        <strong>Cookies ou Cookies de Navigateur.</strong> Un cookie est un petit fichier placé sur votre Dispositif.
        Vous pouvez instruire votre navigateur de refuser tous les Cookies ou d'indiquer quand un Cookie est envoyé.
        Cependant, si vous n'acceptez pas les Cookies, vous ne pourrez peut-être pas utiliser certaines parties de notre
        Service.
      </li>
      <li>
        <strong>Balises Web.</strong> Certaines sections de notre Service et nos e-mails peuvent contenir des fichiers
        électroniques petits connus sous le nom de balises web (également désignées sous le nom de gifs transparents,
        tags de pixel, et gifs à pixel unique) qui permettent à la Société, par exemple, de compter les utilisateurs qui
        ont visité ces pages ou ouvert un courriel et pour d'autres statistiques de site web connexes (par exemple,
        enregistrer la popularité d'une certaine section et vérifier l'intégrité du système et du serveur).
      </li>
    </ul>
    <p>
      Nous utilisons à la fois des Cookies de session et des Cookies persistants pour les objectifs énoncés ci-dessous :
    </p>
    <ul>
      <li>
        <strong>Cookies Nécessaires / Essentiels</strong>
        Type : Cookies de Session Administrés par : Nous But : Ces Cookies sont essentiels pour vous fournir les
        services disponibles via le Site Web et pour vous permettre d'utiliser certaines de ses fonctionnalités. Ils
        aident à authentifier les utilisateurs et à prévenir l'utilisation frauduleuse des comptes utilisateurs. Sans
        ces Cookies, les services que vous avez demandés ne peuvent pas être fournis, et nous utilisons ces Cookies
        uniquement pour vous fournir ces services.
      </li>
      <li>
        <strong>Cookies de Politique / Avis d'Acceptation des Cookies</strong>
        Type : Cookies Persistants Administrés par : Nous But : Ces Cookies identifient si les utilisateurs ont accepté
        l'utilisation des cookies sur le Site Web.
      </li>
      <li>
        <strong>Cookies de Fonctionnalité</strong>
        Type : Cookies Persistants Administrés par : Nous But : Ces Cookies nous permettent de nous souvenir des choix
        que vous faites lorsque vous utilisez le Site Web, comme se souvenir de vos détails de connexion ou de votre
        préférence linguistique. Le but de ces Cookies est de vous fournir une expérience plus personnelle et d'éviter
        que vous ayez à saisir vos préférences chaque fois que vous utilisez le Site Web.
      </li>
    </ul>
    <h4>Informations Collectées lors de l'Utilisation de l'Application</h4>
    <p>
      Lors de l'utilisation de notre Application, afin de fournir des fonctionnalités de notre Application, nous pouvons
      collecter, avec votre autorisation préalable :
    </p>
    <ul>
      <li>Informations concernant votre localisation</li>
      <li>Informations provenant du carnet d'adresses de votre Dispositif (liste de contacts)</li>
      <li>Photos et autres informations provenant de la caméra et de la bibliothèque de photos de votre Dispositif</li>
    </ul>
    <p>
      Nous utilisons ces informations pour fournir des fonctionnalités de notre Service, pour améliorer et personnaliser
      notre Service. Les informations peuvent être téléchargées sur les serveurs de la Société et/ou un serveur de
      Fournisseur de services ou elles peuvent simplement être stockées sur votre dispositif.
    </p>
    <p>
      Vous pouvez activer ou désactiver l'accès à ces informations à tout moment, via les paramètres de votre
      Dispositif.
    </p>
    <h3>Utilisation de Vos Données Personnelles</h3>
    <p>La Société peut utiliser les Données Personnelles aux fins suivantes :</p>
    <ul>
      <li>
        <strong>Pour fournir et maintenir notre Service</strong>, y compris pour surveiller l'utilisation de notre
        Service.
      </li>
      <li>
        <strong>Pour gérer Votre Compte :</strong> pour gérer Votre inscription en tant qu'utilisateur du Service. Les
        Données Personnelles que vous fournissez peuvent vous donner accès à différentes fonctionnalités du Service qui
        sont disponibles pour vous en tant qu'utilisateur enregistré.
      </li>
      <li>
        <strong>Pour l'exécution d'un contrat :</strong> le développement, la conformité et l'entreprise du contrat
        d'achat pour les produits, articles ou services que vous avez achetés ou de tout autre contrat avec nous via le
        Service.
      </li>
      <li>
        <strong>Pour vous contacter :</strong> Pour vous contacter par courriel, appels téléphoniques, SMS ou autres
        formes équivalentes de communication électronique, telles que les notifications push d'une application mobile
        concernant des mises à jour ou des communications informatives liées aux fonctionnalités, produits ou services
        contractés, y compris les mises à jour de sécurité, lorsque cela est nécessaire ou raisonnable pour leur mise en
        œuvre.
      </li>
      <li>
        Pour vous fournir des nouvelles, des offres spéciales et des informations générales sur d'autres biens, services
        et événements que nous proposons qui sont similaires à ceux que vous avez déjà achetés ou demandés, sauf si vous
        avez choisi de ne pas recevoir de telles informations.
      </li>
      <li>
        <strong>Pour gérer vos demandes :</strong> Pour assister et gérer vos demandes à nous.
      </li>
    </ul>
    <h4>Pour les transferts d'entreprise :</h4>
    <p>
      Nous pouvons utiliser vos informations pour évaluer ou réaliser une fusion, une cession, une restructuration, une
      réorganisation, une dissolution ou une autre vente ou transfert de tout ou partie de nos actifs, que ce soit dans
      le cadre d'une activité en cours ou dans le cadre d'une faillite, liquidation ou procédure similaire, dans
      laquelle les données personnelles détenues par nous sur nos utilisateurs de Service font partie des actifs
      transférés.
    </p>
    <h4>Pour d'autres objectifs :</h4>
    <p>
      Nous pouvons utiliser vos informations pour d'autres objectifs, tels que l'analyse de données, l'identification
      des tendances d'utilisation, la détermination de l'efficacité de nos campagnes promotionnelles et pour évaluer et
      améliorer notre Service, produits, marketing et votre expérience. Nous pouvons partager vos informations
      personnelles dans les situations suivantes :
    </p>
    <ul>
      <li>
        <strong>Avec des Fournisseurs de services :</strong> Nous pouvons partager vos informations personnelles avec
        des Fournisseurs de services pour surveiller et analyser l'utilisation de notre Service, pour vous contacter.
      </li>
      <li>
        <strong>Pour les transferts d'entreprise :</strong> Nous pouvons partager ou transférer vos informations
        personnelles en connexion avec, ou pendant les négociations de, toute fusion, vente d'actifs de la société,
        financement, ou acquisition de tout ou partie de notre entreprise par une autre société.
      </li>
      <li>
        <strong>Avec des Filiales :</strong> Nous pouvons partager vos informations avec nos filiales, dans ce cas, nous
        exigerons que ces filiales respectent cette Politique de Confidentialité. Les filiales incluent notre société
        mère et toute autre filiale, partenaires de coentreprise ou autres entreprises que nous contrôlons ou qui sont
        sous contrôle commun avec nous.
      </li>
      <li>
        <strong>Avec des partenaires commerciaux :</strong> Nous pouvons partager vos informations avec nos partenaires
        commerciaux pour vous proposer certains produits, services ou promotions.
      </li>
      <li>
        <strong>Avec d'autres utilisateurs :</strong> lorsque vous partagez des informations personnelles ou
        interagissez autrement dans les zones publiques avec d'autres utilisateurs, ces informations peuvent être vues
        par tous les utilisateurs et peuvent être distribuées publiquement à l'extérieur.
      </li>
      <li>
        <strong>Avec votre consentement :</strong> Nous pouvons divulguer vos informations personnelles pour tout autre
        objectif avec votre consentement.
      </li>
    </ul>
    <h3>Conservation de Vos Données Personnelles</h3>
    <p>
      La Société conservera vos Données Personnelles uniquement aussi longtemps que nécessaire aux fins établies dans
      cette Politique de Confidentialité. Nous conserverons et utiliserons vos Données Personnelles dans la mesure
      nécessaire pour se conformer à nos obligations légales (par exemple, si nous sommes tenus de conserver vos données
      pour respecter les lois applicables), résoudre des litiges et faire appliquer nos accords et politiques légales.
    </p>
    <p>
      La Société conservera également les Données d'Utilisation à des fins d'analyse interne. Les Données d'Utilisation
      sont généralement conservées pour une période plus courte, sauf lorsque ces données sont utilisées pour renforcer
      la sécurité ou pour améliorer la fonctionnalité de notre Service, ou nous sommes légalement obligés de conserver
      ces données pendant des périodes de temps plus longues.
    </p>
    <h3>Transfert de Vos Données Personnelles</h3>
    <p>
      Vos informations, y compris les Données Personnelles, sont traitées dans les bureaux d'exploitation de la Société
      et dans tout autre lieu où les parties impliquées dans le traitement sont situées. Cela signifie que ces
      informations peuvent être transférées à — et maintenues sur — des ordinateurs situés en dehors de votre état,
      province, pays ou autre juridiction gouvernementale où les lois sur la protection des données peuvent différer de
      celles de votre juridiction.
    </p>
    <p>
      Votre consentement à cette Politique de Confidentialité suivi de votre soumission de telles informations
      représente votre accord à ce transfert.
    </p>
    <p>
      La Société prendra toutes les mesures raisonnablement nécessaires pour garantir que vos données sont traitées de
      manière sécurisée et conformément à cette Politique de Confidentialité et aucun transfert de vos Données
      Personnelles n'aura lieu vers une organisation ou un pays à moins que des contrôles adéquats ne soient en place y
      compris la sécurité de vos données et autres informations personnelles.
    </p>
    <h3>Divulgation de Vos Données Personnelles</h3>
    <h4>Transactions Commerciales</h4>
    <p>
      Si la Société est impliquée dans une fusion, acquisition ou vente d'actifs, vos Données Personnelles peuvent être
      transférées. Nous vous fournirons un préavis avant que vos Données Personnelles ne soient transférées et soumises
      à une différente Politique de Confidentialité.
    </p>
    <h4>Application de la Loi</h4>
    <p>
      Dans certaines circonstances, la Société peut être tenue de divulguer vos Données Personnelles si requis de le
      faire par la loi ou en réponse à des demandes valides par des autorités publiques (par exemple, un tribunal ou une
      agence gouvernementale).
    </p>
    <h4>Autres Exigences Légales</h4>
    <p>
      La Société peut divulguer vos Données Personnelles dans la croyance de bonne foi que cette action est nécessaire
      pour :
    </p>
    <ul>
      <li>Se conformer à une obligation légale</li>
      <li>Protéger et défendre les droits ou la propriété de la Société</li>
      <li>Prévenir ou enquêter sur un éventuel acte répréhensible en lien avec le Service</li>
      <li>Protéger la sécurité personnelle des utilisateurs du Service ou du public</li>
      <li>Se prémunir contre la responsabilité légale</li>
    </ul>
    <h3>Sécurité de Vos Données Personnelles</h3>
    <p>
      La sécurité de vos Données Personnelles est importante pour nous, mais rappelez-vous qu'aucune méthode de
      transmission sur Internet, ou méthode de stockage électronique, n'est sûre à 100 %. Alors que nous nous efforçons
      d'utiliser des moyens commercialement acceptables pour protéger vos Données Personnelles, nous ne pouvons garantir
      sa sécurité absolue.
    </p>
    <h2>Confidentialité des Enfants</h2>
    <p>
      Notre Service ne s'adresse pas à quiconque ayant moins de 13 ans. Nous ne collectons pas sciemment des
      informations personnelles identifiables de personnes âgées de moins de 13 ans. Si vous êtes un parent ou un tuteur
      et que vous êtes conscient que votre enfant nous a fourni des Données Personnelles, veuillez nous contacter. Si
      nous prenons conscience que nous avons collecté des Données Personnelles d'une personne de moins de 13 ans sans
      vérification du consentement parental, nous prenons des mesures pour supprimer ces informations de nos serveurs.
    </p>
    <p>
      Si nous devons nous appuyer sur le consentement comme base légale pour traiter vos informations et que votre pays
      exige le consentement d'un parent, nous pouvons exiger le consentement de votre parent avant de collecter et
      d'utiliser ces informations.
    </p>
    <h2>Liens vers d'Autres Sites Web</h2>
    <p>
      Notre Service peut contenir des liens vers d'autres sites Web qui ne sont pas exploités par nous. Si vous cliquez
      sur un lien tiers, vous serez dirigé vers le site de ce tiers. Nous vous conseillons fortement de consulter la
      Politique de Confidentialité de chaque site que vous visitez.
    </p>
    <p>
      Nous n'avons aucun contrôle sur et n'assumons aucune responsabilité quant au contenu, aux politiques de
      confidentialité ou aux pratiques des sites ou services tiers.
    </p>
    <h2>Modifications de cette Politique de Confidentialité</h2>
    <p>
      Nous pouvons mettre à jour notre Politique de Confidentialité de temps à autre. Nous vous notifierons de tout
      changement en publiant la nouvelle Politique de Confidentialité sur cette page.
    </p>
    <p>
      Nous vous informerons par courriel et/ou un avis bien visible sur notre Service, avant que le changement ne
      devienne effectif et mettrons à jour la date de "dernière mise à jour" en haut de cette Politique de
      Confidentialité.
    </p>
    <p>
      Vous êtes conseillé de revoir cette Politique de Confidentialité périodiquement pour tout changement. Les
      modifications apportées à cette Politique de Confidentialité deviennent effectives lorsqu'elles sont publiées sur
      cette page.
    </p>
    <h2>Contactez-Nous</h2>
    <p>Si vous avez des questions sur cette Politique de Confidentialité, vous pouvez nous contacter :</p>
    <ul>
      <li>Par email : contact@unifeed.news</li>
    </ul>
  </div>
));
