import {useAtomValue, useSetAtom} from 'jotai';
import {atomWithStorage} from 'jotai/utils';
import {Locale} from '@/types';

export const localeState = atomWithStorage<Locale>(
  'locale',
  (() => {
    const originParts = location.host.split('.');
    if (originParts.length === 3) {
      const firstPart = originParts[0];
      if (Object.values<string>(Locale).includes(firstPart)) {
        return firstPart as Locale;
      }
    }
    const language = navigator.languages[0] ?? null;
    if (language === null) return Locale.En;
    const part = language.split('-')[0];
    return Object.values<string>(Locale).includes(part) ? (part as Locale) : Locale.En;
  })(),
  undefined,
  {
    getOnInit: true,
  },
);

export const useLocaleValue = () => useAtomValue(localeState);

export const useSetLocaleState = () => useSetAtom(localeState);
