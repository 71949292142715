import {match} from 'ts-pattern';
import {Locale} from '@/types';

export const getLocaleLabel = (locale: Locale) =>
  match(locale)
    .with(Locale.En, () => 'English')
    .with(Locale.Zh, () => '中文')
    .with(Locale.De, () => 'Deutsch')
    .with(Locale.Fr, () => 'Français')
    .with(Locale.Ja, () => '日本語')
    .with(Locale.Ko, () => '한국어')
    .with(Locale.Ru, () => 'Русский')
    .with(Locale.Es, () => 'Español')
    .with(Locale.Pt, () => 'Português')
    .with(Locale.It, () => 'Italiano')
    .with(Locale.Hi, () => 'हिंदी')
    .with(Locale.Nl, () => 'Nederlands')
    .with(Locale.Pl, () => 'Polski')
    .with(Locale.Tr, () => 'Türkçe')
    .with(Locale.Ar, () => 'العربية')
    .with(Locale.Id, () => 'Bahasa Indonesia')
    .exhaustive();
