import * as React from 'react';

export const Tr: React.FC = React.memo(() => (
  <div>
    <h1>Gizlilik Politikası</h1>
    <p>Son güncelleme: 05 Temmuz 2024</p>
    <p>
      Bu Gizlilik Politikası, Hizmeti kullandığınızda bilgilerinizi toplama, kullanma ve ifşa etme ile ilgili
      politikalarımızı ve prosedürlerimizi açıklamaktadır ve gizlilik haklarınız ve yasaların sizi nasıl koruduğu
      hakkında sizi bilgilendirir.
    </p>
    <p>
      Hizmeti sağlamak ve geliştirmek için kişisel verilerinizi kullanıyoruz, buna kişiselleştirilmiş reklamlar gösterme
      ve ücretli abonelikler sunma dahil. Hizmeti kullanarak, bu Gizlilik Politikası'na uygun olarak bilgilerin
      toplanmasına ve kullanılmasına izin vermiş olursunuz.
    </p>
    <h2>Yorumlama ve Tanımlar</h2>
    <h3>Yorumlama</h3>
    <p>
      İlk harfi büyük olan kelimelerin aşağıdaki koşullar altında tanımlanan anlamları vardır. Aşağıdaki tanımlar, tekil
      veya çoğul olmasına bakılmaksızın aynı anlama sahiptir.
    </p>
    <h3>Tanımlar</h3>
    <p>Bu Gizlilik Politikası'nın amaçları doğrultusunda:</p>
    <ul>
      <li>
        <strong>Hesap</strong>, Hizmetimize veya Hizmetimizin bir kısmına erişmek için sizin için oluşturulan benzersiz
        bir hesabı ifade eder.
      </li>
      <li>
        <strong>Bağlı Kuruluş</strong>, bir tarafı kontrol eden, tarafça kontrol edilen veya tarafla ortak kontrol
        altında olan bir kuruluşu ifade eder; burada "kontrol", yönetim kurulu veya diğer yönetici otoritenin seçimi
        için oy hakkına sahip hisselerin, sermaye payının veya diğer menkul kıymetlerin %50 veya daha fazlasına sahip
        olmayı ifade eder.
      </li>
      <li>
        <strong>Uygulama</strong>, Şirket tarafından sağlanan yazılım programı olan UniFeed News'i ifade eder.
      </li>
      <li>
        <strong>Şirket</strong> (bu Sözleşmede "Şirket", "Biz", "Bizi" veya "Bizim" olarak anılır) Ropsid Inc.'i ifade
        eder.
      </li>
      <li>
        <strong>Ülke</strong>, Ontario, Kanada anlamına gelir.
      </li>
      <li>
        <strong>Cihaz</strong>, bir bilgisayar, cep telefonu veya dijital tablet gibi Hizmete erişim sağlayabilen
        herhangi bir cihaz anlamına gelir.
      </li>
      <li>
        <strong>Kişisel Veri</strong>, tanımlanmış veya tanımlanabilir bir bireyle ilgili herhangi bir bilgi anlamına
        gelir.
      </li>
      <li>
        <strong>Hizmet</strong>, Uygulamayı ifade eder.
      </li>
      <li>
        <strong>Hizmet Sağlayıcı</strong>, Şirket adına verileri işleyen herhangi bir gerçek veya tüzel kişiyi ifade
        eder. Bu, Hizmeti kolaylaştırmak, Şirket adına Hizmeti sağlamak, Hizmetle ilgili hizmetleri gerçekleştirmek veya
        Hizmetin nasıl kullanıldığını analiz etmede Şirkete yardımcı olmak amacıyla Şirket tarafından istihdam edilen
        üçüncü taraf şirketleri veya bireyleri ifade eder.
      </li>
      <li>
        <strong>Kullanım Verileri</strong>, Hizmetin kullanımıyla veya Hizmet altyapısından (örneğin, bir sayfa
        ziyaretinin süresi) otomatik olarak toplanan verileri ifade eder.
      </li>
      <li>
        <strong>Siz</strong>, Hizmete erişen veya Hizmeti kullanan bireyi veya bu bireyin adına Hizmete erişen veya
        Hizmeti kullanan şirketi veya diğer tüzel kişiliği ifade eder.
      </li>
    </ul>
    <h2>Kişisel Verilerinizi Toplama ve Kullanma</h2>
    <h3>Toplanan Veri Türleri</h3>
    <h4>Kişisel Veri</h4>
    <p>
      Hizmetimizi kullanırken, sizinle iletişim kurmak veya sizi tanımlamak için kullanılabilecek belirli kişisel
      tanımlanabilir bilgileri sağlamanızı isteyebiliriz. Kişisel tanımlanabilir bilgiler şunları içerebilir, ancak
      bunlarla sınırlı değildir:
    </p>
    <ul>
      <li>E-posta adresi</li>
      <li>Ad ve soyad</li>
      <li>Telefon numarası</li>
      <li>Adres, Eyalet, İl, Posta kodu, Şehir</li>
      <li>Kullanım Verileri</li>
    </ul>
    <h4>Kullanım Verileri</h4>
    <p>Kullanım Verileri, Hizmeti kullanırken otomatik olarak toplanır.</p>
    <p>
      Kullanım Verileri, Cihazınızın İnternet Protokol adresi (ör. IP adresi), tarayıcı türü, tarayıcı sürümü,
      Hizmetimizin ziyaret ettiğiniz sayfaları, ziyaretinizin tarihi ve saati, bu sayfalarda geçirilen süre, benzersiz
      cihaz tanımlayıcıları ve diğer tanılama verileri gibi bilgileri içerebilir.
    </p>
    <p>
      Hizmete bir mobil cihazla veya mobil cihaz aracılığıyla eriştiğinizde, bu bilgiler mobil cihaz türünüz, mobil
      cihazınızın benzersiz kimliği, mobil cihazınızın IP adresi, mobil işletim sistemi, mobil İnternet tarayıcısı türü,
      benzersiz cihaz tanımlayıcıları ve diğer tanılama verileri gibi bilgileri içerebilir.
    </p>
    <h4>İzleme Teknolojileri ve Çerezler</h4>
    <p>
      Hizmetimizdeki etkinliği izlemek ve belirli bilgileri depolamak için Çerezler ve benzeri izleme teknolojileri
      kullanıyoruz. İzleme teknolojileri, bilgi toplamak ve izlemek ve Hizmetimizi geliştirmek ve analiz etmek için
      işaretçiler, etiketler ve komut dosyaları kullanır.
    </p>
    <p>Kullandığımız teknolojiler şunları içerebilir:</p>
    <ul>
      <li>
        <strong>Çerezler veya Tarayıcı Çerezleri.</strong> Çerez, Cihazınıza yerleştirilen küçük bir dosyadır.
        Tarayıcınıza tüm Çerezleri reddetmesi veya bir Çerez gönderildiğinde size bildirmesi talimatını verebilirsiniz.
        Ancak, Çerezleri kabul etmezseniz, Hizmetimizin bazı bölümlerini kullanamayabilirsiniz.
      </li>
      <li>
        <strong>Web İşaretçileri.</strong> Hizmetimizin belirli bölümleri ve e-postalarımız, Şirkete, örneğin bu
        sayfaları ziyaret eden kullanıcıları saymak veya bir e-postayı açmak ve diğer ilgili web sitesi istatistikleri
        (örneğin, belirli bir bölümün popülerliğini kaydetmek ve sistem ve sunucu bütünlüğünü doğrulamak) için izin
        veren web işaretçileri (aynı zamanda açık gifler, piksel etiketleri ve tek piksellik gifler olarak da bilinir)
        olarak bilinen küçük elektronik dosyalar içerebilir.
      </li>
    </ul>
    <p>Çerezleri aşağıdaki amaçlar doğrultusunda kullanıyoruz:</p>
    <ul>
      <li>
        <strong>Gerekli / Temel Çerezler</strong>
        Tür: Oturum Çerezleri Yönetici: Biz Amaç: Bu Çerezler, Web Sitesi aracılığıyla size sunulan hizmetleri sağlamak
        ve bazı özelliklerini kullanmanızı sağlamak için gereklidir. Kullanıcıları doğrulamaya ve kullanıcı hesaplarının
        sahte kullanımını önlemeye yardımcı olurlar. Bu Çerezler olmadan talep ettiğiniz hizmetler sağlanamaz ve bu
        Çerezleri yalnızca size bu hizmetleri sağlamak için kullanırız.
      </li>
      <li>
        <strong>Çerez Politikası / Bildirim Kabul Çerezleri</strong>
        Tür: Kalıcı Çerezler Yönetici: Biz Amaç: Bu Çerezler, kullanıcıların Web Sitesinde çerezlerin kullanımını kabul
        edip etmediklerini tanımlar.
      </li>
      <li>
        <strong>İşlevsellik Çerezleri</strong>
        Tür: Kalıcı Çerezler Yönetici: Biz Amaç: Bu Çerezler, Web Sitesini kullanırken yaptığınız seçimleri
        hatırlamamıza olanak tanır, örneğin giriş bilgilerinizi veya dil tercihinizi hatırlamak. Bu Çerezlerin amacı,
        size daha kişisel bir deneyim sunmak ve Web Sitesini her kullandığınızda tercihlerinizi yeniden girmenizi
        önlemektir.
      </li>
    </ul>
    <h4>Uygulamayı Kullanırken Toplanan Bilgiler</h4>
    <p>
      Uygulamamızın özelliklerini sağlamak için Uygulamamızı kullanırken, önceden izin alarak aşağıdaki bilgileri
      toplayabiliriz:
    </p>
    <ul>
      <li>Konumunuzla ilgili bilgiler</li>
      <li>Cihazınızdaki telefon rehberinden (kişiler listesi) bilgiler</li>
      <li>Cihazınızın kamerasından ve fotoğraf kütüphanesinden resimler ve diğer bilgiler</li>
    </ul>
    <p>
      Bu bilgileri Hizmetimizin özelliklerini sağlamak, Hizmetimizi geliştirmek ve özelleştirmek için kullanıyoruz.
      Bilgiler Şirket'in sunucularına ve/veya bir Hizmet Sağlayıcının sunucusuna yüklenebilir veya yalnızca Cihazınızda
      depolanabilir.
    </p>
    <p>
      Bu bilgilere erişimi istediğiniz zaman, Cihaz ayarlarınızdan etkinleştirebilir veya devre dışı bırakabilirsiniz.
    </p>
    <h3>Kişisel Verilerinizin Kullanımı</h3>
    <p>Şirket, Kişisel Verileri aşağıdaki amaçlarla kullanabilir:</p>
    <ul>
      <li>
        <strong>Hizmetimizi sağlamak ve sürdürmek</strong>, Hizmetimizin kullanımını izlemek dahil.
      </li>
      <li>
        <strong>Hesabınızı yönetmek:</strong> Hizmetin bir kullanıcısı olarak kaydınızı yönetmek. Sağladığınız Kişisel
        Veriler, kayıtlı bir kullanıcı olarak size sunulan Hizmetin farklı işlevlerine erişim sağlayabilir.
      </li>
      <li>
        <strong>Bir sözleşmenin ifası için:</strong> Satın aldığınız ürünler, öğeler veya hizmetlerle ilgili satın alma
        sözleşmesinin veya Hizmet aracılığıyla bizimle olan herhangi bir başka sözleşmenin geliştirilmesi, uyulması ve
        uygulanması.
      </li>
      <li>
        <strong>Sizinle iletişim kurmak için:</strong> E-posta, telefon aramaları, SMS veya bir mobil uygulamanın push
        bildirimleri gibi diğer elektronik iletişim biçimleriyle ilgili işlevler, ürünler veya sözleşmeli hizmetlerle
        ilgili güncellemeler veya bilgilendirici iletişimler hakkında sizinle iletişim kurmak için.
      </li>
      <li>
        Satın aldığınız veya sorguladığınız diğer ürünler, hizmetler ve etkinlikler hakkında size haberler, özel
        teklifler ve genel bilgiler sağlamak, bu tür bilgileri almayı tercih etmediyseniz.
      </li>
      <li>
        <strong>Talimatlarınızı yönetmek:</strong> Bize taleplerinizi yönetmek için.
      </li>
    </ul>
    <h4>İş transferleri için:</h4>
    <p>
      Birleşme, devir, yeniden yapılandırma, yeniden yapılanma, tasfiye veya varlıklarımızın bir kısmının veya tamamının
      başka bir şirkete devri veya satışı ile ilgili veya bununla ilgili olarak bilgileriniz kullanılabilir.
    </p>
    <h4>Diğer amaçlar için:</h4>
    <p>
      Bilgilerinizi diğer amaçlarla kullanabiliriz, örneğin veri analizi, kullanım eğilimlerinin belirlenmesi, tanıtım
      kampanyalarımızın etkinliğinin belirlenmesi ve Hizmetimizi, ürünlerimizi, pazarlamamızı ve deneyiminizi
      değerlendirmek ve geliştirmek için. Kişisel bilgilerinizi aşağıdaki durumlarda paylaşabiliriz:
    </p>
    <ul>
      <li>
        <strong>Hizmet Sağlayıcılarla:</strong> Hizmetimizin kullanımını izlemek ve analiz etmek, sizinle iletişim
        kurmak için kişisel bilgilerinizi Hizmet Sağlayıcılarla paylaşabiliriz.
      </li>
      <li>
        <strong>İş transferleri için:</strong> Şirket varlıklarının satışı veya başka bir şirkete devir sürecinde,
        kişisel bilgileriniz bu işlemler sırasında veya müzakereler sırasında paylaşılabilir veya aktarılabilir.
      </li>
      <li>
        <strong>Bağlı Kuruluşlarla:</strong> Kişisel bilgilerinizi bağlı kuruluşlarımızla paylaşabiliriz, bu durumda bu
        bağlı kuruluşların bu Gizlilik Politikasına uymasını talep edeceğiz. Bağlı kuruluşlar, ana şirketimizi ve
        kontrol ettiğimiz veya bizimle ortak kontrol altında olan diğer yan kuruluşları, ortak girişim ortaklarını veya
        diğer şirketleri içerir.
      </li>
      <li>
        <strong>İş ortaklarıyla:</strong> Kişisel bilgilerinizi iş ortaklarımızla belirli ürünler, hizmetler veya
        promosyonlar sunmak için paylaşabiliriz.
      </li>
      <li>
        <strong>Diğer kullanıcılarla:</strong> Diğer kullanıcılarla kamuya açık alanlarda kişisel bilgiler
        paylaştığınızda veya etkileşimde bulunduğunuzda, bu bilgiler tüm kullanıcılar tarafından görülebilir ve kamuya
        açık bir şekilde dağıtılabilir.
      </li>
      <li>
        <strong>Onayınızla:</strong> Kişisel bilgilerinizi başka bir amaç için onayınızla ifşa edebiliriz.
      </li>
    </ul>
    <h3>Kişisel Verilerinizin Saklanması</h3>
    <p>
      Şirket, Kişisel Verilerinizi yalnızca bu Gizlilik Politikasında belirtilen amaçlar için gerekli olduğu sürece
      saklayacaktır. Kişisel Verilerinizi, yasal yükümlülüklerimize uymak (örneğin, geçerli yasalara uymak için
      verilerinizi saklamamız gerekiyorsa), anlaşmazlıkları çözmek ve yasal sözleşmelerimizi ve politikalarımızı
      uygulamak için gerektiği ölçüde saklayacağız ve kullanacağız.
    </p>
    <p>
      Şirket ayrıca, dahili analiz amaçları için Kullanım Verilerini saklayacaktır. Kullanım Verileri genellikle daha
      kısa bir süre için saklanır, ancak bu veriler güvenliği güçlendirmek veya Hizmetimizin işlevselliğini iyileştirmek
      için kullanıldığında veya yasal olarak bu verileri daha uzun süre saklamakla yükümlü olduğumuzda bu veriler daha
      uzun süre saklanabilir.
    </p>
    <h3>Kişisel Verilerinizin Aktarılması</h3>
    <p>
      Bilgileriniz, Kişisel Veriler dahil olmak üzere, Şirketin faaliyet gösterdiği ofislerde ve işlemde yer alan
      tarafların bulunduğu diğer yerlerde işlenir. Bu, bu bilgilerin sizin eyaletiniz, iliniz, ülkeniz veya diğer
      hükümet yetki alanınızın dışındaki bilgisayarlara aktarılabileceği ve bu bilgisayarlarda saklanabileceği anlamına
      gelir ve bu durumda veri koruma yasaları sizin yetki alanınızdaki yasalardan farklı olabilir.
    </p>
    <p>
      Bu Gizlilik Politikasına verdiğiniz onay ve bu bilgileri göndermeniz, bu aktarıma onay verdiğiniz anlamına gelir.
    </p>
    <p>
      Şirket, verilerinizin güvenli bir şekilde işlenmesini sağlamak için makul ölçüde gerekli tüm adımları atacak ve
      Kişisel Verilerinizin bu Gizlilik Politikasına uygun olarak işlenmesini sağlamak için yeterli kontroller olmadıkça
      verilerinizi bir kuruluşa veya bir ülkeye aktarmayacaktır.
    </p>
    <h3>Kişisel Verilerinizin İfşası</h3>
    <h4>İşlemler</h4>
    <p>
      Şirket bir birleşme, devralma veya varlık satışı ile ilgili olduğunda, Kişisel Verileriniz aktarılabilir. Kişisel
      Verilerinizin aktarılmadan önce ve farklı bir Gizlilik Politikasına tabi olmadan önce bildirimde bulunacağız.
    </p>
    <h4>Yasal Zorunluluk</h4>
    <p>
      Şirket, yasal bir zorunluluk durumunda veya kamu otoritelerinin geçerli taleplerine yanıt olarak (örneğin, bir
      mahkeme veya hükümet ajansı) Kişisel Verilerinizi ifşa etmek zorunda kalabilir.
    </p>
    <h4>Diğer Yasal Gereklilikler</h4>
    <p>Şirket, Kişisel Verilerinizi aşağıdaki durumlarda iyi niyetle ifşa edebilir:</p>
    <ul>
      <li>Yasal bir zorunluluğa uymak</li>
      <li>Şirketin haklarını veya mülkiyetini korumak ve savunmak</li>
      <li>Hizmetle ilgili olası suistimalleri önlemek veya araştırmak</li>
      <li>Hizmetin kullanıcılarının veya halkın kişisel güvenliğini korumak</li>
      <li>Yasal sorumluluklara karşı korumak</li>
    </ul>
    <h3>Kişisel Verilerinizin Güvenliği</h3>
    <p>
      Kişisel Verilerinizin güvenliği bizim için önemlidir, ancak internet üzerinden iletimin veya elektronik
      depolamanın hiçbir yöntemi %100 güvenli değildir. Kişisel Verilerinizi korumak için ticari olarak kabul edilebilir
      araçlar kullanmaya çalışsak da, mutlak güvenliğini garanti edemeyiz.
    </p>
    <h2>Çocukların Gizliliği</h2>
    <p>
      Hizmetimiz 13 yaşın altındaki hiç kimseye hitap etmemektedir. 13 yaşın altındaki hiç kimseden kişisel
      tanımlanabilir bilgi toplamayız. Ebeveyn veya veliyseniz ve çocuğunuzun bize Kişisel Veri sağladığını fark
      ederseniz, lütfen bizimle iletişime geçin. 13 yaşın altındaki bir kimseden ebeveyn izni olmadan Kişisel Veri
      topladığımızı fark edersek, bu bilgileri sunucularımızdan kaldırmak için gerekli adımları atarız.
    </p>
    <p>
      Bilgilerinizi işlememiz için yasal bir temel olarak rızaya dayanmak zorunda olduğumuzda ve ülkeniz bir ebeveynin
      rızasını gerektiriyorsa, bu bilgileri toplamadan ve kullanmadan önce ebeveyninizin rızasını talep edebiliriz.
    </p>
    <h2>Diğer Web Sitelerine Bağlantılar</h2>
    <p>
      Hizmetimiz, tarafımızdan işletilmeyen diğer web sitelerine bağlantılar içerebilir. Üçüncü taraf bağlantısına
      tıkladığınızda, bu üçüncü tarafın sitesine yönlendirileceksiniz. Ziyaret ettiğiniz her sitenin Gizlilik
      Politikasını gözden geçirmenizi şiddetle tavsiye ederiz.
    </p>
    <p>
      Üçüncü taraf sitelerinin veya hizmetlerinin içeriği, gizlilik politikaları veya uygulamaları üzerinde hiçbir
      kontrolümüz yoktur ve hiçbir sorumluluk kabul etmeyiz.
    </p>
    <h2>Bu Gizlilik Politikasındaki Değişiklikler</h2>
    <p>
      Gizlilik Politikamızı zaman zaman güncelleyebiliriz. Yeni Gizlilik Politikasını bu sayfada yayınlayarak sizi
      herhangi bir değişiklik hakkında bilgilendireceğiz.
    </p>
    <p>
      Değişikliğin yürürlüğe girmesinden önce ve bu Gizlilik Politikasının üst kısmındaki "Son güncelleme" tarihini
      güncelleyerek sizi e-posta yoluyla ve/veya Hizmetimizdeki belirgin bir bildirimle bilgilendireceğiz.
    </p>
    <p>
      Bu Gizlilik Politikasını periyodik olarak gözden geçirmeniz tavsiye edilir. Bu Gizlilik Politikasındaki
      değişiklikler, bu sayfada yayınlandıklarında yürürlüğe girer.
    </p>
    <h2>Bize Ulaşın</h2>
    <p>Bu Gizlilik Politikası hakkında herhangi bir sorunuz varsa, bizimle iletişime geçebilirsiniz:</p>
    <ul>
      <li>E-posta ile: contact@unifeed.news</li>
    </ul>
  </div>
));
