import * as React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import {Seo} from '@/components/common';
import * as states from '@/states';
import {Footer} from './Footer';
import {Header} from './Header';
import {Home} from './Home';
import {Privacy} from './Privacy';

export const PublicApp: React.FC = React.memo(() => {
  const pageContents = states.bloc.usePageContentsValue();

  states.global.usePageScrollTopEffect();

  return (
    <>
      <Seo title={`${pageContents.title}: ${pageContents.headline}`} description={pageContents.subheading} />
      <Header />
      <Routes>
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Footer />
    </>
  );
});
