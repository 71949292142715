import {atom, useAtomValue, useSetAtom} from 'jotai';

type Layout = {
  width: number;
  height: number;
};

const layoutState = atom<Layout>({
  width: window.innerWidth,
  height: window.innerHeight,
});

export const useLayoutValue = () => useAtomValue(layoutState);

export const useSetLayoutState = () => useSetAtom(layoutState);
