import * as React from 'react';

export const Ru: React.FC = React.memo(() => (
  <div>
    <h1>Политика конфиденциальности</h1>
    <p>Последнее обновление: 5 июля 2024 г.</p>
    <p>
      Эта Политика конфиденциальности описывает наши правила и процедуры по сбору, использованию и раскрытию вашей
      информации при использовании Сервиса и информирует вас о ваших правах на конфиденциальность и о том, как закон
      защищает вас.
    </p>
    <p>
      Мы используем ваши персональные данные для предоставления и улучшения Сервиса, включая показ персонализированной
      рекламы и предложение платных подписок. Используя Сервис, вы соглашаетесь на сбор и использование информации в
      соответствии с настоящей Политикой конфиденциальности.
    </p>
    <h2>Толкование и определения</h2>
    <h3>Толкование</h3>
    <p>
      Слова, которые начинаются с заглавной буквы, имеют значения, определенные при следующих условиях. Следующие
      определения имеют одинаковое значение независимо от того, используются они в единственном или множественном числе.
    </p>
    <h3>Определения</h3>
    <p>Для целей настоящей Политики конфиденциальности:</p>
    <ul>
      <li>
        <strong>Аккаунт</strong> означает уникальный аккаунт, созданный для вас, чтобы получить доступ к нашему Сервису
        или его частям.
      </li>
      <li>
        <strong>Филиал</strong> означает юридическое лицо, которое контролирует, контролируется или находится под общим
        контролем с другой стороной, где «контроль» означает владение 50% или более акций, долей участия или других
        ценных бумаг, имеющих право голоса при выборе директоров или другой управляющей власти.
      </li>
      <li>
        <strong>Приложение</strong> относится к UniFeed News, программному обеспечению, предоставленному Компанией.
      </li>
      <li>
        <strong>Компания</strong> (именуемая в настоящем Соглашении как «Компания», «Мы», «Нас» или «Наш») относится к
        Ropsid Inc.
      </li>
      <li>
        <strong>Страна</strong> относится к: Онтарио, Канада
      </li>
      <li>
        <strong>Устройство</strong> означает любое устройство, которое может получить доступ к Сервису, такое как
        компьютер, мобильный телефон или цифровой планшет.
      </li>
      <li>
        <strong>Персональные данные</strong> — это любая информация, относящаяся к идентифицированному или
        идентифицируемому лицу.
      </li>
      <li>
        <strong>Сервис</strong> относится к Приложению.
      </li>
      <li>
        <strong>Поставщик услуг</strong> означает любое физическое или юридическое лицо, которое обрабатывает данные от
        имени Компании. Это относится к сторонним компаниям или лицам, нанятым Компанией для облегчения предоставления
        Сервиса, для предоставления Сервиса от имени Компании, для выполнения услуг, связанных с Сервисом, или для
        оказания помощи Компании в анализе использования Сервиса.
      </li>
      <li>
        <strong>Данные об использовании</strong> относятся к данным, собираемым автоматически, либо генерируемым в
        результате использования Сервиса, либо от инфраструктуры Сервиса самой по себе (например, продолжительность
        посещения страницы).
      </li>
      <li>
        <strong>Вы</strong> означает лицо, получающее доступ к Сервису или использующее его, или компанию или другое
        юридическое лицо, от имени которого это лицо получает доступ к Сервису или использует его, в зависимости от
        обстоятельств.
      </li>
    </ul>
    <h2>Сбор и использование ваших персональных данных</h2>
    <h3>Типы собираемых данных</h3>
    <h4>Персональные данные</h4>
    <p>
      При использовании нашего Сервиса мы можем попросить вас предоставить нам определенную персонально идентифицируемую
      информацию, которую можно использовать для связи с вами или для вашего идентифицирования. Персонально
      идентифицируемая информация может включать, но не ограничиваться:
    </p>
    <ul>
      <li>Адрес электронной почты</li>
      <li>Имя и фамилия</li>
      <li>Номер телефона</li>
      <li>Адрес, штат, провинция, почтовый индекс, город</li>
      <li>Данные об использовании</li>
    </ul>
    <h4>Данные об использовании</h4>
    <p>Данные об использовании собираются автоматически при использовании Сервиса.</p>
    <p>
      Данные об использовании могут включать такую информацию, как IP-адрес вашего Устройства, тип браузера, версия
      браузера, страницы нашего Сервиса, которые вы посещаете, время и дата вашего посещения, время, проведенное на этих
      страницах, уникальные идентификаторы устройств и другие диагностические данные.
    </p>
    <p>
      При доступе к Сервису через мобильное устройство эта информация может включать такие данные, как тип используемого
      мобильного устройства, уникальный идентификатор вашего мобильного устройства, IP-адрес вашего мобильного
      устройства, операционная система вашего мобильного устройства, тип мобильного интернет-браузера, уникальные
      идентификаторы устройств и другие диагностические данные.
    </p>
    <h4>Технологии отслеживания и Cookies</h4>
    <p>
      Мы используем файлы Cookies и аналогичные технологии отслеживания для отслеживания активности на нашем Сервисе и
      хранения определенной информации. Технологии отслеживания, которые мы используем, включают маяки, теги и скрипты
      для сбора и отслеживания информации и для улучшения и анализа нашего Сервиса.
    </p>
    <p>Используемые нами технологии могут включать:</p>
    <ul>
      <li>
        <strong>Cookies или браузерные Cookies.</strong> Cookie — это небольшой файл, размещаемый на вашем Устройстве.
        Вы можете настроить свой браузер на отказ от всех Cookies или на указание, когда Cookie отправляется. Однако,
        если вы не принимаете Cookies, вы не сможете использовать некоторые части нашего Сервиса.
      </li>
      <li>
        <strong>Веб-маяки.</strong> Некоторые разделы нашего Сервиса и наши электронные письма могут содержать небольшие
        электронные файлы, известные как веб-маяки (также называемые чистыми GIF, пиксельными тегами и одно-пиксельными
        GIF), которые позволяют Компании, например, подсчитывать пользователей, которые посетили эти страницы или
        открыли электронное письмо, и для других связанных статистических данных веб-сайта (например, для записи
        популярности определенного раздела и проверки целостности системы и сервера).
      </li>
    </ul>
    <p>Мы используем как сессионные, так и постоянные Cookies для указанных ниже целей:</p>
    <ul>
      <li>
        <strong>Необходимые / Основные Cookies</strong>
        Тип: Сессионные Cookies Управляются: Нами Цель: Эти Cookies необходимы для предоставления вам услуг, доступных
        через веб-сайт, и для использования некоторых его функций. Они помогают аутентифицировать пользователей и
        предотвращать мошенническое использование учетных записей пользователей. Без этих Cookies запрашиваемые вами
        услуги не могут быть предоставлены, и мы используем эти Cookies только для предоставления вам этих услуг.
      </li>
      <li>
        <strong>Cookies политики использования Cookies / Уведомление о принятии Cookies</strong>
        Тип: Постоянные Cookies Управляются: Нами Цель: Эти Cookies идентифицируют, приняли ли пользователи
        использование Cookies на веб-сайте.
      </li>
      <li>
        <strong>Функциональные Cookies</strong>
        Тип: Постоянные Cookies Управляются: Нами Цель: Эти Cookies позволяют нам запоминать выборы, которые вы делаете
        при использовании веб-сайта, такие как запоминание ваших учетных данных для входа или языка предпочтения. Цель
        этих Cookies заключается в предоставлении вам более персонализированного опыта и предотвращении необходимости
        повторного ввода ваших предпочтений каждый раз, когда вы используете веб-сайт.
      </li>
    </ul>
    <h4>Информация, собираемая при использовании Приложения</h4>
    <p>
      При использовании нашего Приложения, для предоставления функций нашего Приложения, мы можем собирать, с вашего
      предварительного разрешения:
    </p>
    <ul>
      <li>Информацию о вашем местоположении</li>
      <li>Информацию из телефонной книги вашего Устройства (список контактов)</li>
      <li>Фотографии и другую информацию из камеры и фотобиблиотеки вашего Устройства</li>
    </ul>
    <p>
      Мы используем эту информацию для предоставления функций нашего Сервиса, для улучшения и настройки нашего Сервиса.
      Информация может быть загружена на серверы Компании и/или серверы Поставщика услуг или просто сохранена на вашем
      устройстве.
    </p>
    <p>Вы можете в любое время включить или отключить доступ к этой информации через настройки вашего Устройства.</p>
    <h3>Использование ваших персональных данных</h3>
    <p>Компания может использовать персональные данные для следующих целей:</p>
    <ul>
      <li>
        <strong>Для предоставления и поддержки нашего Сервиса</strong>, включая мониторинг использования нашего Сервиса.
      </li>
      <li>
        <strong>Для управления вашим аккаунтом:</strong> для управления вашей регистрацией как пользователя Сервиса.
        Предоставленные вами персональные данные могут предоставить вам доступ к различным функциям Сервиса, которые
        доступны вам как зарегистрированному пользователю.
      </li>
      <li>
        <strong>Для выполнения контракта:</strong> разработка, соблюдение и выполнение контракта на покупку продуктов,
        предметов или услуг, которые вы приобрели, или любого другого контракта с нами через Сервис.
      </li>
      <li>
        <strong>Для связи с вами:</strong> Для связи с вами по электронной почте, телефонным звонкам, SMS или другим
        аналогичным формам электронных коммуникаций, таким как push-уведомления мобильного приложения по поводу
        обновлений или информационных сообщений, связанных с функциями, продуктами или заключенными услугами, включая
        обновления безопасности, когда это необходимо или разумно для их реализации.
      </li>
      <li>
        Чтобы предоставить вам новости, специальные предложения и общую информацию о других товарах, услугах и событиях,
        которые мы предлагаем и которые аналогичны тем, которые вы уже приобрели или о которых вы узнали, если вы не
        отказались от получения такой информации.
      </li>
      <li>
        <strong>Для управления вашими запросами:</strong> Для рассмотрения и управления вашими запросами к нам.
      </li>
    </ul>
    <h4>Для передачи бизнеса:</h4>
    <p>
      Мы можем использовать вашу информацию для оценки или проведения слияния, отчуждения, реструктуризации,
      реорганизации, роспуска или другой продажи или передачи всех или части наших активов, будь то как продолжающееся
      предприятие или как часть банкротства, ликвидации или аналогичной процедуры, в которой персональные данные,
      находящиеся у нас на счет наших пользователей Сервиса, являются частью передаваемых активов.
    </p>
    <h4>Для других целей:</h4>
    <p>
      Мы можем использовать вашу информацию для других целей, таких как анализ данных, выявление тенденций
      использования, определение эффективности наших рекламных кампаний и для оценки и улучшения нашего Сервиса,
      продуктов, маркетинга и вашего опыта. Мы можем делиться вашей персональной информацией в следующих ситуациях:
    </p>
    <ul>
      <li>
        <strong>С Поставщиками услуг:</strong> Мы можем делиться вашей персональной информацией с Поставщиками услуг для
        мониторинга и анализа использования нашего Сервиса, для связи с вами.
      </li>
      <li>
        <strong>Для передачи бизнеса:</strong> Мы можем делиться или передавать вашу персональную информацию в связи с
        или во время переговоров о любом слиянии, продаже активов компании, финансировании или приобретении всего или
        части нашего бизнеса другой компании.
      </li>
      <li>
        <strong>С филиалами:</strong> Мы можем делиться вашей информацией с нашими филиалами, в этом случае мы будем
        требовать от этих филиалов соблюдения данной Политики конфиденциальности. Филиалы включают нашу материнскую
        компанию и любые другие дочерние компании, партнеров по совместным предприятиям или другие компании, которые мы
        контролируем или которые находятся под общим контролем с нами.
      </li>
      <li>
        <strong>С деловыми партнерами:</strong> Мы можем делиться вашей информацией с нашими деловыми партнерами для
        предложения вам определенных продуктов, услуг или акций.
      </li>
      <li>
        <strong>С другими пользователями:</strong> когда вы делитесь персональной информацией или иным образом
        взаимодействуете в публичных областях с другими пользователями, такая информация может быть просмотрена всеми
        пользователями и может быть публично распространена за пределами.
      </li>
      <li>
        <strong>С вашего согласия:</strong> Мы можем раскрыть вашу персональную информацию для любых других целей с
        вашего согласия.
      </li>
    </ul>
    <h3>Сохранение ваших персональных данных</h3>
    <p>
      Компания будет хранить ваши персональные данные только так долго, как это необходимо для целей, изложенных в
      данной Политике конфиденциальности. Мы будем хранить и использовать ваши персональные данные в той мере, в какой
      это необходимо для выполнения наших юридических обязательств (например, если нам нужно сохранить ваши данные для
      соблюдения применимых законов), разрешения споров и обеспечения соблюдения наших правовых соглашений и политик.
    </p>
    <p>
      Компания также будет хранить данные об использовании для внутренних аналитических целей. Данные об использовании,
      как правило, хранятся в течение более короткого периода времени, за исключением случаев, когда эти данные
      используются для усиления безопасности или для улучшения функциональности нашего Сервиса, или если мы юридически
      обязаны хранить эти данные в течение более длительных периодов времени.
    </p>
    <h3>Передача ваших персональных данных</h3>
    <p>
      Ваша информация, включая персональные данные, обрабатывается в операционных офисах компании и в любых других
      местах, где находятся стороны, участвующие в обработке. Это означает, что эта информация может быть передана на
      компьютеры, расположенные за пределами вашего штата, провинции, страны или другой правительственной юрисдикции,
      где законы о защите данных могут отличаться от законов вашей юрисдикции.
    </p>
    <p>
      Ваше согласие на данную Политику конфиденциальности, сопровождаемое предоставлением такой информации, означает
      ваше согласие на такую передачу.
    </p>
    <p>
      Компания примет все разумные меры для обеспечения того, чтобы ваши данные обрабатывались безопасно и в
      соответствии с настоящей Политикой конфиденциальности, и никакая передача ваших персональных данных не будет
      осуществляться в организацию или страну, если не будут приняты соответствующие меры контроля, включая безопасность
      ваших данных и другой персональной информации.
    </p>
    <h3>Раскрытие ваших персональных данных</h3>
    <h4>Деловые сделки</h4>
    <p>
      Если компания участвует в слиянии, поглощении или продаже активов, ваши персональные данные могут быть переданы.
      Мы предоставим уведомление перед тем, как ваши персональные данные будут переданы и станут предметом другой
      Политики конфиденциальности.
    </p>
    <h4>Правоохранительные органы</h4>
    <p>
      При определенных обстоятельствах компания может быть обязана раскрыть ваши персональные данные, если это требуется
      по закону или в ответ на законные запросы государственных органов (например, суда или государственного
      учреждения).
    </p>
    <h4>Другие юридические требования</h4>
    <p>
      Компания может раскрыть ваши персональные данные в добросовестном убеждении, что такое действие необходимо для:
    </p>
    <ul>
      <li>Соблюдения юридического обязательства</li>
      <li>Защиты и отстаивания прав или собственности компании</li>
      <li>Предотвращения или расследования возможных неправомерных действий в связи с Сервисом</li>
      <li>Защиты личной безопасности пользователей Сервиса или общественности</li>
      <li>Защиты от юридической ответственности</li>
    </ul>
    <h3>Безопасность ваших персональных данных</h3>
    <p>
      Безопасность ваших персональных данных важна для нас, но помните, что ни один метод передачи через Интернет или
      метод электронного хранения не является на 100% безопасным. Несмотря на то, что мы стремимся использовать
      коммерчески приемлемые средства для защиты ваших персональных данных, мы не можем гарантировать их абсолютную
      безопасность.
    </p>
    <h2>Конфиденциальность детей</h2>
    <p>
      Наш Сервис не предназначен для лиц младше 13 лет. Мы не собираем преднамеренно персонально идентифицируемую
      информацию от лиц младше 13 лет. Если вы являетесь родителем или опекуном и вам известно, что ваш ребенок
      предоставил нам персональные данные, пожалуйста, свяжитесь с нами. Если мы обнаружим, что собрали персональные
      данные от лиц младше 13 лет без проверки согласия родителей, мы предпримем шаги по удалению этой информации с
      наших серверов.
    </p>
    <p>
      Если нам нужно полагаться на согласие в качестве правового основания для обработки вашей информации, и ваша страна
      требует согласия родителя, мы можем потребовать согласия вашего родителя перед сбором и использованием этой
      информации.
    </p>
    <h2>Ссылки на другие веб-сайты</h2>
    <p>
      Наш Сервис может содержать ссылки на другие веб-сайты, которые не управляются нами. Если вы нажмете на стороннюю
      ссылку, вы будете перенаправлены на сайт этой стороны. Мы настоятельно рекомендуем вам ознакомиться с Политикой
      конфиденциальности каждого сайта, который вы посещаете.
    </p>
    <p>
      Мы не контролируем и не несем ответственности за содержание, Политику конфиденциальности или практику сторонних
      сайтов или сервисов.
    </p>
    <h2>Изменения в этой Политике конфиденциальности</h2>
    <p>
      Мы можем время от времени обновлять нашу Политику конфиденциальности. Мы будем уведомлять вас о любых изменениях,
      публикуя новую Политику конфиденциальности на этой странице.
    </p>
    <p>
      Мы уведомим вас по электронной почте и/или путем размещения заметного уведомления на нашем Сервисе до вступления
      изменений в силу и обновим дату «последнего обновления» в верхней части данной Политики конфиденциальности.
    </p>
    <p>
      Вам рекомендуется периодически пересматривать данную Политику конфиденциальности на предмет изменений. Изменения в
      данной Политике конфиденциальности вступают в силу с момента их опубликования на этой странице.
    </p>
    <h2>Свяжитесь с нами</h2>
    <p>Если у вас есть вопросы по поводу данной Политики конфиденциальности, вы можете связаться с нами:</p>
    <ul>
      <li>По электронной почте: contact@unifeed.news</li>
    </ul>
  </div>
));
