import {PageContents} from '../utils';

export const pageContents: PageContents = {
  title: 'UniFeed Wiadomości',
  headline: 'Zobacz Świat Bez Filtrów – Bieżące Podsumowania Wiadomości z 13 Krajów w 16 Językach',
  subheading:
    'Odkryj wszechstronne, bezstronne bieżące podsumowania wiadomości z całego świata, wszystkie wygodnie przetłumaczone na Twój język ojczysty, dla lepszego zrozumienia i jasności',
  or: 'LUB',
  launchWebApp: 'Uruchom aplikację webową',
};
