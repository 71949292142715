export type Nullable<T> = T | null;

export enum Locale {
  En = 'en',
  Zh = 'zh',
  De = 'de',
  Fr = 'fr',
  Ja = 'ja',
  Ko = 'ko',
  Ru = 'ru',
  Es = 'es',
  Pt = 'pt',
  It = 'it',
  Hi = 'hi',
  Nl = 'nl',
  Pl = 'pl',
  Tr = 'tr',
  Ar = 'ar',
  Id = 'id',
}
