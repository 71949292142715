import * as React from 'react';

export const Pt: React.FC = React.memo(() => (
  <div>
    <h1>Política de Privacidade</h1>
    <p>Última atualização: 05 de julho de 2024</p>
    <p>
      Esta Política de Privacidade descreve as nossas políticas e procedimentos sobre a recolha, uso e divulgação das
      suas informações quando utiliza o Serviço e informa-o sobre os seus direitos de privacidade e como a lei o
      protege.
    </p>
    <p>
      Utilizamos os seus dados pessoais para fornecer e melhorar o Serviço, incluindo a exibição de anúncios
      personalizados e a oferta de assinaturas pagas. Ao utilizar o Serviço, concorda com a recolha e uso das
      informações de acordo com esta Política de Privacidade.
    </p>
    <h2>Interpretação e Definições</h2>
    <h3>Interpretação</h3>
    <p>
      As palavras cuja letra inicial é maiúscula têm significados definidos nas seguintes condições. As seguintes
      definições terão o mesmo significado, independentemente de aparecerem no singular ou no plural.
    </p>
    <h3>Definições</h3>
    <p>Para os propósitos desta Política de Privacidade:</p>
    <ul>
      <li>
        <strong>Conta</strong> significa uma conta única criada para si para aceder ao nosso Serviço ou partes do nosso
        Serviço.
      </li>
      <li>
        <strong>Afiliada</strong> significa uma entidade que controla, é controlada por ou está sob controlo comum com
        uma parte, onde "controlo" significa a propriedade de 50% ou mais das ações, participação acionária ou outros
        valores mobiliários com direito a voto para a eleição de diretores ou outra autoridade administrativa.
      </li>
      <li>
        <strong>Aplicação</strong> refere-se ao UniFeed News, o programa de software fornecido pela Empresa.
      </li>
      <li>
        <strong>Empresa</strong> (referida como "a Empresa", "Nós", "Nos" ou "Nosso" neste Acordo) refere-se à Ropsid
        Inc.
      </li>
      <li>
        <strong>País</strong> refere-se a: Ontário, Canadá
      </li>
      <li>
        <strong>Dispositivo</strong> significa qualquer dispositivo que possa aceder ao Serviço, como um computador, um
        telemóvel ou um tablet digital.
      </li>
      <li>
        <strong>Dados Pessoais</strong> são quaisquer informações que se relacionam a um indivíduo identificado ou
        identificável.
      </li>
      <li>
        <strong>Serviço</strong> refere-se à Aplicação.
      </li>
      <li>
        <strong>Prestador de Serviços</strong> significa qualquer pessoa física ou jurídica que processa os dados em
        nome da Empresa. Refere-se a empresas ou indivíduos terceirizados empregados pela Empresa para facilitar o
        Serviço, fornecer o Serviço em nome da Empresa, realizar serviços relacionados ao Serviço ou ajudar a Empresa a
        analisar como o Serviço é utilizado.
      </li>
      <li>
        <strong>Dados de Utilização</strong> refere-se a dados recolhidos automaticamente, gerados pelo uso do Serviço
        ou pela própria infraestrutura do Serviço (por exemplo, a duração de uma visita à página).
      </li>
      <li>
        <strong>Você</strong> significa o indivíduo que acede ou usa o Serviço, ou a empresa ou outra entidade jurídica
        em nome da qual tal indivíduo está a aceder ou a usar o Serviço, conforme aplicável.
      </li>
    </ul>
    <h2>Recolha e Utilização dos Seus Dados Pessoais</h2>
    <h3>Tipos de Dados Recolhidos</h3>
    <h4>Dados Pessoais</h4>
    <p>
      Ao utilizar o nosso Serviço, podemos solicitar que nos forneça certas informações pessoalmente identificáveis que
      podem ser usadas para contactá-lo ou identificá-lo. Informações pessoalmente identificáveis podem incluir, mas não
      se limitam a:
    </p>
    <ul>
      <li>Endereço de email</li>
      <li>Nome e apelido</li>
      <li>Número de telefone</li>
      <li>Endereço, Estado, Província, Código Postal, Cidade</li>
      <li>Dados de Utilização</li>
    </ul>
    <h4>Dados de Utilização</h4>
    <p>Dados de Utilização são recolhidos automaticamente ao utilizar o Serviço.</p>
    <p>
      Os Dados de Utilização podem incluir informações como o endereço de Protocolo de Internet do seu Dispositivo (por
      exemplo, endereço IP), tipo de navegador, versão do navegador, as páginas do nosso Serviço que visita, a hora e a
      data da sua visita, o tempo despendido nessas páginas, identificadores únicos de dispositivos e outros dados de
      diagnóstico.
    </p>
    <p>
      Quando acede ao Serviço por ou através de um dispositivo móvel, essas informações podem incluir informações como o
      tipo de dispositivo móvel que usa, o seu ID único de dispositivo móvel, o endereço IP do seu dispositivo móvel, o
      seu sistema operativo móvel, o tipo de navegador de Internet móvel que usa, identificadores únicos de dispositivos
      e outros dados de diagnóstico.
    </p>
    <h4>Tecnologias de Rastreio e Cookies</h4>
    <p>
      Utilizamos Cookies e tecnologias de rastreamento semelhantes para rastrear a atividade no nosso Serviço e
      armazenar certas informações. As tecnologias de rastreamento usadas são beacons, tags e scripts para recolher e
      rastrear informações e para melhorar e analisar o nosso Serviço.
    </p>
    <p>As tecnologias que utilizamos podem incluir:</p>
    <ul>
      <li>
        <strong>Cookies ou Cookies do Navegador.</strong> Um cookie é um pequeno ficheiro colocado no seu Dispositivo.
        Pode instruir o seu navegador a recusar todos os Cookies ou a indicar quando um Cookie está a ser enviado. No
        entanto, se não aceitar Cookies, pode não ser capaz de utilizar algumas partes do nosso Serviço.
      </li>
      <li>
        <strong>Web Beacons.</strong> Certas secções do nosso Serviço e os nossos emails podem conter pequenos ficheiros
        eletrónicos conhecidos como web beacons (também referidos como clear gifs, pixel tags e single-pixel gifs) que
        permitem à Empresa, por exemplo, contar os utilizadores que visitaram essas páginas ou abriram um email e para
        outras estatísticas relacionadas ao site (por exemplo, registando a popularidade de uma determinada secção e
        verificando a integridade do sistema e do servidor).
      </li>
    </ul>
    <p>Utilizamos Cookies de Sessão e Cookies Persistentes para os propósitos definidos abaixo:</p>
    <ul>
      <li>
        <strong>Cookies Necessários / Essenciais</strong>
        Tipo: Cookies de Sessão Administrados por: Nós Propósito: Estes Cookies são essenciais para fornecer serviços
        disponíveis através do site e para permitir que utilize algumas das suas funcionalidades. Eles ajudam a
        autenticar utilizadores e prevenir o uso fraudulento de contas de utilizador. Sem estes Cookies, os serviços que
        solicitou não podem ser fornecidos, e nós utilizamos estes Cookies apenas para fornecer esses serviços a si.
      </li>
      <li>
        <strong>Cookies de Aceitação da Política de Cookies / Aviso</strong>
        Tipo: Cookies Persistentes Administrados por: Nós Propósito: Estes Cookies identificam se os utilizadores
        aceitaram o uso de cookies no site.
      </li>
      <li>
        <strong>Cookies de Funcionalidade</strong>
        Tipo: Cookies Persistentes Administrados por: Nós Propósito: Estes Cookies permitem-nos lembrar as escolhas que
        faz ao utilizar o site, como lembrar os seus detalhes de login ou preferência de idioma. O propósito destes
        Cookies é fornecer uma experiência mais pessoal e evitar que tenha de reintroduzir as suas preferências cada vez
        que utiliza o site.
      </li>
    </ul>
    <h4>Informações Recolhidas ao Utilizar a Aplicação</h4>
    <p>
      Ao utilizar a nossa Aplicação, para fornecer funcionalidades da nossa Aplicação, podemos recolher, com a sua
      permissão prévia:
    </p>
    <ul>
      <li>Informações sobre a sua localização</li>
      <li>Informações da lista de contactos do seu Dispositivo</li>
      <li>Fotos e outras informações da câmara e biblioteca de fotos do seu Dispositivo</li>
    </ul>
    <p>
      Utilizamos estas informações para fornecer funcionalidades do nosso Serviço, para melhorar e personalizar o nosso
      Serviço. As informações podem ser carregadas para os servidores da Empresa e/ou para o servidor de um Prestador de
      Serviços ou podem ser simplesmente armazenadas no seu dispositivo.
    </p>
    <p>
      Pode ativar ou desativar o acesso a estas informações a qualquer momento, através das configurações do seu
      Dispositivo.
    </p>
    <h3>Uso dos Seus Dados Pessoais</h3>
    <p>A Empresa pode usar Dados Pessoais para os seguintes propósitos:</p>
    <ul>
      <li>
        <strong>Para fornecer e manter o nosso Serviço</strong>, incluindo monitorizar a utilização do nosso Serviço.
      </li>
      <li>
        <strong>Para gerir a sua Conta:</strong> para gerir o seu registo como utilizador do Serviço. Os Dados Pessoais
        que fornece podem dar-lhe acesso a diferentes funcionalidades do Serviço que estão disponíveis para si como um
        utilizador registado.
      </li>
      <li>
        <strong>Para a execução de um contrato:</strong> o desenvolvimento, cumprimento e realização do contrato de
        compra dos produtos, itens ou serviços que adquiriu ou de qualquer outro contrato connosco através do Serviço.
      </li>
      <li>
        <strong>Para contactá-lo:</strong> Para contactá-lo por e-mail, chamadas telefónicas, SMS ou outras formas
        equivalentes de comunicação eletrónica, como notificações push de uma aplicação móvel sobre atualizações ou
        comunicações informativas relacionadas às funcionalidades, produtos ou serviços contratados, incluindo as
        atualizações de segurança, quando necessário ou razoável para sua implementação.
      </li>
      <li>
        Para fornecer-lhe notícias, ofertas especiais e informações gerais sobre outros bens, serviços e eventos que
        oferecemos e que são semelhantes aos que já comprou ou perguntou, a menos que tenha optado por não receber tais
        informações.
      </li>
      <li>
        <strong>Para gerir os seus pedidos:</strong> Para atender e gerir os seus pedidos para nós.
      </li>
    </ul>
    <h4>Para transferências comerciais:</h4>
    <p>
      Podemos usar as suas informações para avaliar ou conduzir uma fusão, alienação, reestruturação, reorganização,
      dissolução ou outra venda ou transferência de alguns ou todos os nossos ativos, seja como uma empresa em atividade
      ou como parte de falência, liquidação ou processo similar, no qual os dados pessoais mantidos por nós sobre os
      nossos utilizadores do Serviço estão entre os ativos transferidos.
    </p>
    <h4>Para outros propósitos:</h4>
    <p>
      Podemos usar as suas informações para outros propósitos, como análise de dados, identificação de tendências de
      utilização, determinação da eficácia das nossas campanhas promocionais e para avaliar e melhorar o nosso Serviço,
      produtos, marketing e a sua experiência. Podemos partilhar as suas informações pessoais nas seguintes situações:
    </p>
    <ul>
      <li>
        <strong>Com Prestadores de Serviços:</strong> Podemos partilhar as suas informações pessoais com Prestadores de
        Serviços para monitorizar e analisar a utilização do nosso Serviço, para contactá-lo.
      </li>
      <li>
        <strong>Para transferências comerciais:</strong> Podemos partilhar ou transferir as suas informações pessoais em
        conexão com, ou durante negociações de, qualquer fusão, venda de ativos da empresa, financiamento ou aquisição
        de todo ou parte do nosso negócio para outra empresa.
      </li>
      <li>
        <strong>Com Afiliadas:</strong> Podemos partilhar as suas informações com as nossas afiliadas, caso em que
        exigiremos que essas afiliadas respeitem esta Política de Privacidade. Afiliadas incluem a nossa empresa-mãe e
        quaisquer outras subsidiárias, parceiros de joint venture ou outras empresas que controlamos ou que estão sob
        controlo comum connosco.
      </li>
      <li>
        <strong>Com parceiros de negócios:</strong> Podemos partilhar as suas informações com os nossos parceiros de
        negócios para oferecer-lhe determinados produtos, serviços ou promoções.
      </li>
      <li>
        <strong>Com outros utilizadores:</strong> quando partilha informações pessoais ou de outra forma interage nas
        áreas públicas com outros utilizadores, tais informações podem ser vistas por todos os utilizadores e podem ser
        distribuídas publicamente fora.
      </li>
      <li>
        <strong>Com o seu consentimento:</strong> Podemos divulgar as suas informações pessoais para qualquer outro
        propósito com o seu consentimento.
      </li>
    </ul>
    <h3>Retenção dos Seus Dados Pessoais</h3>
    <p>
      A Empresa reterá os seus Dados Pessoais apenas pelo tempo necessário para os propósitos estabelecidos nesta
      Política de Privacidade. Reteremos e usaremos os seus Dados Pessoais na medida necessária para cumprir com as
      nossas obrigações legais (por exemplo, se formos obrigados a reter os seus dados para cumprir com as leis
      aplicáveis), resolver disputas e fazer cumprir os nossos acordos e políticas legais.
    </p>
    <p>
      A Empresa também reterá Dados de Utilização para fins de análise interna. Dados de Utilização geralmente são
      retidos por um período de tempo mais curto, exceto quando esses dados são usados para fortalecer a segurança ou
      para melhorar a funcionalidade do nosso Serviço, ou quando somos legalmente obrigados a reter esses dados por
      períodos mais longos.
    </p>
    <h3>Transferência dos Seus Dados Pessoais</h3>
    <p>
      As suas informações, incluindo Dados Pessoais, são processadas nos escritórios operacionais da Empresa e em
      qualquer outro lugar onde as partes envolvidas no processamento estejam localizadas. Isso significa que essas
      informações podem ser transferidas para — e mantidas em — computadores localizados fora do seu estado, província,
      país ou outra jurisdição governamental onde as leis de proteção de dados podem diferir das de sua jurisdição.
    </p>
    <p>
      O seu consentimento para esta Política de Privacidade seguido pelo envio de tais informações representa a sua
      concordância com essa transferência.
    </p>
    <p>
      A Empresa tomará todas as medidas razoavelmente necessárias para garantir que os seus dados sejam tratados com
      segurança e de acordo com esta Política de Privacidade e nenhuma transferência dos seus Dados Pessoais ocorrerá
      para uma organização ou um país, a menos que haja controlos adequados em vigor, incluindo a segurança dos seus
      dados e outras informações pessoais.
    </p>
    <h3>Divulgação dos Seus Dados Pessoais</h3>
    <h4>Transações Comerciais</h4>
    <p>
      Se a Empresa estiver envolvida numa fusão, aquisição ou venda de ativos, os seus Dados Pessoais poderão ser
      transferidos. Forneceremos aviso antes que os seus Dados Pessoais sejam transferidos e se tornem sujeitos a uma
      Política de Privacidade diferente.
    </p>
    <h4>Aplicação da Lei</h4>
    <p>
      Em determinadas circunstâncias, a Empresa pode ser obrigada a divulgar os seus Dados Pessoais se exigido por lei
      ou em resposta a solicitações válidas de autoridades públicas (por exemplo, um tribunal ou uma agência
      governamental).
    </p>
    <h4>Outros Requisitos Legais</h4>
    <p>A Empresa pode divulgar os seus Dados Pessoais na crença de boa fé de que tal ação é necessária para:</p>
    <ul>
      <li>Cumprir com uma obrigação legal</li>
      <li>Proteger e defender os direitos ou propriedade da Empresa</li>
      <li>Prevenir ou investigar possíveis irregularidades em conexão com o Serviço</li>
      <li>Proteger a segurança pessoal dos utilizadores do Serviço ou do público</li>
      <li>Proteger contra responsabilidade legal</li>
    </ul>
    <h3>Segurança dos Seus Dados Pessoais</h3>
    <p>
      A segurança dos seus Dados Pessoais é importante para nós, mas lembre-se de que nenhum método de transmissão pela
      Internet ou método de armazenamento eletrónico é 100% seguro. Embora nos esforcemos para usar meios comercialmente
      aceitáveis para proteger os seus Dados Pessoais, não podemos garantir a sua segurança absoluta.
    </p>
    <h2>Privacidade das Crianças</h2>
    <p>
      O nosso Serviço não se dirige a menores de 13 anos. Não recolhemos intencionalmente informações pessoalmente
      identificáveis de menores de 13 anos. Se é um pai ou responsável e está ciente de que o seu filho nos forneceu
      Dados Pessoais, entre em contacto connosco. Se tomarmos conhecimento de que recolhemos Dados Pessoais de menores
      de 13 anos sem verificação do consentimento dos pais, tomaremos medidas para remover essas informações dos nossos
      servidores.
    </p>
    <p>
      Se precisarmos confiar no consentimento como base legal para processar as suas informações e o seu país exigir o
      consentimento de um dos pais, podemos exigir o consentimento dos seus pais antes de recolher e usar essas
      informações.
    </p>
    <h2>Links para Outros Sites</h2>
    <p>
      O nosso Serviço pode conter links para outros sites que não são operados por nós. Se clicar num link de terceiros,
      será direcionado para o site desse terceiro. Recomendamos fortemente que reveja a Política de Privacidade de cada
      site que visitar.
    </p>
    <p>
      Não temos controlo sobre e não assumimos responsabilidade pelo conteúdo, políticas de privacidade ou práticas de
      quaisquer sites ou serviços de terceiros.
    </p>
    <h2>Alterações a Esta Política de Privacidade</h2>
    <p>
      Podemos atualizar a nossa Política de Privacidade de tempos em tempos. Notificaremos sobre quaisquer alterações
      publicando a nova Política de Privacidade nesta página.
    </p>
    <p>
      Informaremos por e-mail e/ou um aviso proeminente no nosso Serviço, antes da alteração se tornar efetiva e
      atualizaremos a data da "última atualização" no topo desta Política de Privacidade.
    </p>
    <p>
      Recomendamos que reveja periodicamente esta Política de Privacidade para quaisquer alterações. As alterações a
      esta Política de Privacidade são efetivas quando publicadas nesta página.
    </p>
    <h2>Contacte-nos</h2>
    <p>Se tiver alguma dúvida sobre esta Política de Privacidade, pode contactar-nos:</p>
    <ul>
      <li>Por e-mail: contact@unifeed.news</li>
    </ul>
  </div>
));
