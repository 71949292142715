import '../public/css/index.css';
import '../public/css/tailwind/dist.css';
import * as React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter as Router} from 'react-router-dom';
import {Provider as JotaiProvider} from 'jotai';
import {HelmetProvider} from 'react-helmet-async';
import {BlockUi, Layout} from '@/components/common';
import {App} from './components';

const domNode = document.getElementById('rr')!;
const root = ReactDOM.createRoot(domNode);

root.render(
  <Router>
    <HelmetProvider>
      <JotaiProvider>
        {/* <FlashMsg /> */}
        <BlockUi />
        <Layout />
        <App />
      </JotaiProvider>
    </HelmetProvider>
  </Router>,
);
