import {PageContents} from '../utils';

export const pageContents: PageContents = {
  title: 'UniFeed Notizie',
  headline: 'Vedi il mondo senza filtri – Digest di notizie in tempo reale da 13 paesi in 16 lingue',
  subheading:
    'Scopri riassunti di notizie in tempo reale, dettagliati e imparziali da tutto il mondo, tradotti nella tua lingua per garantire chiarezza e comprensione',
  or: 'o',
  launchWebApp: "Avviare l'applicazione web",
};
