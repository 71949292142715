import * as React from 'react';

export const Hi: React.FC = React.memo(() => (
  <div>
    <h1>गोपनीयता नीति</h1>
    <p>अंतिम अपडेट: 05 जुलाई, 2024</p>
    <p>
      यह गोपनीयता नीति सेवा का उपयोग करने पर आपकी जानकारी के संग्रह, उपयोग और प्रकटीकरण पर हमारी नीतियों और प्रक्रियाओं
      का वर्णन करती है और आपको आपके गोपनीयता अधिकारों और कानून आपकी रक्षा कैसे करता है, के बारे में सूचित करती है।
    </p>
    <p>
      हम आपकी व्यक्तिगत जानकारी का उपयोग सेवा प्रदान करने और उसमें सुधार करने के लिए करते हैं, जिसमें व्यक्तिगत विज्ञापन
      दिखाना और भुगतान सदस्यता की पेशकश करना शामिल है। सेवा का उपयोग करके, आप इस गोपनीयता नीति के अनुसार जानकारी के
      संग्रह और उपयोग से सहमत होते हैं।
    </p>
    <h2>व्याख्या और परिभाषाएँ</h2>
    <h3>व्याख्या</h3>
    <p>
      जिन शब्दों के प्रारंभिक अक्षर बड़े हैं, उनके अर्थ निम्नलिखित शर्तों के तहत परिभाषित हैं। निम्नलिखित परिभाषाओं का
      वही अर्थ होगा चाहे वे एकवचन में हों या बहुवचन में।
    </p>
    <h3>परिभाषाएँ</h3>
    <p>इस गोपनीयता नीति के उद्देश्यों के लिए:</p>
    <ul>
      <li>
        <strong>खाता</strong> का अर्थ है सेवा या हमारी सेवा के कुछ हिस्सों तक पहुंचने के लिए आपके लिए बनाया गया एक
        अद्वितीय खाता।
      </li>
      <li>
        <strong>संबद्ध</strong> का अर्थ है कोई इकाई जो किसी पार्टी के साथ नियंत्रण करती है, द्वारा नियंत्रित होती है, या
        सामान्य नियंत्रण के अधीन होती है, जहाँ "नियंत्रण" का अर्थ है निदेशकों के चुनाव या अन्य प्रबंधकीय प्राधिकरण के
        लिए मतदान के योग्य 50% या अधिक शेयरों, इक्विटी ब्याज, या अन्य प्रतिभूतियों का स्वामित्व।
      </li>
      <li>
        <strong>अनुप्रयोग</strong> का अर्थ है UniFeed News, कंपनी द्वारा प्रदान किया गया सॉफ़्टवेयर प्रोग्राम।
      </li>
      <li>
        <strong>कंपनी</strong> (इस समझौते में "कंपनी", "हम", "हमें" या "हमारा" के रूप में संदर्भित) का अर्थ है Ropsid
        Inc.
      </li>
      <li>
        <strong>देश</strong> का अर्थ है: ओंटारियो, कनाडा
      </li>
      <li>
        <strong>उपकरण</strong> का अर्थ है कोई भी उपकरण जो सेवा तक पहुंच सकता है, जैसे कंप्यूटर, सेलफोन, या डिजिटल
        टैबलेट।
      </li>
      <li>
        <strong>व्यक्तिगत डेटा</strong> का अर्थ है कोई भी जानकारी जो किसी पहचाने जाने योग्य व्यक्ति से संबंधित है।
      </li>
      <li>
        <strong>सेवा</strong> का अर्थ है अनुप्रयोग।
      </li>
      <li>
        <strong>सेवा प्रदाता</strong> का अर्थ है कोई भी प्राकृतिक या कानूनी व्यक्ति जो कंपनी की ओर से डेटा को संसाधित
        करता है। इसका तात्पर्य उन तृतीय-पक्ष कंपनियों या व्यक्तियों से है जिन्हें सेवा को सुविधाजनक बनाने के लिए, कंपनी
        की ओर से सेवा प्रदान करने के लिए, सेवा से संबंधित सेवाओं का प्रदर्शन करने के लिए, या सेवा के उपयोग के तरीके का
        विश्लेषण करने में कंपनी की सहायता करने के लिए कंपनी द्वारा नियुक्त किया गया है।
      </li>
      <li>
        <strong>उपयोग डेटा</strong> का अर्थ है स्वचालित रूप से एकत्रित डेटा, या तो सेवा के उपयोग से उत्पन्न या सेवा
        अवसंरचना से स्वयं उत्पन्न (उदाहरण के लिए, पृष्ठ यात्रा की अवधि)।
      </li>
      <li>
        <strong>आप</strong> का अर्थ है वह व्यक्ति जो सेवा का उपयोग कर रहा है, या वह कंपनी, या अन्य कानूनी इकाई जिसके नाम
        पर वह व्यक्ति सेवा का उपयोग कर रहा है, जैसा कि लागू हो।
      </li>
    </ul>
    <h2>आपके व्यक्तिगत डेटा का संग्रह और उपयोग</h2>
    <h3>एकत्रित डेटा के प्रकार</h3>
    <h4>व्यक्तिगत डेटा</h4>
    <p>
      हमारी सेवा का उपयोग करते समय, हम आपसे कुछ व्यक्तिगत पहचान योग्य जानकारी प्रदान करने के लिए कह सकते हैं जिसका उपयोग
      संपर्क या पहचान के लिए किया जा सकता है। व्यक्तिगत पहचान योग्य जानकारी में शामिल हैं, लेकिन यह इन तक सीमित नहीं है:
    </p>
    <ul>
      <li>ईमेल पता</li>
      <li>प्रथम नाम और अंतिम नाम</li>
      <li>फ़ोन नंबर</li>
      <li>पता, राज्य, प्रांत, ज़िप/पोस्टल कोड, शहर</li>
      <li>उपयोग डेटा</li>
    </ul>
    <h4>उपयोग डेटा</h4>
    <p>उपयोग डेटा स्वचालित रूप से सेवा का उपयोग करते समय एकत्रित किया जाता है।</p>
    <p>
      उपयोग डेटा में आपके उपकरण का इंटरनेट प्रोटोकॉल पता (जैसे, IP पता), ब्राउज़र प्रकार, ब्राउज़र संस्करण, हमारी सेवा
      के पृष्ठ जिन पर आप गए, आपकी यात्रा की तिथि और समय, उन पृष्ठों पर बिताया गया समय, अद्वितीय उपकरण पहचानकर्ता, और
      अन्य निदान डेटा शामिल हो सकते हैं।
    </p>
    <p>
      जब आप मोबाइल डिवाइस के माध्यम से सेवा का उपयोग करते हैं, तो यह जानकारी आपके मोबाइल डिवाइस का प्रकार, आपके मोबाइल
      डिवाइस की अद्वितीय पहचान, आपके मोबाइल डिवाइस का IP पता, आपका मोबाइल ऑपरेटिंग सिस्टम, मोबाइल इंटरनेट ब्राउज़र का
      प्रकार, अद्वितीय उपकरण पहचानकर्ता, और अन्य निदान डेटा शामिल हो सकता है।
    </p>
    <h4>ट्रैकिंग तकनीक और कुकीज</h4>
    <p>
      हम कुकीज और समान ट्रैकिंग तकनीकों का उपयोग करके हमारी सेवा पर गतिविधियों को ट्रैक करने और कुछ जानकारी संग्रहीत
      करने के लिए करते हैं। ट्रैकिंग तकनीकों में बीकन, टैग, और स्क्रिप्ट शामिल होते हैं जो जानकारी एकत्र करने और ट्रैक
      करने और हमारी सेवा में सुधार और विश्लेषण करने के लिए उपयोग किए जाते हैं।
    </p>
    <p>हम जिन तकनीकों का उपयोग करते हैं, उनमें शामिल हैं:</p>
    <ul>
      <li>
        <strong>कुकीज या ब्राउज़र कुकीज।</strong> एक कुकी एक छोटा फाइल है जो आपके डिवाइस पर रखी जाती है। आप अपने
        ब्राउज़र को सभी कुकीज को अस्वीकार करने या जब कोई कुकी भेजी जा रही हो, तो संकेत देने के लिए निर्देशित कर सकते
        हैं। हालांकि, यदि आप कुकीज को स्वीकार नहीं करते हैं, तो आप हमारी सेवा के कुछ हिस्सों का उपयोग नहीं कर सकते।
      </li>
      <li>
        <strong>वेब बीकन।</strong> हमारी सेवा के कुछ अनुभागों और हमारे ईमेल्स में छोटे इलेक्ट्रॉनिक फाइल्स होते हैं
        जिन्हें वेब बीकन (जिन्हें क्लियर जीआईएफ्स, पिक्सेल टैग, और सिंगल-पिक्सेल जीआईएफ्स भी कहा जाता है) के रूप में
        जाना जाता है जो कंपनी को, उदाहरण के लिए, उन पृष्ठों पर गए उपयोगकर्ताओं की गिनती करने या एक ईमेल खोलने और अन्य
        संबंधित वेबसाइट सांख्यिकी (उदाहरण के लिए, किसी विशेष अनुभाग की लोकप्रियता रिकॉर्डिंग और प्रणाली और सर्वर की
        अखंडता सत्यापित करने के लिए) के लिए अनुमति देते हैं।
      </li>
    </ul>
    <p>हम नीचे निर्दिष्ट उद्देश्यों के लिए सत्र और स्थायी दोनों प्रकार के कुकीज का उपयोग करते हैं:</p>
    <ul>
      <li>
        <strong>आवश्यक / आवश्यक कुकीज</strong>
        प्रकार: सत्र कुकीज द्वारा प्रशासित: हम उद्देश्य: ये कुकीज आपको वेबसाइट के माध्यम से उपलब्ध सेवाओं को प्रदान करने
        और कुछ सुविधाओं का उपयोग करने के लिए आवश्यक हैं। वे उपयोगकर्ताओं को प्रमाणित करने और उपयोगकर्ता खातों के
        धोखाधड़ी वाले उपयोग को रोकने में मदद करते हैं। इन कुकीज के बिना, जो सेवाएँ आपने मांगी हैं, वे प्रदान नहीं की जा
        सकतीं, और हम केवल आपको उन सेवाओं को प्रदान करने के लिए इन कुकीज का उपयोग करते हैं।
      </li>
      <li>
        <strong>कुकीज नीति / नोटिस स्वीकृति कुकीज</strong>
        प्रकार: स्थायी कुकीज द्वारा प्रशासित: हम उद्देश्य: ये कुकीज पहचानती हैं कि उपयोगकर्ताओं ने वेबसाइट पर कुकीज के
        उपयोग को स्वीकार किया है या नहीं।
      </li>
      <li>
        <strong>फंक्शनलिटी कुकीज</strong>
        प्रकार: स्थायी कुकीज द्वारा प्रशासित: हम उद्देश्य: ये कुकीज हमें वेबसाइट का उपयोग करते समय आपकी द्वारा चुने गए
        विकल्पों को याद रखने की अनुमति देती हैं, जैसे कि आपके लॉगिन विवरण या भाषा वरीयता को याद रखना। इन कुकीज का
        उद्देश्य आपको एक अधिक व्यक्तिगत अनुभव प्रदान करना और हर बार वेबसाइट का उपयोग करते समय आपको अपनी वरीयताएँ फिर से
        दर्ज करने से बचाना है।
      </li>
    </ul>
    <h4>अनुप्रयोग का उपयोग करते समय एकत्र की गई जानकारी</h4>
    <p>
      हमारी अनुप्रयोग का उपयोग करते समय, अनुप्रयोग की सुविधाओं को प्रदान करने के लिए, हम आपके पूर्व अनुमति के साथ
      जानकारी एकत्र कर सकते हैं:
    </p>
    <ul>
      <li>आपकी स्थान की जानकारी</li>
      <li>आपके डिवाइस की फोन बुक (संपर्क सूची) से जानकारी</li>
      <li>आपके डिवाइस की कैमरा और फोटो लाइब्रेरी से चित्र और अन्य जानकारी</li>
    </ul>
    <p>
      हम इस जानकारी का उपयोग सेवा की सुविधाओं को प्रदान करने, सेवा में सुधार और अनुकूलित करने के लिए करते हैं। यह
      जानकारी कंपनी के सर्वरों और/या सेवा प्रदाता के सर्वर पर अपलोड की जा सकती है या इसे बस आपके डिवाइस पर संग्रहीत किया
      जा सकता है।
    </p>
    <p>आप अपने डिवाइस सेटिंग्स के माध्यम से कभी भी इस जानकारी तक पहुँच को सक्षम या अक्षम कर सकते हैं।</p>
    <h3>आपके व्यक्तिगत डेटा का उपयोग</h3>
    <p>कंपनी व्यक्तिगत डेटा का उपयोग निम्नलिखित उद्देश्यों के लिए कर सकती है:</p>
    <ul>
      <li>
        <strong>हमारी सेवा प्रदान करने और बनाए रखने के लिए</strong>, जिसमें हमारी सेवा के उपयोग की निगरानी करना शामिल
        है।
      </li>
      <li>
        <strong>आपके खाता का प्रबंधन करने के लिए:</strong> सेवा के उपयोगकर्ता के रूप में आपके पंजीकरण का प्रबंधन करने के
        लिए। आपके द्वारा प्रदान किए गए व्यक्तिगत डेटा आपको पंजीकृत उपयोगकर्ता के रूप में उपलब्ध सेवा की विभिन्न
        कार्यक्षमताओं तक पहुंचने की अनुमति दे सकते हैं।
      </li>
      <li>
        <strong>कॉन्ट्रैक्ट का प्रदर्शन:</strong> उन उत्पादों, वस्त्रों, या सेवाओं के लिए क्रय कॉन्ट्रैक्ट का विकास,
        अनुपालन, और निष्पादन जिसमें आपने सेवा के माध्यम से खरीदारी की हो या हमारे साथ किसी अन्य कॉन्ट्रैक्ट का प्रदर्शन।
      </li>
      <li>
        <strong>आपसे संपर्क करने के लिए:</strong> आपको ईमेल, टेलीफोन कॉल, एसएमएस, या अन्य समकक्ष इलेक्ट्रॉनिक संचार के
        रूपों के माध्यम से संपर्क करने के लिए, जैसे कि मोबाइल अनुप्रयोग की पुश सूचनाएँ, कार्यक्षमताओं, उत्पादों या
        संविदात्मक सेवाओं से संबंधित अपडेट या जानकारी संचार से संबंधित, जिसमें सुरक्षा अपडेट भी शामिल है, जब उनके
        कार्यान्वयन के लिए आवश्यक या उचित हो।
      </li>
      <li>
        आपको समाचार, विशेष ऑफ़र, और उन अन्य उत्पादों, सेवाओं, और घटनाओं के बारे में सामान्य जानकारी प्रदान करने के लिए
        जो हमने पहले से खरीदी हैं या जिनके बारे में आपने पूछा है, जब तक आपने ऐसी जानकारी प्राप्त नहीं करने का विकल्प
        नहीं चुना है।
      </li>
      <li>
        <strong>आपके अनुरोधों का प्रबंधन करने के लिए:</strong> हमारे पास आपके अनुरोधों का प्रबंधन करने के लिए।
      </li>
    </ul>
    <h4>व्यावसायिक हस्तांतरण के लिए:</h4>
    <p>
      हम आपके डेटा का उपयोग किसी संयोजन, विविवर्तन, पुनर्गठन, पुनर्गठन, विघटन, या किसी अन्य बिक्री या कुछ या सभी
      संपत्तियों के हस्तांतरण का मूल्यांकन या संचालन करने के लिए कर सकते हैं, चाहे वह एक चलती चिंता के रूप में हो या भाग
      के रूप में दिवालियापन, परिसमापन, या समान प्रक्रिया, जिसमें हमारी सेवा उपयोगकर्ताओं के बारे में हमारे पास मौजूद
      व्यक्तिगत डेटा भी शामिल हो सकता है।
    </p>
    <h4>अन्य उद्देश्यों के लिए:</h4>
    <p>
      हम आपके डेटा का उपयोग अन्य उद्देश्यों के लिए कर सकते हैं, जैसे डेटा विश्लेषण, उपयोग प्रवृत्तियों की पहचान करना,
      हमारी प्रचार अभियानों की प्रभावशीलता का निर्धारण करना, और हमारी सेवा, उत्पादों, विपणन, और आपके अनुभव को सुधारने के
      लिए। हम आपके व्यक्तिगत डेटा को निम्नलिखित स्थितियों में साझा कर सकते हैं:
    </p>
    <ul>
      <li>
        <strong>सेवा प्रदाताओं के साथ:</strong> हम आपके व्यक्तिगत डेटा को सेवा प्रदाताओं के साथ साझा कर सकते हैं जो
        हमारे सेवा का उपयोग करने और विश्लेषण करने के लिए हमारे साथ डेटा को संसाधित करते हैं, संपर्क करते हैं।
      </li>
      <li>
        <strong>व्यावसायिक हस्तांतरण के लिए:</strong> हम आपके व्यक्तिगत डेटा को किसी संयोजन, कंपनी संपत्तियों की बिक्री,
        वित्तपोषण, या हमारे व्यवसाय के किसी भी हिस्से के दूसरे कंपनी के अधिग्रहण के दौरान या उसके वार्ता के दौरान साझा
        या हस्तांतरित कर सकते हैं।
      </li>
      <li>
        <strong>संबद्धों के साथ:</strong> हम आपके डेटा को अपने सहायक कंपनियों के साथ साझा कर सकते हैं, इस मामले में हम
        उनसे इस गोपनीयता नीति का पालन करने की आवश्यकता करेंगे। सहायक कंपनियों में हमारी मूल कंपनी और कोई अन्य सहायक
        कंपनियां, संयुक्त उद्यम भागीदार, या अन्य कंपनियां शामिल होती हैं जिन्हें हम नियंत्रित करते हैं या जो हमारे साथ
        सामान्य नियंत्रण में हैं।
      </li>
      <li>
        <strong>व्यवसायिक भागीदारों के साथ:</strong> हम आपके डेटा को अपने व्यावसायिक भागीदारों के साथ साझा कर सकते हैं
        ताकि आपको कुछ उत्पादों, सेवाओं, या प्रचार प्रस्ताव प्रदान किए जा सकें।
      </li>
      <li>
        <strong>अन्य उपयोगकर्ताओं के साथ:</strong> जब आप अन्य उपयोगकर्ताओं के साथ सार्वजनिक क्षेत्रों में बातचीत करते
        हैं, तब आपके व्यक्तिगत डेटा को अन्य उपयोगकर्ताओं द्वारा देखा जा सकता है और सार्वजनिक रूप से बाहर वितरित किया जा
        सकता है।
      </li>
      <li>
        <strong>आपकी सहमति के साथ:</strong> हम आपके व्यक्तिगत डेटा को किसी अन्य उद्देश्य के लिए आपकी सहमति से प्रकट कर
        सकते हैं।
      </li>
    </ul>
    <h3>आपके व्यक्तिगत डेटा की संरक्षा</h3>
    <p>
      कंपनी आपके व्यक्तिगत डेटा को केवल उतनी ही अवधि के लिए बनाए रखेगी जितनी इस गोपनीयता नीति में निर्धारित उद्देश्यों
      के लिए आवश्यक है। हम आपकी व्यक्तिगत डेटा को तब तक बनाए रखेंगे और उपयोग करेंगे जब तक हमारे पास कानूनी दायित्वों
      (उदाहरण के लिए, अगर हमें आपके डेटा को लागू कानूनों के अनुपालन के लिए बनाए रखने की आवश्यकता है), विवादों का समाधान
      करने, और हमारी कानूनी समझौतों और नीतियों को लागू करने के लिए आवश्यक है।
    </p>
    <p>
      कंपनी भी उपयोग डेटा को आंतरिक विश्लेषण उद्देश्यों के लिए बनाए रखेगी। उपयोग डेटा आमतौर पर कम समय के लिए बनाए रखा
      जाता है, सिवाय इसके जब इस डेटा का उपयोग हमारी सेवा की सुरक्षा को मजबूत करने या उसकी कार्यक्षमता को सुधारने के लिए
      किया जाता है, या जब हमें इस डेटा को अधिक समय तक बनाए रखने की कानूनी बाध्यता होती है।
    </p>
    <h3>आपके व्यक्तिगत डेटा का स्थानांतरण</h3>
    <p>
      आपका डेटा, जिसमें व्यक्तिगत डेटा शामिल है, कंपनी के संचालन कार्यालयों में और अन्य स्थानों में संसाधित किया जाता है
      जहाँ डेटा को संसाधित करने वाले पक्ष स्थित होते हैं। इसका मतलब है कि यह जानकारी — और आपके राज्य, प्रांत, देश, या
      अन्य सरकारी अधिकार क्षेत्र के बाहर स्थित कंप्यूटरों पर स्थानांतरित और बनाए रखी जा सकती है जहाँ डेटा संरक्षण कानून
      आपके अधिकार क्षेत्र से भिन्न हो सकते हैं।
    </p>
    <p>
      इस गोपनीयता नीति के लिए आपकी सहमति के बाद आपके द्वारा इस प्रकार की जानकारी का सबमिशन उस स्थानांतरण के लिए आपकी
      सहमति का प्रतिनिधित्व करता है।
    </p>
    <p>
      कंपनी सभी कदम उठाएगी ताकि आपकी डेटा का सुरक्षित रूप से और इस गोपनीयता नीति के अनुसार संरक्षित किया जा सके और किसी
      संगठन या देश को आपके व्यक्तिगत डेटा का कोई स्थानांतरण तब तक नहीं होगा जब तक कि वहाँ पर्याप्त नियंत्रण न हो जिसमें
      आपकी डेटा की सुरक्षा और अन्य व्यक्तिगत जानकारी शामिल हो।
    </p>
    <h3>आपके व्यक्तिगत डेटा का प्रकटीकरण</h3>
    <h4>व्यवसायिक लेनदेन</h4>
    <p>
      अगर कंपनी किसी विलय, अधिग्रहण, या संपत्ति बिक्री में शामिल होती है, तो आपका व्यक्तिगत डेटा स्थानांतरित किया जा
      सकता है। हम आपके व्यक्तिगत डेटा के स्थानांतरण से पहले और किसी अन्य गोपनीयता नीति के अधीन होने से पहले सूचित
      करेंगे।
    </p>
    <h4>कानून प्रवर्तन</h4>
    <p>
      कुछ परिस्थितियों में, कंपनी आपके व्यक्तिगत डेटा को प्रकट करने की कानूनी बाध्यता के कारण या सार्वजनिक अधिकारियों के
      (जैसे, एक न्यायालय या सरकारी एजेंसी) द्वारा उचित अनुरोधों के जवाब में आवश्यक हो सकती है।
    </p>
    <h4>अन्य कानूनी आवश्यकताएँ</h4>
    <p>कंपनी आपके व्यक्तिगत डेटा को उस अच्छी विश्वास में प्रकट कर सकती है कि ऐसी कार्रवाई आवश्यक है:</p>
    <ul>
      <li>कानूनी बाध्यता का पालन करने के लिए</li>
      <li>कंपनी के अधिकारों या संपत्ति की रक्षा और बचाव करने के लिए</li>
      <li>सेवा के संबंध में संभावित गलत काम की रोकथाम या जांच करने के लिए</li>
      <li>सेवा उपयोगकर्ताओं या जनता की व्यक्तिगत सुरक्षा की रक्षा करने के लिए</li>
      <li>कानूनी उत्तरदायित्व के खिलाफ रक्षा करने के लिए</li>
    </ul>
    <h3>आपके व्यक्तिगत डेटा की सुरक्षा</h3>
    <p>
      आपकी व्यक्तिगत डेटा की सुरक्षा हमारे लिए महत्वपूर्ण है, लेकिन याद रखें कि इंटरनेट पर ट्रांसमिशन की कोई विधि, या
      इलेक्ट्रॉनिक संग्रहण की कोई विधि, 100% सुरक्षित नहीं है। जबकि हम आपकी व्यक्तिगत डेटा की सुरक्षा के लिए वाणिज्यिक
      रूप से स्वीकार्य साधनों का उपयोग करने की कोशिश करते हैं, हम इसकी पूर्ण सुरक्षा की गारंटी नहीं दे सकते।
    </p>
    <h2>बच्चों की गोपनीयता</h2>
    <p>
      हमारी सेवा 13 साल से कम उम्र के किसी भी व्यक्ति को संबोधित नहीं करती है। हम जानबूझकर 13 साल से कम उम्र के किसी भी
      व्यक्ति से व्यक्तिगत पहचान योग्य जानकारी एकत्र नहीं करते हैं। यदि आप एक माता-पिता या अभिभावक हैं और आपको पता है कि
      आपके बच्चे ने हमें व्यक्तिगत डेटा प्रदान किया है, तो कृपया हमसे संपर्क करें। अगर हमें पता चलता है कि हमने 13 साल
      से कम उम्र के किसी व्यक्ति से माता-पिता की सहमति की पुष्टि के बिना व्यक्तिगत डेटा एकत्र किया है, तो हम उस जानकारी
      को अपने सर्वरों से हटाने के लिए कदम उठाएंगे।
    </p>
    <p>
      यदि हमें आपकी जानकारी और आपके देश की जानकारी को प्रोसेस करने के लिए सहमति की आवश्यकता है, तो हम आपके माता-पिता की
      सहमति की आवश्यकता हो सकती है, इससे पहले कि हम उस जानकारी को एकत्र करें और उपयोग करें।
    </p>
    <h2>अन्य वेबसाइटों के लिंक</h2>
    <p>
      हमारी सेवा में अन्य वेबसाइटों के लिंक हो सकते हैं जो हमारे द्वारा संचालित नहीं हैं। यदि आप किसी तृतीय पक्ष लिंक पर
      क्लिक करते हैं, तो आपको उस तृतीय पक्ष की साइट पर निर्देशित किया जाएगा। हम आपको सलाह देते हैं कि आप हर साइट पर जाने
      से पहले गोपनीयता नीति की समीक्षा करें।
    </p>
    <p>
      हमारे पास अन्य वेबसाइटों या सेवाओं की सामग्री, गोपनीयता नीतियों, या प्रथाओं पर कोई नियंत्रण नहीं है और हम उनकी कोई
      जिम्मेदारी नहीं लेते हैं।
    </p>
    <h2>इस गोपनीयता नीति में परिवर्तन</h2>
    <p>
      हम समय-समय पर अपनी गोपनीयता नीति को अपडेट कर सकते हैं। हम इस पृष्ठ पर नई गोपनीयता नीति को पोस्ट करके आपको किसी भी
      परिवर्तन के बारे में सूचित करेंगे।
    </p>
    <p>
      हम परिवर्तन लागू होने से पहले आपको ईमेल और/या हमारी सेवा पर एक प्रमुख सूचना के माध्यम से सूचित करेंगे और इस
      गोपनीयता नीति के शीर्ष पर "अंतिम अपडेट" की तारीख अपडेट करेंगे।
    </p>
    <p>
      आपको सलाह दी जाती है कि आप इस गोपनीयता नीति की समय-समय पर समीक्षा करें। इस गोपनीयता नीति में परिवर्तन तब प्रभावी
      होते हैं जब वे इस पृष्ठ पर पोस्ट किए जाते हैं।
    </p>
    <h2>हमसे संपर्क करें</h2>
    <p>यदि आपके पास इस गोपनीयता नीति के बारे में कोई प्रश्न हैं, तो आप हमसे संपर्क कर सकते हैं:</p>
    <ul>
      <li>ईमेल द्वारा: contact@unifeed.news</li>
    </ul>
  </div>
));
