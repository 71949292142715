import * as React from 'react';
import {match} from 'ts-pattern';
import * as states from '@/states';
import {Locale} from '@/types';
import {Ar} from './Ar';
import {De} from './De';
import {En} from './En';
import {Es} from './Es';
import {Fr} from './Fr';
import {Hi} from './Hi';
import {Id} from './Id';
import {It} from './It';
import {Ja} from './Ja';
import {Ko} from './Ko';
import {Nl} from './Nl';
import {Pl} from './Pl';
import {Pt} from './Pt';
import {Ru} from './Ru';
import {Tr} from './Tr';
import {Zh} from './Zh';

export const Privacy: React.FC = React.memo(() => {
  const locale = states.global.useLocaleValue();
  return (
    <section className="relative grid gap-16 bg-gray-700 py-24 bg-blend-multiply lg:py-36">
      <div className="absolute inset-0 z-0 h-full w-full bg-gradient-to-br from-blue-50 to-transparent dark:from-blue-900" />
      <div className="[&_h6]:text-md relative z-10 mx-auto max-w-screen-xl px-4 [&>div]:grid [&>div]:gap-2 [&_*]:text-white [&_h1]:my-8 [&_h1]:text-4xl [&_h2]:my-6 [&_h2]:text-3xl [&_h3]:my-4 [&_h3]:text-2xl [&_h4]:my-2 [&_h4]:text-xl [&_h5]:my-1 [&_h5]:text-lg [&_h6]:my-0.5">
        {match(locale)
          .with(Locale.En, () => <En />)
          .with(Locale.Zh, () => <Zh />)
          .with(Locale.De, () => <De />)
          .with(Locale.Fr, () => <Fr />)
          .with(Locale.Ja, () => <Ja />)
          .with(Locale.Ko, () => <Ko />)
          .with(Locale.Ru, () => <Ru />)
          .with(Locale.Es, () => <Es />)
          .with(Locale.Pt, () => <Pt />)
          .with(Locale.It, () => <It />)
          .with(Locale.Hi, () => <Hi />)
          .with(Locale.Nl, () => <Nl />)
          .with(Locale.Pl, () => <Pl />)
          .with(Locale.Tr, () => <Tr />)
          .with(Locale.Ar, () => <Ar />)
          .with(Locale.Id, () => <Id />)
          .exhaustive()}
      </div>
    </section>
  );
});
