import * as React from 'react';

export const De: React.FC = React.memo(() => (
  <div>
    <h1>Datenschutzrichtlinie</h1>
    <p>Letzte Aktualisierung: 05. Juli 2024</p>
    <p>
      Diese Datenschutzrichtlinie beschreibt unsere Richtlinien und Verfahren zur Erhebung, Nutzung und Offenlegung
      Ihrer Informationen, wenn Sie den Dienst nutzen, und informiert Sie über Ihre Datenschutzrechte und wie das Gesetz
      Sie schützt.
    </p>
    <p>
      Wir verwenden Ihre personenbezogenen Daten, um den Dienst bereitzustellen und zu verbessern, einschließlich der
      Anzeige personalisierter Werbung und des Angebots kostenpflichtiger Abonnements. Durch die Nutzung des Dienstes
      stimmen Sie der Erhebung und Nutzung von Informationen gemäß dieser Datenschutzrichtlinie zu.
    </p>
    <h2>Auslegung und Definitionen</h2>
    <h3>Auslegung</h3>
    <p>
      Die Wörter, bei denen der Anfangsbuchstabe großgeschrieben ist, haben die unten definierten Bedeutungen. Diese
      Definitionen haben die gleiche Bedeutung, unabhängig davon, ob sie im Singular oder im Plural erscheinen.
    </p>
    <h3>Definitionen</h3>
    <p>Für die Zwecke dieser Datenschutzrichtlinie:</p>
    <ul>
      <li>
        <strong>Konto</strong> bedeutet ein einzigartiges Konto, das für Sie erstellt wurde, um auf unseren Dienst oder
        Teile unseres Dienstes zuzugreifen.
      </li>
      <li>
        <strong>Partnerunternehmen</strong> bedeutet ein Unternehmen, das eine Partei kontrolliert, von ihr kontrolliert
        wird oder unter gemeinsamer Kontrolle mit einer Partei steht, wobei „Kontrolle“ den Besitz von 50 % oder mehr
        der Aktien, Kapitalbeteiligungen oder anderen Wertpapiere bedeutet, die zur Wahl von Direktoren oder anderen
        Führungskräften berechtigt sind.
      </li>
      <li>
        <strong>Anwendung</strong> bezieht sich auf UniFeed News, das von der Gesellschaft bereitgestellte
        Softwareprogramm.
      </li>
      <li>
        <strong>Unternehmen</strong> (in dieser Vereinbarung entweder als „das Unternehmen“, „Wir“, „Uns“ oder „Unser“
        bezeichnet) bezieht sich auf Ropsid Inc.
      </li>
      <li>
        <strong>Land</strong> bezieht sich auf: Ontario, Kanada
      </li>
      <li>
        <strong>Gerät</strong> bedeutet jedes Gerät, das auf den Dienst zugreifen kann, wie z.B. ein Computer, ein
        Mobiltelefon oder ein digitales Tablet.
      </li>
      <li>
        <strong>Personenbezogene Daten</strong> sind alle Informationen, die sich auf eine identifizierte oder
        identifizierbare Person beziehen.
      </li>
      <li>
        <strong>Dienst</strong> bezieht sich auf die Anwendung.
      </li>
      <li>
        <strong>Dienstleister</strong> bedeutet jede natürliche oder juristische Person, die die Daten im Auftrag des
        Unternehmens verarbeitet. Es bezieht sich auf Drittunternehmen oder Einzelpersonen, die vom Unternehmen
        beauftragt wurden, den Dienst zu erleichtern, den Dienst im Namen des Unternehmens bereitzustellen,
        dienstbezogene Dienstleistungen zu erbringen oder dem Unternehmen bei der Analyse der Nutzung des Dienstes zu
        helfen.
      </li>
      <li>
        <strong>Nutzungsdaten</strong> sind Daten, die automatisch erfasst werden, entweder durch die Nutzung des
        Dienstes oder durch die Dienstinfrastruktur selbst (z.B. die Dauer eines Seitenbesuchs).
      </li>
      <li>
        <strong>Sie</strong> bedeutet die Person, die auf den Dienst zugreift oder ihn nutzt, oder das Unternehmen oder
        eine andere juristische Person, in deren Namen diese Person auf den Dienst zugreift oder ihn nutzt, je nach
        Fall.
      </li>
    </ul>
    <h2>Erhebung und Nutzung Ihrer personenbezogenen Daten</h2>
    <h3>Arten der erhobenen Daten</h3>
    <h4>Personenbezogene Daten</h4>
    <p>
      Während der Nutzung unseres Dienstes können wir Sie bitten, uns bestimmte personenbezogene Daten zur Verfügung zu
      stellen, die verwendet werden können, um Sie zu kontaktieren oder zu identifizieren. Persönlich identifizierbare
      Informationen können unter anderem umfassen:
    </p>
    <ul>
      <li>Email-Adresse</li>
      <li>Vorname und Nachname</li>
      <li>Telefonnummer</li>
      <li>Adresse, Bundesland, Provinz, Postleitzahl, Stadt</li>
      <li>Nutzungsdaten</li>
    </ul>
    <h4>Nutzungsdaten</h4>
    <p>Nutzungsdaten werden automatisch bei der Nutzung des Dienstes erfasst.</p>
    <p>
      Nutzungsdaten können Informationen wie die Internet-Protokoll-Adresse (z.B. IP-Adresse) Ihres Geräts, den
      Browsertyp, die Browserversion, die Seiten unseres Dienstes, die Sie besuchen, das Datum und die Uhrzeit Ihres
      Besuchs, die auf diesen Seiten verbrachte Zeit, eindeutige Gerätekennungen und andere Diagnosedaten umfassen.
    </p>
    <p>
      Wenn Sie über ein mobiles Gerät auf den Dienst zugreifen, können diese Informationen den Gerätetyp, den Sie
      verwenden, die eindeutige ID Ihres mobilen Geräts, die IP-Adresse Ihres mobilen Geräts, Ihr mobiles
      Betriebssystem, den Typ des mobilen Internetbrowsers, den Sie verwenden, eindeutige Gerätekennungen und andere
      Diagnosedaten umfassen.
    </p>
    <h4>Verfolgungstechnologien und Cookies</h4>
    <p>
      Wir verwenden Cookies und ähnliche Verfolgungstechnologien, um die Aktivität auf unserem Dienst zu verfolgen und
      bestimmte Informationen zu speichern. Verfolgungstechnologien, die verwendet werden, sind Beacons, Tags und
      Skripte, um Informationen zu sammeln und zu verfolgen und unseren Dienst zu verbessern und zu analysieren.
    </p>
    <p>Die Technologien, die wir verwenden, können umfassen:</p>
    <ul>
      <li>
        <strong>Cookies oder Browser-Cookies.</strong> Ein Cookie ist eine kleine Datei, die auf Ihrem Gerät abgelegt
        wird. Sie können Ihren Browser anweisen, alle Cookies abzulehnen oder anzuzeigen, wenn ein Cookie gesendet wird.
        Wenn Sie keine Cookies akzeptieren, können Sie möglicherweise einige Teile unseres Dienstes nicht nutzen.
      </li>
      <li>
        <strong>Web-Beacons.</strong> Bestimmte Abschnitte unseres Dienstes und unsere E-Mails können kleine
        elektronische Dateien enthalten, die als Web-Beacons (auch bekannt als Clear Gifs, Pixel-Tags und
        Single-Pixel-Gifs) bezeichnet werden, die es dem Unternehmen beispielsweise ermöglichen, die Benutzer zu zählen,
        die diese Seiten besucht oder eine E-Mail geöffnet haben, und für andere damit verbundene Website-Statistiken
        (zum Beispiel zur Aufzeichnung der Beliebtheit eines bestimmten Abschnitts und zur Überprüfung der System- und
        Serverintegrität).
      </li>
    </ul>
    <p>Wir verwenden sowohl Session- als auch Persistente-Cookies für die unten angegebenen Zwecke:</p>
    <ul>
      <li>
        <strong>Notwendige / Wesentliche Cookies</strong>
        Typ: Session-Cookies Verwaltet von: Uns Zweck: Diese Cookies sind wesentlich, um Ihnen Dienste bereitzustellen,
        die über die Website verfügbar sind, und um Ihnen die Nutzung einiger ihrer Funktionen zu ermöglichen. Sie
        helfen, Benutzer zu authentifizieren und betrügerische Nutzung von Benutzerkonten zu verhindern. Ohne diese
        Cookies können die von Ihnen angeforderten Dienste nicht bereitgestellt werden, und wir verwenden diese Cookies
        nur, um Ihnen diese Dienste bereitzustellen.
      </li>
      <li>
        <strong>Cookies zur Akzeptanz der Cookies-Richtlinie / Benachrichtigung</strong>
        Typ: Persistente-Cookies Verwaltet von: Uns Zweck: Diese Cookies identifizieren, ob Benutzer die Verwendung von
        Cookies auf der Website akzeptiert haben.
      </li>
      <li>
        <strong>Funktionscookies</strong>
        Typ: Persistente-Cookies Verwaltet von: Uns Zweck: Diese Cookies ermöglichen es uns, die Entscheidungen, die Sie
        bei der Nutzung der Website treffen, zu speichern, z.B. die Speicherung Ihrer Anmeldedaten oder der
        Spracheinstellung. Der Zweck dieser Cookies ist es, Ihnen eine persönlichere Erfahrung zu bieten und zu
        verhindern, dass Sie Ihre Präferenzen jedes Mal neu eingeben müssen, wenn Sie die Website nutzen.
      </li>
    </ul>
    <h4>Informationen, die während der Nutzung der Anwendung gesammelt werden</h4>
    <p>
      Während der Nutzung unserer Anwendung können wir, um Funktionen unserer Anwendung bereitzustellen, mit Ihrer
      vorherigen Erlaubnis Informationen sammeln:
    </p>
    <ul>
      <li>Informationen über Ihren Standort</li>
      <li>Informationen aus dem Telefonbuch Ihres Geräts (Kontaktliste)</li>
      <li>Bilder und andere Informationen aus der Kamera und Fotobibliothek Ihres Geräts</li>
    </ul>
    <p>
      Wir verwenden diese Informationen, um Funktionen unseres Dienstes bereitzustellen, unseren Dienst zu verbessern
      und anzupassen. Die Informationen können auf die Server des Unternehmens und/oder die Server eines Dienstanbieters
      hochgeladen werden oder einfach auf Ihrem Gerät gespeichert werden.
    </p>
    <p>
      Sie können den Zugriff auf diese Informationen jederzeit über Ihre Geräteeinstellungen aktivieren oder
      deaktivieren.
    </p>
    <h3>Verwendung Ihrer personenbezogenen Daten</h3>
    <p>Das Unternehmen kann personenbezogene Daten für die folgenden Zwecke verwenden:</p>
    <ul>
      <li>
        <strong>Um unseren Dienst bereitzustellen und zu warten</strong>, einschließlich der Überwachung der Nutzung
        unseres Dienstes.
      </li>
      <li>
        <strong>Um Ihr Konto zu verwalten:</strong> um Ihre Registrierung als Benutzer des Dienstes zu verwalten. Die
        von Ihnen bereitgestellten personenbezogenen Daten können Ihnen den Zugang zu verschiedenen Funktionalitäten des
        Dienstes ermöglichen, die Ihnen als registrierter Benutzer zur Verfügung stehen.
      </li>
      <li>
        <strong>Zur Erfüllung eines Vertrags:</strong> die Entwicklung, Einhaltung und Durchführung des Kaufvertrags für
        die von Ihnen gekauften Produkte, Artikel oder Dienstleistungen oder eines anderen Vertrags mit uns über den
        Dienst.
      </li>
      <li>
        <strong>Um Sie zu kontaktieren:</strong> Um Sie per E-Mail, Telefonanrufe, SMS oder andere gleichwertige Formen
        der elektronischen Kommunikation zu kontaktieren, wie z.B. Push-Benachrichtigungen einer mobilen Anwendung über
        Updates oder informative Mitteilungen in Bezug auf die Funktionalitäten, Produkte oder vertraglich vereinbarten
        Dienstleistungen, einschließlich der Sicherheitsupdates, wenn dies für deren Umsetzung erforderlich oder
        vernünftig ist.
      </li>
      <li>
        Um Ihnen Nachrichten, Sonderangebote und allgemeine Informationen über andere Waren, Dienstleistungen und
        Veranstaltungen anzubieten, die wir anbieten und die denen ähneln, die Sie bereits gekauft oder angefragt haben,
        es sei denn, Sie haben sich gegen den Erhalt solcher Informationen entschieden.
      </li>
      <li>
        <strong>Um Ihre Anfragen zu verwalten:</strong> Um Ihre Anfragen an uns zu bearbeiten und zu verwalten.
      </li>
    </ul>
    <h4>Für Unternehmensübertragungen:</h4>
    <p>
      Wir können Ihre Informationen verwenden, um eine Fusion, Veräußerung, Umstrukturierung, Reorganisation, Auflösung
      oder einen anderen Verkauf oder Transfer einiger oder aller unserer Vermögenswerte zu bewerten oder durchzuführen,
      sei es im Rahmen einer Fortführung des Geschäftsbetriebs oder als Teil eines Insolvenz-, Liquidations- oder
      ähnlichen Verfahrens, bei dem personenbezogene Daten, die wir über unsere Dienstbenutzer halten, zu den
      übertragenen Vermögenswerten gehören.
    </p>
    <h4>Für andere Zwecke:</h4>
    <p>
      Wir können Ihre Informationen für andere Zwecke verwenden, wie z.B. Datenanalyse, Identifizierung von
      Nutzungstrends, Bestimmung der Wirksamkeit unserer Werbekampagnen und zur Bewertung und Verbesserung unseres
      Dienstes, unserer Produkte, unseres Marketings und Ihrer Erfahrungen. Wir können Ihre personenbezogenen
      Informationen in den folgenden Situationen teilen:
    </p>
    <ul>
      <li>
        <strong>Mit Dienstleistern:</strong> Wir können Ihre personenbezogenen Daten mit Dienstleistern teilen, um die
        Nutzung unseres Dienstes zu überwachen und zu analysieren, um Sie zu kontaktieren.
      </li>
      <li>
        <strong>Für Unternehmensübertragungen:</strong> Wir können Ihre personenbezogenen Informationen in Verbindung
        mit oder während Verhandlungen über eine Fusion, den Verkauf von Unternehmensvermögen, die Finanzierung oder den
        Erwerb des gesamten oder eines Teils unseres Geschäfts an ein anderes Unternehmen teilen oder übertragen.
      </li>
      <li>
        <strong>Mit Partnerunternehmen:</strong> Wir können Ihre Informationen mit unseren Partnerunternehmen teilen, in
        diesem Fall werden wir von diesen Partnerunternehmen verlangen, diese Datenschutzrichtlinie einzuhalten.
        Partnerunternehmen umfassen unser Mutterunternehmen und alle anderen Tochtergesellschaften,
        Joint-Venture-Partner oder andere Unternehmen, die wir kontrollieren oder die unter gemeinsamer Kontrolle mit
        uns stehen.
      </li>
      <li>
        <strong>Mit Geschäftspartnern:</strong> Wir können Ihre Informationen mit unseren Geschäftspartnern teilen, um
        Ihnen bestimmte Produkte, Dienstleistungen oder Werbeaktionen anzubieten.
      </li>
      <li>
        <strong>Mit anderen Benutzern:</strong> wenn Sie personenbezogene Informationen teilen oder anderweitig in den
        öffentlichen Bereichen mit anderen Benutzern interagieren, können diese Informationen von allen Benutzern
        eingesehen und außerhalb öffentlich verbreitet werden.
      </li>
      <li>
        <strong>Mit Ihrer Zustimmung:</strong> Wir können Ihre personenbezogenen Informationen für andere Zwecke mit
        Ihrer Zustimmung offenlegen.
      </li>
    </ul>
    <h3>Aufbewahrung Ihrer personenbezogenen Daten</h3>
    <p>
      Das Unternehmen wird Ihre personenbezogenen Daten nur so lange aufbewahren, wie es für die in dieser
      Datenschutzrichtlinie festgelegten Zwecke erforderlich ist. Wir werden Ihre personenbezogenen Daten in dem Umfang
      aufbewahren und verwenden, wie es erforderlich ist, um unseren gesetzlichen Verpflichtungen nachzukommen (z.B.
      wenn wir Ihre Daten aufbewahren müssen, um geltenden Gesetzen zu entsprechen), Streitigkeiten beizulegen und
      unsere rechtlichen Vereinbarungen und Richtlinien durchzusetzen.
    </p>
    <p>
      Das Unternehmen wird auch Nutzungsdaten für interne Analysezwecke aufbewahren. Nutzungsdaten werden im Allgemeinen
      für einen kürzeren Zeitraum aufbewahrt, es sei denn, diese Daten werden verwendet, um die Sicherheit zu stärken
      oder die Funktionalität unseres Dienstes zu verbessern, oder wir sind gesetzlich verpflichtet, diese Daten für
      längere Zeiträume aufzubewahren.
    </p>
    <h3>Übertragung Ihrer personenbezogenen Daten</h3>
    <p>
      Ihre Informationen, einschließlich personenbezogener Daten, werden an den Betriebsstätten des Unternehmens und an
      anderen Orten, an denen sich die an der Verarbeitung beteiligten Parteien befinden, verarbeitet. Das bedeutet,
      dass diese Informationen an — und auf — Computern außerhalb Ihres Staates, Ihrer Provinz, Ihres Landes oder einer
      anderen staatlichen Gerichtsbarkeit, in der die Datenschutzgesetze von denen Ihrer Gerichtsbarkeit abweichen
      können, übertragen und dort gespeichert werden können.
    </p>
    <p>
      Ihre Zustimmung zu dieser Datenschutzrichtlinie, gefolgt von Ihrer Übermittlung solcher Informationen, stellt Ihre
      Zustimmung zu dieser Übertragung dar.
    </p>
    <p>
      Das Unternehmen wird alle angemessenen Schritte unternehmen, um sicherzustellen, dass Ihre Daten sicher und in
      Übereinstimmung mit dieser Datenschutzrichtlinie behandelt werden und keine Übertragung Ihrer personenbezogenen
      Daten an eine Organisation oder ein Land stattfindet, es sei denn, es gibt angemessene Kontrollen, einschließlich
      der Sicherheit Ihrer Daten und anderer persönlicher Informationen.
    </p>
    <h3>Offenlegung Ihrer personenbezogenen Daten</h3>
    <h4>Geschäftstransaktionen</h4>
    <p>
      Wenn das Unternehmen an einer Fusion, einer Übernahme oder einem Verkauf von Vermögenswerten beteiligt ist, können
      Ihre personenbezogenen Daten übertragen werden. Wir werden eine Benachrichtigung bereitstellen, bevor Ihre
      personenbezogenen Daten übertragen werden und einer anderen Datenschutzrichtlinie unterliegen.
    </p>
    <h4>Gesetzesdurchsetzung</h4>
    <p>
      Unter bestimmten Umständen kann das Unternehmen verpflichtet sein, Ihre personenbezogenen Daten offenzulegen, wenn
      dies gesetzlich vorgeschrieben ist oder als Reaktion auf berechtigte Anfragen öffentlicher Behörden (z.B. ein
      Gericht oder eine Regierungsbehörde).
    </p>
    <h4>Andere gesetzliche Anforderungen</h4>
    <p>
      Das Unternehmen kann Ihre personenbezogenen Daten in dem guten Glauben offenlegen, dass eine solche Handlung
      notwendig ist, um:
    </p>
    <ul>
      <li>Einer gesetzlichen Verpflichtung nachzukommen</li>
      <li>Die Rechte oder das Eigentum des Unternehmens zu schützen und zu verteidigen</li>
      <li>Mögliche Verfehlungen im Zusammenhang mit dem Dienst zu verhindern oder zu untersuchen</li>
      <li>Die persönliche Sicherheit der Benutzer des Dienstes oder der Öffentlichkeit zu schützen</li>
      <li>Vor rechtlicher Haftung zu schützen</li>
    </ul>
    <h3>Sicherheit Ihrer personenbezogenen Daten</h3>
    <p>
      Die Sicherheit Ihrer personenbezogenen Daten ist uns wichtig, aber denken Sie daran, dass keine Methode der
      Übertragung über das Internet oder der elektronischen Speicherung 100% sicher ist. Während wir uns bemühen,
      kommerziell akzeptable Mittel zum Schutz Ihrer personenbezogenen Daten zu verwenden, können wir deren absolute
      Sicherheit nicht garantieren.
    </p>
    <h2>Datenschutz von Kindern</h2>
    <p>
      Unser Dienst richtet sich nicht an Personen unter 13 Jahren. Wir sammeln nicht wissentlich persönlich
      identifizierbare Informationen von Personen unter 13 Jahren. Wenn Sie ein Elternteil oder ein Vormund sind und
      wissen, dass Ihr Kind uns personenbezogene Daten zur Verfügung gestellt hat, kontaktieren Sie uns bitte. Wenn wir
      feststellen, dass wir personenbezogene Daten von Personen unter 13 Jahren ohne Überprüfung der elterlichen
      Zustimmung gesammelt haben, ergreifen wir Maßnahmen, um diese Informationen von unseren Servern zu entfernen.
    </p>
    <p>
      Wenn wir uns auf die Zustimmung als rechtliche Grundlage für die Verarbeitung Ihrer Informationen verlassen müssen
      und Ihr Land die Zustimmung eines Elternteils erfordert, können wir die Zustimmung Ihres Elternteils benötigen,
      bevor wir diese Informationen sammeln und verwenden.
    </p>
    <h2>Links zu anderen Websites</h2>
    <p>
      Unser Dienst kann Links zu anderen Websites enthalten, die nicht von uns betrieben werden. Wenn Sie auf einen Link
      eines Drittanbieters klicken, werden Sie zu dieser Website des Drittanbieters weitergeleitet. Wir empfehlen Ihnen
      dringend, die Datenschutzrichtlinie jeder Website, die Sie besuchen, zu überprüfen.
    </p>
    <p>
      Wir haben keine Kontrolle über und übernehmen keine Verantwortung für den Inhalt , die Datenschutzrichtlinien oder
      -praktiken von Websites oder Diensten Dritter.
    </p>
    <h2>Änderungen dieser Datenschutzrichtlinie</h2>
    <p>
      Wir können unsere Datenschutzrichtlinie von Zeit zu Zeit aktualisieren. Wir werden Sie über Änderungen
      informieren, indem wir die neue Datenschutzrichtlinie auf dieser Seite veröffentlichen.
    </p>
    <p>
      Wir werden Sie per E-Mail und/oder durch eine auffällige Benachrichtigung auf unserem Dienst informieren, bevor
      die Änderung wirksam wird, und das Datum der „letzten Aktualisierung“ oben in dieser Datenschutzrichtlinie
      aktualisieren.
    </p>
    <p>
      Sie werden geraten, diese Datenschutzrichtlinie regelmäßig auf Änderungen zu überprüfen. Änderungen dieser
      Datenschutzrichtlinie werden wirksam, wenn sie auf dieser Seite veröffentlicht werden.
    </p>
    <h2>Kontaktieren Sie uns</h2>
    <p>Wenn Sie Fragen zu dieser Datenschutzrichtlinie haben, können Sie uns kontaktieren:</p>
    <ul>
      <li>Per E-Mail: contact@unifeed.news</li>
    </ul>
  </div>
));
