import * as React from 'react';

export const Ar: React.FC = React.memo(() => (
  <div>
    <h1>سياسة الخصوصية</h1>
    <p>آخر تحديث: 5 يوليو 2024</p>
    <p>
      تصف سياسة الخصوصية هذه سياساتنا وإجراءاتنا بشأن جمع المعلومات واستخدامها والإفصاح عنها عند استخدامك للخدمة وتخبرك
      بحقوق الخصوصية الخاصة بك وكيفية حماية القانون لك.
    </p>
    <p>
      نستخدم بياناتك الشخصية لتقديم الخدمة وتحسينها، بما في ذلك عرض الإعلانات المخصصة وتقديم الاشتراكات المدفوعة.
      باستخدامك للخدمة، فإنك توافق على جمع واستخدام المعلومات وفقًا لسياسة الخصوصية هذه.
    </p>
    <h2>التفسير والتعريفات</h2>
    <h3>التفسير</h3>
    <p>
      الكلمات التي يتم كتابة أول حرف منها بحرف كبير لها معانٍ محددة في ظل الشروط التالية. يجب أن تحمل التعريفات التالية
      نفس المعنى بغض النظر عما إذا كانت تظهر بصيغة المفرد أو الجمع.
    </p>
    <h3>التعريفات</h3>
    <p>لأغراض سياسة الخصوصية هذه:</p>
    <ul>
      <li>
        <strong>الحساب</strong> يعني حسابًا فريدًا يتم إنشاؤه لك للوصول إلى خدمتنا أو أجزاء من خدمتنا.
      </li>
      <li>
        <strong>الشركة التابعة</strong> تعني كيانًا يتحكم فيه، أو يتحكم به، أو يخضع لتحكم مشترك مع أحد الأطراف، حيث تعني
        "التحكم" امتلاك 50٪ أو أكثر من الأسهم أو الحصص أو الأوراق المالية الأخرى التي يحق لها التصويت لاختيار المديرين
        أو أي سلطة إدارية أخرى.
      </li>
      <li>
        <strong>التطبيق</strong> يشير إلى "UniFeed News"، البرنامج الذي توفره الشركة.
      </li>
      <li>
        <strong>الشركة</strong> (يُشار إليها باسم "الشركة" أو "نحن" أو "لنا" في هذه الاتفاقية) تشير إلى Ropsid Inc.
      </li>
      <li>
        <strong>البلد</strong> يشير إلى: أونتاريو، كندا
      </li>
      <li>
        <strong>الجهاز</strong> يعني أي جهاز يمكنه الوصول إلى الخدمة مثل جهاز الكمبيوتر أو الهاتف المحمول أو الجهاز
        اللوحي الرقمي.
      </li>
      <li>
        <strong>البيانات الشخصية</strong> هي أي معلومات تتعلق بفرد محدد أو قابل للتحديد.
      </li>
      <li>
        <strong>الخدمة</strong> تشير إلى التطبيق.
      </li>
      <li>
        <strong>مزود الخدمة</strong> يعني أي شخص طبيعي أو قانوني يعالج البيانات نيابة عن الشركة. يشير إلى شركات أو أفراد
        تابعين لجهات خارجية تعمل على تسهيل الخدمة أو تقديم الخدمة نيابة عن الشركة أو تقديم خدمات متعلقة بالخدمة أو
        المساعدة في تحليل كيفية استخدام الخدمة.
      </li>
      <li>
        <strong>بيانات الاستخدام</strong> تشير إلى البيانات التي يتم جمعها تلقائيًا، سواء تم إنشاؤها بواسطة استخدام
        الخدمة أو من بنية الخدمة نفسها (على سبيل المثال، مدة زيارة الصفحة).
      </li>
      <li>
        <strong>أنت</strong> تعني الفرد الذي يصل إلى الخدمة أو يستخدمها، أو الشركة أو الكيان القانوني الآخر الذي يصل إلى
        الخدمة أو يستخدمها نيابة عنه، حسب الاقتضاء.
      </li>
    </ul>
    <h2>جمع واستخدام بياناتك الشخصية</h2>
    <h3>أنواع البيانات المجمعة</h3>
    <h4>البيانات الشخصية</h4>
    <p>
      أثناء استخدام خدمتنا، قد نطلب منك تقديم معلومات شخصية محددة يمكن استخدامها للاتصال بك أو التعرف عليك. قد تشمل
      المعلومات الشخصية، على سبيل المثال لا الحصر:
    </p>
    <ul>
      <li>عنوان البريد الإلكتروني</li>
      <li>الاسم الأول واسم العائلة</li>
      <li>رقم الهاتف</li>
      <li>العنوان، الولاية، المقاطعة، الرمز البريدي/الرمز البريدي، المدينة</li>
      <li>بيانات الاستخدام</li>
    </ul>
    <h4>بيانات الاستخدام</h4>
    <p>يتم جمع بيانات الاستخدام تلقائيًا عند استخدام الخدمة.</p>
    <p>
      قد تشمل بيانات الاستخدام معلومات مثل عنوان بروتوكول الإنترنت الخاص بجهازك (مثل عنوان IP)، نوع المتصفح، إصدار
      المتصفح، صفحات خدمتنا التي تزورها، وقت وتاريخ زيارتك، الوقت الذي قضيته في تلك الصفحات، معرّفات الجهاز الفريدة،
      وغيرها من البيانات التشخيصية.
    </p>
    <p>
      عندما تصل إلى الخدمة بواسطة أو من خلال جهاز محمول، قد تشمل هذه المعلومات معلومات مثل نوع الجهاز المحمول الذي
      تستخدمه، معرّف الجهاز المحمول الفريد الخاص بك، عنوان IP الخاص بجهازك المحمول، نظام التشغيل المحمول الخاص بك، نوع
      متصفح الإنترنت المحمول الذي تستخدمه، معرّفات الجهاز الفريدة، وغيرها من البيانات التشخيصية.
    </p>
    <h4>تقنيات التتبع وملفات تعريف الارتباط</h4>
    <p>
      نستخدم ملفات تعريف الارتباط وتقنيات تتبع مماثلة لتتبع النشاط على خدمتنا وتخزين معلومات معينة. تشمل تقنيات التتبع
      المستخدمة منارات الويب والعلامات والبرامج النصية لجمع المعلومات وتتبعها ولتحسين وتحليل خدمتنا.
    </p>
    <p>التقنيات التي نستخدمها قد تشمل:</p>
    <ul>
      <li>
        <strong>ملفات تعريف الارتباط أو ملفات تعريف الارتباط المتصفح.</strong> ملف تعريف الارتباط هو ملف صغير يوضع على
        جهازك. يمكنك إرشاد متصفحك لرفض جميع ملفات تعريف الارتباط أو لإظهار متى يتم إرسال ملف تعريف ارتباط. ومع ذلك، إذا
        لم تقبل ملفات تعريف الارتباط، قد لا تتمكن من استخدام بعض أجزاء خدمتنا.
      </li>
      <li>
        <strong>منارات الويب.</strong> قد تحتوي أقسام معينة من خدمتنا ورسائل البريد الإلكتروني الخاصة بنا على ملفات
        إلكترونية صغيرة تُعرف باسم منارات الويب (يُشار إليها أيضًا باسم gifs الواضحة أو علامات البكسل أو gifs ذات البكسل
        الواحد) التي تسمح للشركة، على سبيل المثال، بعدد المستخدمين الذين زاروا تلك الصفحات أو فتحوا بريدًا إلكترونيًا
        ولإحصاءات أخرى متعلقة بالموقع (على سبيل المثال، تسجيل شعبية قسم معين والتحقق من تكامل النظام والخادم).
      </li>
    </ul>
    <p>نستخدم ملفات تعريف الارتباط لجلسات وملفات تعريف الارتباط الدائمة للأغراض المحددة أدناه:</p>
    <ul>
      <li>
        <strong>ملفات تعريف الارتباط الضرورية / الأساسية</strong>
        النوع: ملفات تعريف الارتباط للجلسات تدار من قبل: نحن الغرض: هذه الملفات ضرورية لتقديم الخدمات المتاحة لك من خلال
        الموقع الإلكتروني ولتمكينك من استخدام بعض ميزاته. تساعد في توثيق المستخدمين ومنع الاستخدام الاحتيالي لحسابات
        المستخدمين. بدون هذه الملفات، لا يمكن توفير الخدمات التي طلبتها، ونحن نستخدم هذه الملفات فقط لتزويدك بهذه
        الخدمات.
      </li>
      <li>
        <strong>سياسة ملفات تعريف الارتباط / إشعار قبول ملفات تعريف الارتباط</strong>
        النوع: ملفات تعريف الارتباط الدائمة تدار من قبل: نحن الغرض: تحدد هذه الملفات ما إذا كان المستخدمون قد قبلوا
        استخدام ملفات تعريف الارتباط على الموقع الإلكتروني.
      </li>
      <li>
        <strong>ملفات تعريف الارتباط الوظيفية</strong>
        النوع: ملفات تعريف الارتباط الدائمة تدار من قبل: نحن الغرض: تتيح لنا هذه الملفات تذكر الخيارات التي تحددها عند
        استخدام الموقع الإلكتروني، مثل تذكر تفاصيل تسجيل الدخول أو تفضيلات اللغة الخاصة بك. الغرض من هذه الملفات هو
        تزويدك بتجربة شخصية أكثر ولتجنب إعادة إدخال تفضيلاتك في كل مرة تستخدم فيها الموقع.
      </li>
    </ul>
    <h4>المعلومات التي تم جمعها أثناء استخدام التطبيق</h4>
    <p>أثناء استخدام تطبيقنا، من أجل تقديم ميزات التطبيق، قد نجمع، بإذنك المسبق:</p>
    <ul>
      <li>معلومات بشأن موقعك</li>
      <li>معلومات من دفتر الهاتف بجهازك (قائمة الاتصالات)</li>
      <li>صور ومعلومات أخرى من كاميرا جهازك ومكتبة الصور الخاصة بك</li>
    </ul>
    <p>
      نستخدم هذه المعلومات لتقديم ميزات خدمتنا ولتحسين وتخصيص خدمتنا. قد يتم تحميل المعلومات على خوادم الشركة و/أو خادم
      مزود الخدمة أو قد يتم تخزينها ببساطة على جهازك.
    </p>
    <p>يمكنك تمكين أو تعطيل الوصول إلى هذه المعلومات في أي وقت، من خلال إعدادات جهازك.</p>
    <h3>استخدام بياناتك الشخصية</h3>
    <p>قد تستخدم الشركة البيانات الشخصية للأغراض التالية:</p>
    <ul>
      <li>
        <strong>لتوفير وصيانة خدمتنا</strong>، بما في ذلك مراقبة استخدام خدمتنا.
      </li>
      <li>
        <strong>لإدارة حسابك:</strong> لإدارة تسجيلك كمستخدم للخدمة. يمكن أن توفر لك البيانات الشخصية التي تقدمها وصولاً
        إلى وظائف مختلفة للخدمة التي تتوفر لك كمستخدم مسجل.
      </li>
      <li>
        <strong>لتنفيذ عقد:</strong> تطوير وامتثال وإبرام عقد الشراء للمنتجات أو العناصر أو الخدمات التي اشتريتها أو أي
        عقد آخر معنا من خلال الخدمة.
      </li>
      <li>
        <strong>للتواصل معك:</strong> للتواصل معك عبر البريد الإلكتروني أو المكالمات الهاتفية أو الرسائل النصية القصيرة
        أو أشكال أخرى من الاتصالات الإلكترونية، مثل الإشعارات الفورية لتطبيق الجوال المتعلقة بالتحديثات أو الاتصالات
        المعلوماتية المتعلقة بالوظائف أو المنتجات أو الخدمات المتعاقد عليها، بما في ذلك تحديثات الأمان، عند الحاجة أو
        المعقول لتنفيذها.
      </li>
      <li>
        لتزويدك بالأخبار والعروض الخاصة والمعلومات العامة حول السلع والخدمات والأحداث الأخرى التي نقدمها والتي تشبه تلك
        التي قمت بشرائها بالفعل أو استفسرت عنها، ما لم تكن قد اخترت عدم تلقي مثل هذه المعلومات.
      </li>
      <li>
        <strong>لإدارة طلباتك:</strong> لحضور وإدارة طلباتك لنا.
      </li>
    </ul>
    <h4>لنقل الأعمال:</h4>
    <p>
      قد نستخدم معلوماتك لتقييم أو إجراء اندماج أو تصفية أو إعادة هيكلة أو إعادة تنظيم أو حل أو بيع أو نقل بعض أو كل
      أصولنا، سواء كان ذلك مستمرًا أو كجزء من الإفلاس أو التصفية أو إجراءات مماثلة، حيث تكون البيانات الشخصية التي نحتفظ
      بها عن مستخدمي خدمتنا جزءًا من الأصول المنقولة.
    </p>
    <h4>لأغراض أخرى:</h4>
    <p>
      قد نستخدم معلوماتك لأغراض أخرى، مثل تحليل البيانات، وتحديد اتجاهات الاستخدام، وتحديد فعالية حملاتنا الترويجية،
      وتقييم وتحسين خدمتنا ومنتجاتنا وتسويقنا وتجربتك. قد نشارك معلوماتك الشخصية في الحالات التالية:
    </p>
    <ul>
      <li>
        <strong>مع مقدمي الخدمة:</strong> قد نشارك معلوماتك الشخصية مع مقدمي الخدمة لمراقبة وتحليل استخدام خدمتنا،
        للاتصال بك.
      </li>
      <li>
        <strong>لنقل الأعمال:</strong> قد نشارك أو ننقل معلوماتك الشخصية في سياق أو خلال مفاوضات أي اندماج أو بيع لأصول
        الشركة أو تمويل أو استحواذ على كل أو جزء من أعمالنا لشركة أخرى.
      </li>
      <li>
        <strong>مع الشركات التابعة:</strong> قد نشارك معلوماتك مع شركاتنا التابعة، وفي هذه الحالة سنطلب من الشركات
        التابعة احترام سياسة الخصوصية هذه. تشمل الشركات التابعة شركتنا الأم وأي شركات تابعة أخرى أو شركاء مشروع مشترك أو
        شركات أخرى نسيطر عليها أو تخضع لسيطرة مشتركة معنا.
      </li>
      <li>
        <strong>مع الشركاء التجاريين:</strong> قد نشارك معلوماتك مع شركائنا التجاريين لتقديم بعض المنتجات أو الخدمات أو
        العروض الترويجية.
      </li>
      <li>
        <strong>مع المستخدمين الآخرين:</strong> عندما تشارك معلومات شخصية أو تتفاعل بطريقة أخرى في المناطق العامة مع
        مستخدمين آخرين، قد تكون هذه المعلومات مرئية لجميع المستخدمين وقد يتم توزيعها علنًا خارجياً.
      </li>
      <li>
        <strong>بموافقتك:</strong> قد نفصح عن معلوماتك الشخصية لأي غرض آخر بموافقتك.
      </li>
    </ul>
    <h3>الاحتفاظ ببياناتك الشخصية</h3>
    <p>
      ستحتفظ الشركة ببياناتك الشخصية فقط طالما كانت ضرورية للأغراض المحددة في سياسة الخصوصية هذه. سنحتفظ ببياناتك
      الشخصية ونستخدمها إلى الحد اللازم للامتثال لالتزاماتنا القانونية (على سبيل المثال، إذا كنا ملزمين بالاحتفاظ
      ببياناتك للامتثال للقوانين المعمول بها)، أو لحل النزاعات، أو لتنفيذ اتفاقياتنا وسياساتنا القانونية.
    </p>
    <p>
      ستحتفظ الشركة أيضًا ببيانات الاستخدام لأغراض التحليل الداخلي. عادةً ما يتم الاحتفاظ ببيانات الاستخدام لفترة زمنية
      أقصر، إلا عندما يتم استخدام هذه البيانات لتعزيز الأمان أو لتحسين وظيفة خدمتنا، أو إذا كنا ملزمين قانونًا بالاحتفاظ
      بهذه البيانات لفترات زمنية أطول.
    </p>
    <h3>نقل بياناتك الشخصية</h3>
    <p>
      يتم معالجة معلوماتك، بما في ذلك البيانات الشخصية، في مكاتب تشغيل الشركة وفي أي أماكن أخرى توجد فيها الأطراف
      المشاركة في المعالجة. يعني ذلك أن هذه المعلومات قد يتم نقلها إلى — والاحتفاظ بها على — أجهزة الكمبيوتر الموجودة
      خارج ولايتك أو مقاطعتك أو بلدك أو ولاية حكومية أخرى حيث قد تختلف قوانين حماية البيانات عن تلك في ولايتك.
    </p>
    <p>موافقتك على سياسة الخصوصية هذه يليها تقديمك لمثل هذه المعلومات يمثل موافقتك على هذا النقل.</p>
    <p>
      ستتخذ الشركة جميع الخطوات اللازمة بشكل معقول لضمان معالجة بياناتك بشكل آمن ووفقًا لسياسة الخصوصية هذه ولن يتم نقل
      بياناتك الشخصية إلى منظمة أو بلد إلا إذا كانت هناك ضوابط كافية بما في ذلك أمان بياناتك ومعلوماتك الشخصية الأخرى.
    </p>
    <h3>الإفصاح عن بياناتك الشخصية</h3>
    <h4>الصفقات التجارية</h4>
    <p>
      إذا كانت الشركة متورطة في اندماج أو استحواذ أو بيع أصول، فقد يتم نقل بياناتك الشخصية. سنقدم إشعارًا قبل نقل
      بياناتك الشخصية ويصبح خاضعًا لسياسة خصوصية مختلفة.
    </p>
    <h4>إنفاذ القانون</h4>
    <p>
      في ظل ظروف معينة، قد تكون الشركة ملزمة بالإفصاح عن بياناتك الشخصية إذا كان ذلك مطلوبًا بموجب القانون أو استجابةً
      لطلبات صالحة من السلطات العامة (مثل محكمة أو وكالة حكومية).
    </p>
    <h4>متطلبات قانونية أخرى</h4>
    <p>قد تفصح الشركة عن بياناتك الشخصية بحسن نية بأن هذا الإجراء ضروري لـ:</p>
    <ul>
      <li>الامتثال لالتزام قانوني</li>
      <li>حماية والدفاع عن حقوق أو ممتلكات الشركة</li>
      <li>منع أو التحقيق في أي خطأ محتمل فيما يتعلق بالخدمة</li>
      <li>حماية السلامة الشخصية لمستخدمي الخدمة أو العامة</li>
      <li>الحماية من المسؤولية القانونية</li>
    </ul>
    <h3>أمان بياناتك الشخصية</h3>
    <p>
      أمان بياناتك الشخصية مهم بالنسبة لنا، ولكن تذكر أنه لا توجد طريقة إرسال عبر الإنترنت أو طريقة تخزين إلكترونية آمنة
      بنسبة 100٪. بينما نسعى لاستخدام وسائل مقبولة تجاريًا لحماية بياناتك الشخصية، لا يمكننا ضمان أمانها المطلق.
    </p>
    <h2>خصوصية الأطفال</h2>
    <p>
      خدمتنا لا تتعامل مع أي شخص دون سن 13 عامًا. نحن لا نجمع عن عمد معلومات تعريفية شخصية من أي شخص دون سن 13 عامًا.
      إذا كنت والدًا أو وصيًا وتعلم أن طفلك قد قدم لنا بيانات شخصية، فيرجى الاتصال بنا. إذا أصبحنا على علم بأننا جمعنا
      بيانات شخصية من أي شخص دون سن 13 عامًا دون التحقق من موافقة الوالدين، سنتخذ خطوات لإزالة تلك المعلومات من خوادمنا.
    </p>
    <p>
      إذا كنا بحاجة إلى الاعتماد على الموافقة كأساس قانوني لمعالجة معلوماتك وبلدك يتطلب موافقة أحد الوالدين، فقد نحتاج
      إلى موافقة والدك قبل أن نجمع ونستخدم تلك المعلومات.
    </p>
    <h2>روابط لمواقع أخرى</h2>
    <p>
      قد تحتوي خدمتنا على روابط لمواقع أخرى لا نقوم بتشغيلها. إذا نقرت على رابط جهة خارجية، فسيتم توجيهك إلى موقع الطرف
      الثالث. ننصحك بشدة بمراجعة سياسة الخصوصية الخاصة بكل موقع تزوره.
    </p>
    <p>لا نتحكم ولا نتحمل أي مسؤولية عن محتوى أو سياسات الخصوصية أو ممارسات أي مواقع أو خدمات تابعة لجهات خارجية.</p>
    <h2>التغييرات على سياسة الخصوصية هذه</h2>
    <p>
      قد نقوم بتحديث سياسة الخصوصية الخاصة بنا من وقت لآخر. سنخطرك بأي تغييرات عن طريق نشر سياسة الخصوصية الجديدة على
      هذه الصفحة.
    </p>
    <p>
      سنعلمك عبر البريد الإلكتروني و/أو إشعار بارز على خدمتنا، قبل أن تصبح التغييرات سارية المفعول ونقوم بتحديث تاريخ
      "آخر تحديث" في أعلى سياسة الخصوصية هذه.
    </p>
    <p>
      ننصحك بمراجعة سياسة الخصوصية هذه بشكل دوري لأي تغييرات. التغييرات على سياسة الخصوصية هذه فعالة عندما يتم نشرها على
      هذه الصفحة.
    </p>
    <h2>اتصل بنا</h2>
    <p>إذا كانت لديك أي أسئلة حول سياسة الخصوصية هذه، يمكنك الاتصال بنا:</p>
    <ul>
      <li>عن طريق البريد الإلكتروني: contact@unifeed.news</li>
    </ul>
  </div>
));
