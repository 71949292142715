import {PageContents} from '../utils';

export const pageContents: PageContents = {
  title: 'UniFeed 뉴스',
  headline: '필터 없이 세계를 보다 – 13개국의 실시간 뉴스 다이제스트를 16개 언어로 제공',
  subheading:
    '실시간으로 제공되는 전 세계의 심층적이고 공정한 뉴스 다이제스트를 귀하의 모국어로 번역하여 이해하기 쉽게 탐색하세요',
  or: '또는',
  launchWebApp: '웹앱 실행',
};
